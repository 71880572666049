import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RecordingQRCode from "../../RecordingQRCode";

const TrueFalsePreview = (props) => {
    const { t } = useTranslation();
    const { versions, currentKey, showAnswers, recording } = props;

    return (
        <Fragment>
            {!versions ? (
                t("loading")
            ) : !versions[currentKey] ? (
                <Alert variant="warning" className="alert-small">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {t("warning.notEnoughVersions")}
                </Alert>
            ) : (
                <Fragment>
                    <Row className="test-view">
                        <Col xs={12} className="task-text mb-2">
                            {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                        </Col>
                        {versions[currentKey].optionalTxt && (
                            <Col xs={12} className="mb-2">
                                <div dangerouslySetInnerHTML={{ __html: versions[currentKey].optionalTxt }}></div>
                            </Col>
                        )}
                        {showAnswers && <RecordingQRCode recording={recording} />}
                        <Col xs={12}>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td width="8%" className="text-center">
                                            <b>{t("createExercise.exerciseContent.trueFalse.true")}</b>
                                        </td>
                                        <td width="8%" className="text-center">
                                            <b>{t("createExercise.exerciseContent.trueFalse.false")}</b>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {versions[currentKey].answers.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.content ? <div dangerouslySetInnerHTML={{ __html: e.content }}></div> : <p className="no-data">{t("error.noAnswerText")}</p>}</td>
                                            <td className="text-center">{showAnswers && e.correct && <FontAwesomeIcon icon={faCheck} />}</td>
                                            <td className="text-center">{showAnswers && !e.correct && <FontAwesomeIcon icon={faCheck} />}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default TrueFalsePreview;
