import React, { Fragment, useState, useContext } from "react";
import { Row, Col, Button, Card, OverlayTrigger, Tooltip, Dropdown, Alert, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ExerciseCard from "./ExerciseCard";
import { faEdit, faEllipsisVertical, faEye, /*  faPencil, faShareNodes,*/ faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationCustom from "../PaginationCustom";
import PreviewExerciseModal from "../modals/PreviewExerciseModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { LoggedContext } from "../../structure/LoggedViews";
import { faFile, faFileLines, faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import AddExerciseToTestModal from "../modals/AddExerciseToTestModal";
import SortingDropdown from "../forms/SortingDropdown";

const ExercisesList = (props) => {
    const { onAddToTest, onRemoveFromTest, groups, view, list, onEditExerDuringTest, slicedList, filteredList, sortValue, setSortValue, userID, paginationList, activePage, setActivePage } = props;
    const { t } = useTranslation();
    const [showExerciseDetails, setShowExerciseDetails] = useState(null);
    const [currentKey, setCurrentKey] = useState(0);
    const { setCurrentView } = useContext(LoggedContext);
    const [addExerToTestModal, setAddExerToTestModal] = useState(null);
    const [showAlertForEditing, setShowAlertForEditing] = useState(null);

    const handleCheckIfExerIsInTest = (exer) => {
        let check = false;
        //console.log(list);
        list.forEach((e) => {
            if (e._id === exer._id) check = true;
        });
        return check;
    };
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById("numberOfExercises").offsetTop - (view === "addExercise" ? document.getElementById("testNavbar").offsetHeight : 0) - 16,
                left: 0,
                behavior: "smooth",
            });
        }, 100);
    };

    //console.log(slicedList);

    return (
        <Container>
            {!slicedList ? (
                <Row>
                    <Col className="my-4">
                        <Alert variant="info">{t("info.loadingExerciseList")}</Alert>
                    </Col>
                </Row>
            ) : (
                <Fragment>
                    <Row>
                        <Col className="py-4" xs={view === "addExercise" ? 12 : 6}>
                            <p id="numberOfExercises">
                                {t("test.numberOfExercises")}: {filteredList.length}
                            </p>
                        </Col>
                        {view !== "addExercise" ? (
                            <Col className="py-4" xs={6} xl={{ span: 3, offset: 3 }} lg={{ span: 4, offset: 2 }}>
                                <SortingDropdown value={sortValue} onChange={(v) => setSortValue(v)} noAlphabeticalOrder={true} />
                            </Col>
                        ) : null}
                    </Row>
                    <Row>
                        {slicedList.map((e, i) => (
                            <Col xs={12} lg={view === "addExercise" ? 12 : 6} key={i} className="mb-3">
                                <Card body className="shadow">
                                    <ExerciseCard exerData={e} view={view} loggedUserID={userID} />
                                    {view === "addExercise" ? (
                                        <Row>
                                            <Col xs={12}>
                                                {!handleCheckIfExerIsInTest(e) ? (
                                                    e.taskData.versions.length < groups.length ? (
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id="too-few-versions-tooltip-disabled">{t(`warning.notEnoughVersions`)}</Tooltip>}>
                                                            <span className="d-inline-block">
                                                                <Button variant="primary" className="me-2" disabled>
                                                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                                    {t("addToTest")}
                                                                </Button>
                                                            </span>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <Button variant="primary" className="me-2" onClick={() => onAddToTest(e)}>
                                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                            {t("addToTest")}
                                                        </Button>
                                                    )
                                                ) : (
                                                    <Button variant="outline-danger" className="me-2" onClick={() => onRemoveFromTest(e)}>
                                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                        {t("removeFromTest")}
                                                    </Button>
                                                )}
                                                <Button variant="outline-primary" className="me-2" onClick={() => setShowExerciseDetails(e)}>
                                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                                    {t("details")}
                                                </Button>
                                                {/*  <Button variant="outline-primary">
                                                    <FontAwesomeIcon icon={faPencil} className="me-2" onClick={() => setShowAlertForEditing(e)} />
                                                    {t("edit")}
                                                </Button> */}
                                            </Col>
                                        </Row>
                                    ) : view === "list" ? (
                                        <Row>
                                            <Col>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" id="dropdownAddToTest">
                                                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                        {t("addToTest")}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => setCurrentView({ nav: "createTest", page: "createNewTest", params: { name: "addNewExer", exer: e } })}>
                                                            <FontAwesomeIcon icon={faFile} className="me-2" />
                                                            {t("addToNewTest")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => setAddExerToTestModal(e)}>
                                                            <FontAwesomeIcon icon={faFileLines} className="me-2" />
                                                            {t("addToExistingTest")}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => setCurrentView({ nav: "createTest", page: "createNewTest", params: { name: "addNewExerToDraftTest", exer: e } })}
                                                            disabled={localStorage.getItem("testDraft") ? false : true}
                                                        >
                                                            <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
                                                            {t("addToDraftTest")}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                            <Col className="text-end">
                                                <Dropdown align="end">
                                                    <Dropdown.Toggle variant="outline-primary" id={`exerciseDetails${i + 1}`} className="ellipsis-btn">
                                                        <FontAwesomeIcon icon={faEllipsisVertical} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => setShowExerciseDetails(e)}>
                                                            <FontAwesomeIcon icon={faEye} className="me-2" />
                                                            {t("exerciseDetails")}
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item disabled>
                                                            <FontAwesomeIcon icon={faShareNodes} className="me-2" />
                                                            {t("share")}
                                                        </Dropdown.Item> */}
                                                        {userID !== e.author.accountUid ? null : (
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    setCurrentView({ nav: "exercises", page: "editExercise", params: { exer: e } });
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} className="me-2" />
                                                                {t("edit")}
                                                            </Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {filteredList.length > 0 ? <PaginationCustom paginationList={paginationList} activePage={activePage} setActivePage={setActivePage} scrollToTop={scrollToTop} /> : null}
                </Fragment>
            )}
            {showExerciseDetails ? (
                <>
                    <PreviewExerciseModal
                        show={true}
                        onClose={() => setShowExerciseDetails(null)}
                        versions={showExerciseDetails.taskData.versions}
                        currentKey={currentKey}
                        setKey={setCurrentKey}
                        exerType={showExerciseDetails.taskData.exerType}
                        basicData={showExerciseDetails.taskData.basicData}
                        recording={showExerciseDetails.taskData.recording}
                    />
                </>
            ) : null}
            {/* deleteExerModal ? (
                <ConfirmModal
                    show={true}
                    onClose={() => setDeleteExerModal(null)}
                    onProceed={() => setFetchDeleteExer(true)}
                    text={t("deleteExerModalTxt")}
                    cancelTxt={t("yesDelete")}
                    title={t("deleteExerModalTitle")}
                    proceedTxt={t("logoutModal.cancelTxt")}
                />
            ) : null */}
            {addExerToTestModal ? <AddExerciseToTestModal onClose={() => setAddExerToTestModal(null)} exercise={addExerToTestModal} /> : null}
            {showAlertForEditing ? (
                <ConfirmModal
                    show={true}
                    onClose={() => setShowAlertForEditing(null)}
                    text={t("alertForEditingExerciseModal.text")}
                    proceedTxt={t("alertForEditingExerciseModal.proceedTxt")}
                    cancelTxt={t("alertForEditingExerciseModal.cancelTxt")}
                    onProceed={onEditExerDuringTest}
                    title={t("alertForEditingExerciseModal.title")}
                />
            ) : null}
        </Container>
    );
};

export default ExercisesList;
