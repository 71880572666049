import React, { useState, useEffect, createContext, Fragment } from "react";
import LoadingView from "./components/LoadingView";
import FooterMain from "./components/FooterMain";
import $ from "jquery";
import AlertsContainer from "./components/AlertsContainer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SetNewPassword from "./pages/SetNewPassword";
import LoggedViews from "./structure/LoggedViews";
import LoginPage from "./pages/LoginPage";
import { useTranslation } from "react-i18next";

export const BasicContext = createContext();

const App = () => {
    const { t } = useTranslation();
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if (loading) $("#loadingView").fadeIn(0);
        else $("#loadingView").fadeOut(500);
    }, [loading]);

    const minHeightContainter = () => {
        const mainContainer = document.getElementById("mainContainer");
        mainContainer.style.minHeight = `${window.innerHeight - document.getElementById("footerMain").offsetHeight}px`;
    };

    useEffect(() => {
        minHeightContainter();
        window.addEventListener("resize", minHeightContainter);

        const token = localStorage.getItem("token");
        if (token) setIsLogged(token);
        else setLoading(false);

        return () => {
            window.removeEventListener("resize", minHeightContainter);
        };
    }, []);

    useEffect(() => {
        if (alerts.length)
            alerts.forEach((e, i) => {
                if (e.fading) {
                    $("#alertsContainer .alert").each(function(index) {
                        if (i === index) {
                            setTimeout(() => {
                                $(this).addClass("fade-out");
                            }, e.fading);
                            setTimeout(() => {
                                const list = [...alerts];
                                list.splice(i, 1);
                                setAlerts(list);
                            }, e.fading + 500);
                        }
                    });
                }
            });
    }, [alerts]);

    const routes = [
        {
            path: "/",
            element: <Fragment>{isLogged ? <LoggedViews /> : <LoginPage />}</Fragment>,
        },
        {
            path: "/reset-password",
            element: <SetNewPassword />,
        },
    ];

    const langs = [
        {
            lang: "pl",
            text: t("langs.polish"),
        },
        {
            lang: "en",
            text: t("langs.english"),
        },
    ];

    return (
        <Router>
            <LoadingView />
            <BasicContext.Provider value={{ alerts, setAlerts, setIsLogged, setLoading, isLogged, langs }}>
                {alerts.length > 0 ? <AlertsContainer /> : null}
                <main id="mainContainer">
                    <Routes>
                        {routes.map((e, i) => (
                            <Fragment key={i}>
                                <Route path={e.path} element={e.element} />
                            </Fragment>
                        ))}
                    </Routes>
                </main>
            </BasicContext.Provider>
            <FooterMain />
        </Router>
    );
};

export default App;
