import React, { useContext } from "react";
import { Alert, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BasicContext } from "../App";

const AlertsContainer = () => {
    const { alerts, setAlerts } = useContext(BasicContext);

    return (
        <Container id="alertsContainer">
            <Row>
                {alerts.map((e, i) => (
                    <Col xs={12} key={i}>
                        <Alert
                            dismissible
                            variant={e.variant}
                            onClose={() => {
                                const list = [...alerts];
                                list.splice(i, 1);
                                setAlerts(list);
                            }}
                        >
                            {e.message}
                            {e.link && (
                                <p className="pt-2">
                                    <Link className="alert-link" to={e.link.to} onClick={() => setAlerts([])}>
                                        {e.link.txt}
                                    </Link>
                                </p>
                            )}
                        </Alert>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default AlertsContainer;
