import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarFull } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { t } from "i18next";

const Stars = (props) => {
    const { difficultyLvl } = props;

    return (
        <div className="stars">
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`stars-tooltip`}>{difficultyLvl === 1 ? t("difficultyLvl.easy") : difficultyLvl === 2 ? t("difficultyLvl.medium") : t("difficultyLvl.hard")}</Tooltip>}
            >
                <Button variant="link" className="star-rating-btn p-0">
                    <FontAwesomeIcon icon={faStarFull} className="star-rating" />
                    <FontAwesomeIcon icon={difficultyLvl === 1 ? faStar : faStarFull} className="star-rating" />
                    <FontAwesomeIcon icon={difficultyLvl === 1 || difficultyLvl === 2 ? faStar : faStarFull} className="star-rating" />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

export default Stars;
