import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { Alert, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoggedContext } from "../structure/LoggedViews";

const RecordingQRCode = (props) => {
    const { recording } = props;
    const { t } = useTranslation();
    const { currentView } = useContext(LoggedContext);

    return (
        <Fragment>
            {recording ? (
                <Col xs={12}>
                    <p className="mb-2">
                        <b>
                            <FontAwesomeIcon icon={faRecordVinyl} className="me-2" />
                            {recording.file}
                        </b>
                    </p>

                    {currentView.nav === "exercises" && currentView.page === "createExercise" ? (
                        <Alert variant="info">{t("info.qrCodeNotGenerated")}</Alert>
                    ) : (
                        <div style={{ height: "100px" }}>
                            <Image src={`https://qr.klett.pl/get-qr-code?module=testomat&hash=${recording.kId}`} className="qrcode-recording" alt={`${t("recording.title")} ${recording.file}`} />
                        </div>
                    )}

                    {recording.transcription ? (
                        <>
                            <div className="my-2">{t("recording.transcription")}:</div>
                            <div className="mb-2" dangerouslySetInnerHTML={{ __html: recording.transcription }}></div>
                        </>
                    ) : null}
                </Col>
            ) : null}
        </Fragment>
    );
};

export default RecordingQRCode;
