import React, { Fragment, useContext, useState } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SortingDropdown from "../../../components/forms/SortingDropdown";
import { getMethod } from "../../../requests/gets";
import TestCard from "./TestCard";
import PaginationCustom from "../../../components/PaginationCustom";
import DownloadTestModal from "../../../components/modals/DownloadTestModal";
import PreviewTestModal from "../../../components/modals/PreviewTestModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { logoutClearCookies } from "../../../components/commonData";
import { BasicContext } from "../../../App";
import { LoggedContext } from "../../../structure/LoggedViews";

const TestsList = (props) => {
    const { t } = useTranslation();
    const { slicedList, filteredList, sortValue, setSortValue, userID, paginationList, activePage, setActivePage } = props;
    const { setAlerts } = useContext(BasicContext);
    const [downloadTestModal, setDownloadTestModal] = useState(null);
    const [previewTestModal, setPreviewTestModal] = useState(null);
    const { setIsLogged } = useContext(LoggedContext);

    const scrollToTop = () => {
        if (document.getElementById("testsList"))
            setTimeout(() => {
                window.scrollTo({
                    top: document.getElementById("testsList").offsetTop,
                    left: 0,
                    behavior: "smooth",
                });
            }, 100);
    };

    const handleResponse = (res) => {
        if (res.status === "success") {
            if (Object.keys(res.data).length === 0) setAlerts([{ variant: "danger", message: t("error.emptyData") }]);
            else return res.data;
        } else {
            if (res.status === "errorLogout") {
                setAlerts([
                    {
                        variant: "info",
                        message: (
                            <>
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                {res.msg}
                            </>
                        ),
                        fading: 4000,
                    },
                ]);
                setIsLogged(false);
                logoutClearCookies();
            } else setAlerts([{ variant: "danger", message: res.msg }]);
            return false;
        }
    };

    const handleDownloadTest = async (testID) => {
        const res = await getMethod(`/tests/${testID}`, localStorage.getItem("token"));
        const fetchData = handleResponse(res);
        if (fetchData) setDownloadTestModal(fetchData);
    };

    const handlePreviewTest = async (testID) => {
        //console.log(testID);
        const res = await getMethod(`/tests/${testID}`, localStorage.getItem("token"));
        //console.log(res);
        const fetchData = handleResponse(res);
        if (fetchData) setPreviewTestModal(fetchData);
    };

    const handleDownloadRecordings = async (testID) => {
        const res = await getMethod(`/tests/${testID}`, localStorage.getItem("token"));
        const fetchData = handleResponse(res);
        if (fetchData) {
            let recordingsExist = false;
            //console.log(fetchData);
            fetchData.exerciseList.forEach((e) => {
                if (e.taskData.recording) {
                    recordingsExist = true;
                    //console.log(e.taskData.recording);
                }
            });

            if (recordingsExist) {
                //window.open(`https://api.klett.pl/chmura/assets/zip/${testID}.zip`);
            } else
                setAlerts([
                    {
                        variant: "info",
                        message: (
                            <>
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                {t("info.testHasNoRecordings")}
                            </>
                        ),
                    },
                ]);
        }
    };

    return (
        <Fragment>
            {!slicedList ? (
                <Container>
                    <Row>
                        <Col className="my-4">
                            <Alert variant="info">{t("info.loadingTestList")}</Alert>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <Fragment>
                    <Container id="testsList">
                        <Row>
                            <Col className="py-4" xs={6}>
                                <p className="text-large pt-4">
                                    {t("numberOfTests")}: {filteredList.length}
                                </p>
                            </Col>
                            <Col className="py-4" xs={6} xl={{ span: 3, offset: 3 }} lg={{ span: 4, offset: 2 }}>
                                <SortingDropdown value={sortValue} onChange={(v) => setSortValue(v)} noAlphabeticalOrder={false} />
                            </Col>
                        </Row>
                        <Row>
                            {slicedList.map((e, i) => (
                                <Col xs={12} lg={6} key={i} className="mb-3">
                                    <TestCard
                                        test={e}
                                        onDownloadTest={handleDownloadTest}
                                        onPreviewTest={handlePreviewTest}
                                        index={i}
                                        userID={userID}
                                        onDownloadRecordings={handleDownloadRecordings}
                                    />
                                </Col>
                            ))}
                        </Row>
                        {filteredList.length > 0 ? <PaginationCustom paginationList={paginationList} activePage={activePage} setActivePage={setActivePage} scrollToTop={scrollToTop} /> : null}
                    </Container>
                    {downloadTestModal && <DownloadTestModal show={true} previewData={downloadTestModal} onCloseModal={() => setDownloadTestModal(null)} />}
                    {previewTestModal && <PreviewTestModal show={true} onClose={() => setPreviewTestModal(null)} test={previewTestModal} />}
                </Fragment>
            )}
        </Fragment>
    );
};

export default TestsList;
