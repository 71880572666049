import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Classes from "../../../components/forms/Classes";
import DifficultyLvl from "../../../components/forms/DifficultyLvl";
import DislexicSwitch from "../../../components/forms/DislexicSwitch";
import Subject from "../../../components/forms/Subject";
import { useTranslation } from "react-i18next";
import { LoggedContext } from "../../../structure/LoggedViews";
import Number from "../../../components/forms/Number";
import { BasicContext } from "../../../App";
import { getMethod } from "../../../requests/gets";
import DependentDropdowns2 from "../../../components/forms/DependentDropdowns2";
import DependentDropdown from "../../../components/forms/DependentDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { logoutClearCookies } from "../../../components/commonData";

const BasicData = (props) => {
    const { t } = useTranslation();
    const [selectedArrays, setSelectedArrays] = useState({});
    const { permissions, currentView } = useContext(LoggedContext);
    const [fetchSkills, setFetchSkills] = useState(false);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [fetchCategories, setFetchCategories] = useState(false);
    const { exerciseData, setExerciseData } = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOptions = async () => {
            const res = await getMethod(`/categories/skills?lang=${exerciseData.subject}`, localStorage.getItem("token"));
            if (res.status !== "success") {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            } else {
                setSelectedArrays({ ...selectedArrays, skills: res.data.skills });
            }
        };
        if (fetchSkills) fetchOptions();
        return () => {
            setFetchSkills(false);
        };
    }, [fetchSkills]);

    useEffect(() => {
        const fetchOptions = async () => {
            const res = await getMethod(`/categories/sub?level=${exerciseData.eduStage}`, localStorage.getItem("token"));
            if (res.status !== "success") {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            } else {
                setSelectedArrays({ ...selectedArrays, categories: res.data.categories });
            }
        };
        if (fetchCategories) fetchOptions();
        return () => {
            setFetchCategories(false);
        };
    }, [fetchCategories]);

    useEffect(() => {
        if (currentView.nav === "exercises" && currentView.page === "editExercise") {
            setFetchSkills(true);
        } else setLoading(false);
    }, []);

    useEffect(() => {
        if (currentView.nav === "exercises" && currentView.page === "editExercise" && loading && !selectedArrays.eduStage) {
            if (selectedArrays.categories) {
                const eduStageArray = permissions.filter((e) => e.short === exerciseData.subject)[0].children;
                const seriesArray = eduStageArray.filter((e) => e.short === exerciseData.eduStage)[0].children;
                //console.log(exerciseData.series, seriesArray);
                const volumesArray = exerciseData.series ? seriesArray.filter((e) => e.short === exerciseData.series)[0].children : null;
                setSelectedArrays({
                    ...selectedArrays,
                    eduStage: eduStageArray,
                    series: seriesArray,
                    classes: { min: eduStageArray.filter((e) => e.short === exerciseData.eduStage)[0].min, max: eduStageArray.filter((e) => e.short === exerciseData.eduStage)[0].max },
                    volumes: volumesArray,
                    chapters: exerciseData.volumes ? volumesArray.filter((e) => e.short === exerciseData.volumes)[0].children : null,
                });

                setLoading(false);
            } else if (selectedArrays.skills) {
                setFetchCategories(true);
            }
        }
    }, [selectedArrays]);

    //console.log(exerciseData);

    return (
        <Fragment>
            <Row>
                <Col className="py-3">
                    <h1 className="h4 py-4">{t("createExercise.basicData.title")}</h1>
                </Col>
            </Row>
            {loading ? (
                t("loading")
            ) : (
                <Row>
                    <Col xs={6} xl={3}>
                        <Subject
                            value={exerciseData.subject}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, subject: v, eduStage: null, series: null, classes: [], chapters: [], skills: null, subSkills: [] });
                                setSelectedArrays({ ...selectedArrays, eduStage: permissions.filter((e) => e.short === v)[0].children });
                                setFetchSkills(true);
                            }}
                        />
                    </Col>
                    <Col xs={6} xl={4}>
                        <DependentDropdown
                            value={exerciseData.eduStage}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, eduStage: v, series: null, classes: [], volumes: null, chapters: [], categories: null, subCategories: [] });
                                setSelectedArrays({
                                    ...selectedArrays,
                                    series: selectedArrays.eduStage.filter((e) => e.short === v)[0].children,
                                    classes: { min: selectedArrays.eduStage.filter((e) => e.short === v)[0].min, max: selectedArrays.eduStage.filter((e) => e.short === v)[0].max },
                                });

                                setFetchCategories(true);
                            }}
                            req
                            options={selectedArrays.eduStage}
                            dependentOption={{ value: exerciseData.subject, name: t("subject") }}
                            dropdownID="educationStageDropdown"
                            dropdownLabel={t("educationStage")}
                        />
                    </Col>
                    <Col xs={6} xl={3}>
                        <DependentDropdown
                            value={exerciseData.series}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, series: v ? v : null, chapters: [], volumes: null });
                                setSelectedArrays({ ...selectedArrays, volumes: v ? selectedArrays.series.filter((e) => e.short === v)[0].children : null });
                            }}
                            options={selectedArrays.series}
                            dropdownID="seriesDropdown"
                            dropdownLabel={t("series")}
                            clearable
                            dependentOption={{ value: exerciseData.eduStage, name: t("educationStage") }}
                        />
                    </Col>
                    <Col xs={6} xl={2}>
                        <Classes
                            value={exerciseData.classes}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, classes: v });
                            }}
                            options={selectedArrays.classes}
                            dropdownID="classesDropdown"
                            dropdownLabel={t("class")}
                            dependentOption={{ value: exerciseData.eduStage, name: t("educationStage") }}
                            multiple
                        />
                    </Col>
                    <Col xs={6} xl={3}>
                        <DependentDropdown
                            value={exerciseData.volumes}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, volumes: v ? v : null, chapters: [] });
                                setSelectedArrays({ ...selectedArrays, chapters: v ? selectedArrays.volumes.filter((e) => e.short === v)[0].children : null });
                            }}
                            options={selectedArrays.volumes}
                            dropdownID="volumesDropdown"
                            dropdownLabel={t("volumes")}
                            dependentOption={{ value: exerciseData.series, name: t("series") }}
                            clearable
                        />
                    </Col>
                    <Col xs={6} xl={3}>
                        <DependentDropdown
                            value={exerciseData.chapters}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, chapters: v });
                            }}
                            options={selectedArrays.chapters}
                            dropdownID="chaptersDropdown"
                            dropdownLabel={t("chapters")}
                            dependentOption={{ value: exerciseData.volumes, name: t("volumes") }}
                            multiple
                        />
                    </Col>
                    <Col xs={6} xl={3} lg={3}>
                        <DifficultyLvl
                            value={exerciseData.difficultyLvl}
                            onChangeValue={(v) => {
                                setExerciseData({ ...exerciseData, difficultyLvl: v });
                            }}
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <DependentDropdowns2
                            label={t("categories")}
                            unfilteredArray={selectedArrays.categories}
                            dependentOption={{ value: exerciseData.eduStage, name: t("educationStage") }}
                            value1={exerciseData.categories}
                            value2={exerciseData.subCategories}
                            onChangeValue={(v1, v2) => setExerciseData({ ...exerciseData, categories: v1, subCategories: v2 })}
                            dropdownID="categories"
                        />
                    </Col>
                    <Col xs={12} lg={6}>
                        <DependentDropdowns2
                            label={t("skills")}
                            unfilteredArray={selectedArrays.skills}
                            dependentOption={{ value: exerciseData.subject, name: t("subject") }}
                            value1={exerciseData.skills}
                            value2={exerciseData.subSkills}
                            onChangeValue={(v1, v2) => setExerciseData({ ...exerciseData, skills: v1, subSkills: v2 })}
                            dropdownID="skills"
                        />
                    </Col>

                    <Col xs={6} lg={3} xl={2}>
                        <Number
                            value={exerciseData.time}
                            onChange={(v) => {
                                setExerciseData({ ...exerciseData, time: v });
                            }}
                            formID="estimatedTime"
                            formPl={t("typeMinutes")}
                            label={t("estimatedTime")}
                            integer
                            req
                        />
                    </Col>
                    <Col xs={6} lg={3} xl={2}>
                        <Number
                            value={exerciseData.points}
                            onChange={(value) => {
                                setExerciseData({ ...exerciseData, points: value });
                            }}
                            formID="points"
                            formPl={t("typePoints")}
                            label={t("points")}
                            integer
                            req
                        />
                    </Col>
                    <Col xs={6} lg={3} xl={2}>
                        <DislexicSwitch label={t("forDislexic")} value={exerciseData.dyslexic} onChange={(e) => setExerciseData({ ...exerciseData, dyslexic: e.target.checked })} />
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default BasicData;
