import React, { Fragment, forwardRef, useState, useEffect } from "react";
import $ from "jquery";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BasicTestDataPreview from "./BasicTestDataPreview";
import ExercisePreviewInTest from "./ExercisePreviewInTest";

const TestPrint = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { previewData, exerciseList, groupNumber, printAnswers } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [exercisesOnPages, setExercisesOnPages] = useState(null);
    const [exerciseNumber, setExerciseNumber] = useState(null);
    const [footerPaddings, setFooterPaddings] = useState(null);
    const a4width = 1115;

    const setPdfPreview = (pageInnerHeight, footerHeight) => {
        const pages = [],
            footerTopPaddings = [],
            exerciseNumbers = [];
        let exerInPage = [],
            pageWithExerHeight = document.getElementById("basicTestDataPreview").offsetHeight,
            exerciseNumbersList = [];

        exerciseList.forEach((e, j) => {
            let exerH = $(`#exerContent${j + 1}`).outerHeight(true); // offsetHeight + margins
            //console.log(`#exerContent${j + 1}`, exerH);
            if (pageWithExerHeight + exerH < pageInnerHeight) {
                exerInPage.push(e);
                exerciseNumbersList.push(j);
                pageWithExerHeight += exerH;
            } else {
                footerTopPaddings.push(pageInnerHeight - pageWithExerHeight + footerHeight); //rest od space in px that will be used as paddingTop for footer to simulate bottom position of footer
                pages.push(exerInPage);
                exerciseNumbers.push(exerciseNumbersList);
                exerInPage = [e];
                exerciseNumbersList = [j];
                pageWithExerHeight = 0 + exerH;
            }
        });
        if (exerInPage.length > 0) {
            pages.push(exerInPage);
            footerTopPaddings.push(pageInnerHeight - pageWithExerHeight + footerHeight);
            exerciseNumbers.push(exerciseNumbersList);
        }

        setExerciseNumber(exerciseNumbers);
        setFooterPaddings(footerTopPaddings);
        setExercisesOnPages(pages); // exercise list that is set in pages

        $(".body-preview").css({ display: "none" }); // hide current displaying exercise list that is not set in pages
        $(".footer-preview").css({ display: "none" }); // hide current displaying exercise list that is not set in pages
    };

    const setPageHeightAndExercisesPerPage = () => {
        setCurrentPage(1);
        setExercisesOnPages(null);

        if ($(".body-preview").length) {
            $(".body-preview").css({ display: "block" }); //show to calculate height of exercises after every tab change

            const height = a4width * 1.4142,
                footerHeight = $(".pdf-viewer-footer").outerHeight(),
                padding = 96;
            $(".pdf-viewer").css({ width: `${a4width}px`, height: `${height}px` });
            $(".pdf-viewer-body").css({ height: `${height - footerHeight - padding * 2}px` });

            setPdfPreview((height - padding * 2 - footerHeight).toFixed(0), footerHeight);
        }
    };

    useEffect(() => {
        setPageHeightAndExercisesPerPage();
    }, []);

    return (
        <Fragment>
            <div ref={ref} className="test-view">
                <div className="position-relative pdf-viewer" style={{ width: "100%" }}>
                    <div className="pdf-viewer-body">
                        <BasicTestDataPreview previewData={previewData} groupLetter={String.fromCharCode(previewData.groups[groupNumber].charCode)} showAnswers={printAnswers} />
                        <div className="body-preview">
                            {exerciseList.map((e, j) => (
                                <ExercisePreviewInTest exercise={e} index={j + 1} currentKey={groupNumber} key={j} showAnswers={printAnswers} />
                            ))}
                        </div>
                        <div className="footer-preview">
                            <div className="pdf-viewer-footer">
                                <Image src="/images/klett logo.png" className="main-logo" />
                                <Image src="/images/klett-testomat-kursor-logo.svg" />
                                <p className="d-inline float-end" style={{ paddingTop: "12px" }}>
                                    {t("page")} {currentPage} {t("from")} {exercisesOnPages ? exercisesOnPages.length : 1}
                                </p>
                            </div>
                        </div>
                        {exercisesOnPages ? (
                            <div>
                                {exercisesOnPages.map((p, j) => (
                                    <Fragment key={j}>
                                        <div>
                                            <div style={j > 0 ? { paddingTop: "96px" } : null}>
                                                {p.map((e, k) => (
                                                    <ExercisePreviewInTest exercise={e} index={exerciseNumber[j][k] + 1} currentKey={groupNumber} key={k} showAnswers={printAnswers} />
                                                ))}
                                            </div>
                                            <div className="pdf-viewer-footer" style={{ paddingTop: `${footerPaddings[j]}px` }}>
                                                <Image src="/images/klett logo.png" className="main-logo" />
                                                <Image src="/images/klett-testomat-kursor-logo.svg" />
                                                <p className="d-inline float-end" style={{ paddingTop: "12px" }}>
                                                    {t("page")} {j + 1} {t("from")} {exercisesOnPages ? exercisesOnPages.length : 1}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="page-break" />
                                    </Fragment>
                                ))}
                            </div>
                        ) : (
                            t("loading")
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default TestPrint;
