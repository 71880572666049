import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faKey, faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState } from "react";
import { Row, Col, Form, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoggedContext } from "../../structure/LoggedViews";
import Stars from "../Stars";
import ExercisePreview from "./ExercisePreview";

const ExerciseCard = (props) => {
    const { t } = useTranslation();
    const { filters } = useContext(LoggedContext);
    const { exerData, view, loggedUserID } = props;
    const [key, setKey] = useState(0);
    const [showAnswers, setShowAnswers] = useState(false);

    const getName = (array, value) => {
        let text = null;
        array.forEach((e) => {
            if (e.short === value) text = e.title;
        });

        return text;
    };

    return (
        <Fragment>
            {!exerData ? (
                t("loading")
            ) : (
                <Row>
                    <Col lg={8} xs={6}>
                        <p className="mb-3">
                            {getName(filters.subject, exerData.taskData.basicData.subject)}, {getName(filters.eduStage, exerData.taskData.basicData.eduStage)}
                        </p>
                    </Col>
                    <Col lg={4} xs={6} className="text-end">
                        <p className="mb-3">
                            {exerData.taskData.basicData.time} min, {exerData.taskData.basicData.points}p.
                        </p>
                    </Col>
                    {view === "list" && (
                        <>
                            <Col xs={6} className="mb-3">
                                <Stars difficultyLvl={exerData.taskData.basicData.difficultyLvl} />
                            </Col>
                            <Col xs={6} className="text-end">
                                {exerData.author.accountData.name === process.env.REACT_APP_CLIENT_NAME && exerData.author.accountData.surname === process.env.REACT_APP_CLIENT_SURNAME ? (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltipOwnerIsAuthor`}>
                                                {t("ownerIsAuthor", { author: `${process.env.REACT_APP_CLIENT_NAME} ${process.env.REACT_APP_CLIENT_SURNAME}` })}
                                            </Tooltip>
                                        }
                                    >
                                        <Image src={`/images/icons/${process.env.REACT_APP_CLIENT_ICON}`} alt={`${process.env.REACT_APP_CLIENT_ICON}`} className="mx-1 exercise-card-icon" />
                                    </OverlayTrigger>
                                ) : loggedUserID === exerData.author.accountUid ? (
                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipUserIsAuthor`}>{t("youAreTheAuthor")}</Tooltip>}>
                                        <FontAwesomeIcon icon={faUser} className="mx-1 exercise-card-icon" />
                                    </OverlayTrigger>
                                ) : null}
                                {exerData.recording ? (
                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipExerHasRecording`}>{t("exerciseHasRecording")}</Tooltip>}>
                                        <FontAwesomeIcon icon={faRecordVinyl} className="mx-1 exercise-card-icon" />
                                    </OverlayTrigger>
                                ) : null}

                                {exerData.taskData.exerType !== "exerWriting" && (
                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltipExerHasAnswerKey`}>{t("exerciseHasAnswerKey")}</Tooltip>}>
                                        {/* <Image src="/images/icons/key_icon.png" alt="key icon" className="mx-1 exercise-card-icon" /> */}
                                        <FontAwesomeIcon icon={faKey} className="mx-1 exercise-card-icon" id="keyIcon" />
                                    </OverlayTrigger>
                                )}
                            </Col>
                        </>
                    )}
                    <Col xs={12} className="mb-3">
                        <ExercisePreview
                            size="sm"
                            versions={exerData.taskData.versions}
                            showAnswers={showAnswers}
                            currentKey={key}
                            setKey={(k) => setKey(k)}
                            exerType={exerData.taskData.exerType}
                            recording={exerData.recording}
                        />
                    </Col>
                    {view === "addExercise" && (
                        <Col xs={12}>
                            <Form.Check
                                label={t("showAnswers")}
                                type="switch"
                                id="showAnswersSwitch"
                                checked={showAnswers}
                                onChange={() => setShowAnswers(!showAnswers)}
                                className="mb-3 form-check-sm"
                                disabled={exerData.taskData.exerType === "exerWriting"}
                            />
                        </Col>
                    )}
                </Row>
            )}
        </Fragment>
    );
};

export default ExerciseCard;
