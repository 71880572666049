import { faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState, createContext } from "react";
import { Alert, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LoggedContext } from "../../structure/LoggedViews";
import { difficultyLvlOptions } from "../commonData";
import ExercisePreview from "../exercises/ExercisePreview";
import $ from "jquery";

export const ExercisePreviewContext = createContext();

const PreviewExerciseModal = (props) => {
    const { t } = useTranslation();
    const { show, onClose, versions, currentKey, setKey, exerType, basicData, recording } = props;
    const [showAnswers, setShowAnswers] = useState(false);
    const { filters } = useContext(LoggedContext);

    const getName = (name, short) => {
        const propertiesArray = Object.keys(filters);
        let nameTxt = "";

        propertiesArray.forEach((p) => {
            if (p === name) {
                filters[p].forEach((f) => {
                    if (f.short === short) nameTxt = f.title;
                });
            }
        });
        return nameTxt;
    };

    const difficultyLvlName = (value) => {
        const array = difficultyLvlOptions();
        let name = "";

        array.forEach((el) => {
            if (el.value === value) name = el.text;
        });

        return name;
    };

    const getNames = (name, array, parentValue) => {
        const arrayWithNames = [],
            propertiesArray = Object.keys(filters);
        let namesTxt = "";

        propertiesArray.forEach((p) => {
            if (p === name) {
                filters[p].forEach((f) => {
                    if (f.children) {
                        if (f.short === parentValue) {
                            f.children.forEach((c) => {
                                array.forEach((a) => {
                                    if (a === c.short) arrayWithNames.push(c.title);
                                });
                            });
                        }
                    } else {
                        array.forEach((a) => {
                            if (a === f.short) arrayWithNames.push(f.title);
                        });
                    }
                });
            }
        });

        arrayWithNames.forEach((n, i) => {
            namesTxt += n;
            if (i + 1 < arrayWithNames.length) {
                namesTxt += ", ";
            }
        });
        return namesTxt ? (
            namesTxt
        ) : (
            <Alert variant="warning" className="alert-xs d-inline-block">
                {t("warning.noElementsOnTheFilterList")}
            </Alert>
        );
    };

    useEffect(() => {
        $(document).ready(function() {
            const width = $("#exerPreview .tab-content").width();
            const height = $("#exerPreview .tab-content").height();
            //console.log(width, height);
            $("#exerPreview figure img").each(function() {
                $(this).css({ "max-width": `${width}px`, "max-height": `${height}px` });
            });
        });
    }, []);

    return (
        <Modal show={show} onHide={onClose} dialogClassName="modal-80w">
            <Modal.Header closeButton>
                <Modal.Title>{t("exercisePreview.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={{ span: 12, order: 1 }} lg={{ span: 7, order: 1 }}>
                        <ExercisePreview versions={versions} showAnswers={showAnswers} currentKey={currentKey} setKey={setKey} exerType={exerType} recording={recording} />
                    </Col>
                    <Col xs={{ span: 12, order: 3 }} lg={{ span: 5, order: 2 }}>
                        <Row>
                            <Col xs={12} style={{ fontSize: "1.125rem" }}>
                                {basicData.subject ? getName("subject", basicData.subject) : <span className="no-data">{t("error.noChosenSubject")}</span>},{" "}
                                {basicData.eduStage ? getName("eduStage", basicData.eduStage) : <span className="no-data">{t("error.noChosenEduStage")}</span>}
                                {basicData.series && ", " + getName("series", basicData.series)}
                            </Col>
                            <Col xs={12} className="my-3">
                                <div className="mb-1">
                                    {t("classes")}:{" "}
                                    <b>
                                        {basicData.classes.length === 0
                                            ? t("lack")
                                            : basicData.classes.map((e, i) => (
                                                  <Fragment key={i}>
                                                      {t("class")} {e}
                                                      {i !== basicData.classes.length - 1 && ", "}
                                                  </Fragment>
                                              ))}
                                    </b>
                                </div>
                                <div className="mb-1">
                                    {t("chapters")}: {basicData.chapters.length === 0 ? <i>{t("lack")}</i> : <b>{getNames("chapters", basicData.chapters)}</b>}
                                </div>
                                <div className="mb-1">
                                    {t("categories")}: {!basicData.categories ? <i>{t("lack")}</i> : <b>{getName("categories", basicData.categories)}</b>}
                                </div>
                                <div className="mb-1">
                                    {t("subCategories")}: {basicData.subCategories.length === 0 ? <i>{t("lack")}</i> : <b>{getNames("categories", basicData.subCategories, basicData.categories)}</b>}
                                </div>
                                <div className="mb-1">
                                    {t("skills")}: {!basicData.skills ? <i>{t("lack")}</i> : <b>{getName("skills", basicData.skills)}</b>}
                                </div>
                                <div className="mb-1">
                                    {t("subSkills")}: {basicData.subSkills.length === 0 ? <i>{t("lack")}</i> : <b>{getNames("skills", basicData.subSkills, basicData.skills)}</b>}
                                </div>

                                <div className="mb-1">
                                    {t("estimatedTime")}: <b>{basicData.time ? `${basicData.time} min` : <span className="no-data">{t("error.noTypedTime")}</span>}</b>
                                </div>
                                <div className="mb-1">
                                    {t("points")}: <b>{basicData.points ? basicData.points : <span className="no-data">{t("error.noTypedPoints")}</span>}</b>
                                </div>
                                <div className="mb-1">
                                    {t("difficultyLvl.label")}:{" "}
                                    <b>{basicData.difficultyLvl ? difficultyLvlName(basicData.difficultyLvl) : <span className="no-data">{t("error.noTypedPoints")}</span>}</b>
                                </div>

                                <div className="mb-1">
                                    {t("forDislexic")}: <b>{basicData.dyslexic ? t("yes") : t("no")}</b>
                                </div>
                                <div className="mb-1">
                                    {t("recording.title")}:{" "}
                                    {recording ? (
                                        <b>
                                            <FontAwesomeIcon icon={faRecordVinyl} className="me-1" />
                                            {recording.file}
                                        </b>
                                    ) : (
                                        <i>{t("lack")}</i>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 3 }}>
                        <Form.Check
                            label={t("showAnswers")}
                            type="switch"
                            id="showAnswersSwitch"
                            checked={showAnswers}
                            onChange={() => setShowAnswers(!showAnswers)}
                            className="my-2"
                            disabled={exerType === "exerWriting" && !recording}
                        />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default PreviewExerciseModal;
