import { faCheck, faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { Fragment, useContext } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { LoggedContext } from "../../structure/LoggedViews";

const ExerciseNavbar = (props) => {
    const { setPreviewModal, exerType, disableSubmitBtn, disableAction, setShowCancelModal, pos } = props;
    const { currentView } = useContext(LoggedContext);

    return (
        <Fragment>
            <div id="exerciseNavbar" className={pos ? "sticky-top" : ""}>
                <Container>
                    <Row>
                        <Col className="me-auto" lg={6} md={4} xs={12}>
                            <p className="h4 creator-title my-2 my-lg-0 mt-lg-3 ">{t(`${currentView.page === "editExercise" ? "editExercise" : "createExercise.title"}`)}</p>
                        </Col>
                        <Col className="text-end" lg={6} md={8} xs={12}>
                            <Button variant="outline-primary" className="mx-lg-2 mx-1 d-inline-block my-2" onClick={() => setShowCancelModal(true)}>
                                <FontAwesomeIcon icon={faTimes} className="me-2" />
                                {t("cancel")}
                            </Button>
                            {!exerType ? (
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="preview-exercise-tooltip-disabled">{t("createExercise.firstChooseExerType")}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button disabled className="mx-lg-2 mx-1 d-inline-block my-2" variant="outline-primary" style={{ pointerEvents: "none" }}>
                                            <FontAwesomeIcon icon={faEye} className="me-2" />
                                            {t("preview")}
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <Button
                                    variant="outline-primary"
                                    className="mx-lg-2 mx-1 d-inline-block my-2"
                                    onClick={() => {
                                        setPreviewModal(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEye} className="me-2" />
                                    {t("preview")}
                                </Button>
                            )}
                            {disableSubmitBtn ? (
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="preview-exercise-tooltip-disabled">{t(`error.${disableSubmitBtn}`)}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button disabled className="mx-lg-2 mx-1 d-inline-block my-2" variant="primary" style={{ pointerEvents: "none" }}>
                                            <FontAwesomeIcon icon={faCheck} className="me-2" />
                                            {t("saveAndFinish")}
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <Button variant="primary" className="mx-lg-2 mx-1 d-inline-block my-2" type="submit" disabled={disableAction}>
                                    <FontAwesomeIcon icon={faCheck} className="me-2" />
                                    {t("saveAndFinish")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};

export default ExerciseNavbar;
