import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState } from "react";
import { Button, Col, Form, Image, Row, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TaskText from "../../../../components/forms/TaskText";
import TextToTheTask from "../../../../components/forms/TextToTheTask";
import { ExerciseContext } from "../ExerciseForm";
import CheckRadioAnswers from "./CheckRadioAnswers";

const CheckRadio = () => {
    const { t } = useTranslation();
    const { versions, currentKey, changeValue } = useContext(ExerciseContext);
    const [subExerKey, setSubExerKey] = useState(0);
    const subExercisesInitial = {
        key: 0,
        taskText: "",
        answers: [
            { charCode: 65, content: "", autoFocus: false, correct: false },
            { charCode: 66, content: "", autoFocus: false, correct: false },
        ],
    };

    const configBtns = [
        {
            value: "col1",
            size: 12,
            txt: t("createExercise.exerciseContent.checkRadio.oneColumn"),
        },
        {
            value: "col2",
            size: 6,
            txt: t("createExercise.exerciseContent.checkRadio.twoColumns"),
        },
        {
            value: "col3",
            size: 4,
            txt: t("createExercise.exerciseContent.checkRadio.threeColumns"),
        },
        {
            value: "col4",
            size: 3,
            txt: t("createExercise.exerciseContent.checkRadio.fourColumns"),
        },
    ];

    const subPointsBtns = [
        {
            src: "subpoint-one.svg",
            alt: "subpoint one option",
            txt: t("createExercise.exerciseContent.checkRadio.oneExercise"),
            value: "one",
        },
        {
            src: "subpoint-many.svg",
            alt: "subpoint many option",
            txt: t("createExercise.exerciseContent.checkRadio.manyExercises"),
            value: "many",
        },
    ];

    return (
        <Fragment>
            {versions[currentKey] && (
                <Fragment>
                    {!versions[currentKey].config ? (
                        t("loading")
                    ) : (
                        <Row>
                            <Col xs={12} className="mb-3">
                                <TaskText id={`taskText${currentKey}`} value={versions[currentKey].taskText} onChange={(v) => changeValue("taskText", v)} />
                            </Col>
                            <TextToTheTask value={versions[currentKey].optionalTxt} changeValue={(v) => changeValue("optionalTxt", v)} />

                            {/* column configuration */}
                            <Row id={`checkRadioContent`}>
                                <Col xs={12} id="configColumnsToggleBtnGroup">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="d-block mb-3">{t("createExercise.exerciseContent.checkRadio.config")}</Form.Label>

                                        <ToggleButtonGroup
                                            name="config-toggle-btn-group"
                                            value={versions[currentKey].config.value}
                                            onChange={(v) => {
                                                let selectedOption = null;
                                                configBtns.forEach((e) => {
                                                    if (e.value === v) selectedOption = e;
                                                });

                                                changeValue("config", selectedOption);
                                            }}
                                        >
                                            {configBtns.map((e, i) => (
                                                <ToggleButton
                                                    key={i}
                                                    id={`config-btn-${i}`}
                                                    value={e.value}
                                                    variant="outline-primary"
                                                    className={`exercise-type ${e.value === versions[currentKey].config.value ? "active" : ""}`}
                                                >
                                                    <Image src={`/images/icons/config-0${i + 1}.svg`} alt={e.txt} />
                                                    <p>
                                                        <span>{e.txt}</span>
                                                    </p>
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                    </Form.Group>
                                </Col>

                                {/* subpoints configuration */}
                                <Fragment>
                                    <Col xs={12} id="subPointsToggleBtnGroup">
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("createExercise.exerciseContent.checkRadio.sub")}</Form.Label>
                                            <p className="text-small mb-3">{t("createExercise.exerciseContent.checkRadio.subTxt")}</p>

                                            <ToggleButtonGroup
                                                type="radio"
                                                name="subpoints-toggle-btn-group"
                                                value={versions[currentKey].subPoints}
                                                onChange={(v) => {
                                                    changeValue("subPoints", v);
                                                }}
                                            >
                                                {subPointsBtns.map((e, i) => (
                                                    <ToggleButton
                                                        key={i}
                                                        id={`tbg-btn-${i}`}
                                                        value={e.value}
                                                        variant="outline-primary"
                                                        className={`exercise-type ${e.value === versions[currentKey].subPoints ? "active" : ""}`}
                                                    >
                                                        <Image src={`/images/icons/${e.src}`} alt={e.alt} />
                                                        <p>
                                                            <span>{e.txt}</span>
                                                        </p>
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} id="subPointsAnswers">
                                        {versions[currentKey].subPoints === "one" ? (
                                            <CheckRadioAnswers mainArray={versions[currentKey]} updateData={changeValue} index={currentKey} />
                                        ) : versions[currentKey].subPoints === "many" ? (
                                            <Fragment>
                                                {!versions[currentKey].subExercises ? (
                                                    t("loading")
                                                ) : (
                                                    <Fragment>
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    variant="link"
                                                                    className="py-0 mb-4 ps-0"
                                                                    onClick={() => {
                                                                        const list = [...versions[currentKey].subExercises];
                                                                        list.push(subExercisesInitial);
                                                                        list[list.length - 1].key = list.length - 1;
                                                                        setSubExerKey(list.length - 1);
                                                                        changeValue("subExercises", list);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                                                    {t("createExercise.exerciseContent.addSubExercise")}
                                                                </Button>
                                                                <Button
                                                                    variant="link"
                                                                    className="text-danger py-0 mb-4"
                                                                    onClick={() => {
                                                                        const list = [...versions[currentKey].subExercises];
                                                                        list.splice(subExerKey, 1);
                                                                        list.forEach((e, k) => {
                                                                            e.key = k; //renumerate versions
                                                                        });
                                                                        setSubExerKey(subExerKey - 1 < 0 ? 0 : subExerKey - 1); //activate tab on the left of the deleted version; if first is deleted, activate the newest first tab
                                                                        changeValue("subExercises", list);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                                                                    {t("createExercise.exerciseContent.deleteSubExercise")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="mb-5">
                                                                <Tabs id="sub-exersices" activeKey={subExerKey} onSelect={(k) => setSubExerKey(parseInt(k))}>
                                                                    {versions[currentKey].subExercises.map((e, i) => (
                                                                        <Tab eventKey={i} title={`${t("subExercise")} ${i + 1}`} key={i}>
                                                                            <Row>
                                                                                <Col xs={12} className="mb-3">
                                                                                    <TaskText
                                                                                        value={e.taskText}
                                                                                        subExercise
                                                                                        onChange={(v) => {
                                                                                            const list = [...versions[currentKey].subExercises];
                                                                                            list[i].taskText = v;
                                                                                            changeValue("subExercises", list);
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                                <Col xs={12}>
                                                                                    <CheckRadioAnswers
                                                                                        mainArray={versions[currentKey].subExercises[subExerKey]}
                                                                                        updateData={(name, value) => {
                                                                                            const list = versions[currentKey].subExercises;
                                                                                            list[subExerKey][name] = value;
                                                                                            changeValue("subExercises", list);
                                                                                        }}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Tab>
                                                                    ))}
                                                                </Tabs>
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                )}
                                            </Fragment>
                                        ) : null}
                                    </Col>
                                </Fragment>
                            </Row>
                        </Row>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckRadio;
