import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TestSummaryBasicData from "../../pages/tests/add-edit/TestSummaryBasicData";
import TestSummaryPdfPreview from "../../pages/tests/add-edit/TestSummaryPdfPreview";

const PreviewTestModal = (props) => {
    const { t } = useTranslation();
    const { show, onClose, test } = props;
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAnswers, setShowAnswers] = useState(false);

    useEffect(() => {
        //console.log(test);

        setTimeout(() => {
            setList(test.exerciseList);
            setLoading(false);
        }, 500);
    }, []);

    return (
        <Modal show={show} onHide={onClose} dialogClassName="modal-80w" scrollable>
            <Modal.Header closeButton>
                <Modal.Title>{t("testPreview", { testName: test.testTitle })}</Modal.Title>
            </Modal.Header>
            <Modal.Body id="previewTestModalBody">
                {loading ? (
                    t("loading")
                ) : (
                    <Row>
                        <TestSummaryBasicData previewData={test} />
                        <Col xs={12}>
                            <Form.Check label={t("showAnswers")} type="switch" id="showAnswersSwitch" checked={showAnswers} onChange={() => setShowAnswers(!showAnswers)} className="my-2" />
                        </Col>
                        <TestSummaryPdfPreview loading={false} previewData={test} exerciseList={list} showAnswers={showAnswers} />
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default PreviewTestModal;
