import React, { Fragment, useRef, useCallback, useEffect, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { BasicContext } from "../../App";
import { useTranslation } from "react-i18next";
import TestPrint from "./TestPrint";

const GenerateTestPrint = (props) => {
    const { t } = useTranslation();
    const componentRef = useRef(null);
    const { setAlerts } = useContext(BasicContext);
    const onBeforeGetContentResolve = useRef(null);
    const { groupNumber, print, setLoadingPrint, previewData, exerciseList, setPrint, printAnswers } = props;

    const handleAfterPrint = useCallback(() => {
        //console.log("`onAfterPrint` called"); // tslint:disable-line no-console
        setPrint(false);
    }, []);

    const handleBeforePrint = useCallback(() => {
        //console.log("`onBeforePrint` called"); // tslint:disable-line no-console
    }, []);

    const handleOnBeforeGetContent = useCallback(() => {
        //console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
        let group = String.fromCharCode(previewData.groups[groupNumber].charCode);
        setAlerts([{ variant: "info", message: t(printAnswers ? "info.printingAnswers" : "info.printing", { group: group }) }]);
        setLoadingPrint(true);

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                resolve();
            }, 800);
        });
    }, [setLoadingPrint]);

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `${previewData.testTitle.replace(/\s+/, "_")}_${moment()
            .format("YYYY-MM-DD")
            .replace(/s+/, "_")}`,
        onBeforeGetContent: handleOnBeforeGetContent,
        onBeforePrint: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        removeAfterPrint: true,
    });

    useEffect(() => {
        if (print) handlePrint();
    }, []);

    return (
        <Fragment>
            <div style={{ marginTop: "1000px" }}>
                <TestPrint ref={componentRef} previewData={previewData} exerciseList={exerciseList} groupNumber={groupNumber} printAnswers={printAnswers} />
            </div>
        </Fragment>
    );
};

export default GenerateTestPrint;
