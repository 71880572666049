import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BasicTestDataPreview = (props) => {
    const { t } = useTranslation();
    const { previewData, groupLetter, showAnswers } = props;
    return (
        <Row id="basicTestDataPreview">
            <Col xs={8}>
                <p className="mb-2">
                    <b>{previewData.testTitle ? previewData.testTitle : t("createTest.basicData.testTitle")}</b>
                    {showAnswers && <span> - {t("keyAnswers").toLowerCase()}</span>}
                </p>
            </Col>
            {!showAnswers && (
                <Fragment>
                    <Col xs={4} className="text-end">
                        {previewData.printData.map(
                            (e, i) =>
                                e.name === "score" &&
                                e.value && (
                                    <p className="mb-2" key={i}>
                                        {t("createTest.printData.score")}: .............
                                    </p>
                                )
                        )}
                    </Col>
                    <Col xs={8}>
                        <p className="mb-2">
                            <span>{t("createTest.previewMode.nameAndSurname")}: ............................................</span>
                            {previewData.printData.map(
                                (e, i) =>
                                    e.name === "logNumber" &&
                                    e.value && (
                                        <span className="ps-2" key={i} style={{ whiteSpace: "nowrap" }}>
                                            {t("createTest.printData.logNumberShort")}: .............
                                        </span>
                                    )
                            )}
                        </p>
                    </Col>

                    <Col xs={4} className="text-end">
                        {previewData.printData.map(
                            (e, i) =>
                                e.name === "points" &&
                                e.value && (
                                    <p key={i}>
                                        {t("createTest.printData.points")}: ......../
                                        {previewData.basicData.totalPoints}p.
                                    </p>
                                )
                        )}
                    </Col>

                    <Col xs={8}>
                        <p>
                            {t("class")}: ............
                            {previewData.printData.map(
                                (e, i) =>
                                    e.name === "date" &&
                                    e.value && (
                                        <span key={i} className="ps-2">
                                            {t("createTest.printData.dateShort")}: .....................
                                        </span>
                                    )
                            )}
                        </p>
                    </Col>
                </Fragment>
            )}
            <Col xs={4} className="text-end">
                <p>
                    {`${t("createTest.groups.group")} ${groupLetter}`}
                    {!showAnswers &&
                        previewData.printData.map(
                            (e, i) =>
                                e.name === "time" &&
                                e.value && (
                                    <span key={i}>
                                        , {t("createTest.printData.timeShort")}: {previewData.basicData.totalTime} min
                                    </span>
                                )
                        )}
                </p>
            </Col>
        </Row>
    );
};

export default BasicTestDataPreview;
