import React, { Fragment, useState } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextEditor from "./TextEditor";

const TextToTheTask = (props) => {
    const { t } = useTranslation();
    const [showHelperModal, setShowHelperModal] = useState(false);
    return (
        <Fragment>
            <Col xs={12} className="mb-3">
                <Form.Label>
                    {t("textToTheTask")}
                    <Button onClick={() => setShowHelperModal(true)} className="mx-2 p-0" variant="link">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </Button>
                </Form.Label>
                <TextEditor value={props.value} changeValue={props.changeValue} />
            </Col>
            {showHelperModal ? (
                <Modal show={showHelperModal} onHide={() => setShowHelperModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("createExercise.exerciseContent.taskContentModal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <p className="mb-3">{t("createExercise.exerciseContent.taskContentModal.txt")}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default TextToTheTask;
