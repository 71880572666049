import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import { Modal, Col, Button, Row, Image, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { BasicContext } from "../../App";
import { getMethod } from "../../requests/gets";
import { LoggedContext } from "../../structure/LoggedViews";
import { logoutClearCookies } from "../commonData";

const AddExerciseToTestModal = (props) => {
    const [testsList, setTestsList] = useState(null);
    const [options, setOptions] = useState(null);
    const { exercise, onClose } = props;
    const { t } = useTranslation();
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [selectedTestID, setSelectedTestID] = useState(null);
    const { setCurrentView } = useContext(LoggedContext);
    const [fetchGetTestDetailsAndRedirect, setFetchGetTestDetailsAndRedirect] = useState(false);

    useEffect(() => {
        const fetchTestsList = async () => {
            const res = await getMethod("/tests", localStorage.getItem("token")); //nie pobiera listy zadań - naprawić

            if (res.status === "success") {
                //console.log(res.data);
                const list = [];
                let userData = localStorage.getItem("userData");
                userData = JSON.parse(userData);
                res.data.forEach((e, i) => {
                    list.push({
                        key: i + 1,
                        value: e._id,
                        text: (
                            <>
                                {e.testTitle}
                                {e.author.accountUid === userData.uid ? (
                                    <FontAwesomeIcon icon={faUser} className="ms-2" />
                                ) : e.author.accountData.name === process.env.REACT_APP_CLIENT_NAME && e.author.accountData.surname === process.env.REACT_APP_CLIENT_SURNAME ? (
                                    <Image src={`/images/icons/${process.env.REACT_APP_CLIENT_ICON}`} alt={`${process.env.REACT_APP_CLIENT_ICON}`} className="ms-1 exercise-card-icon" />
                                ) : null}
                            </>
                        ),
                    });
                });
                setOptions(list);
                setTestsList(res.data);
            } else {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            }
        };

        fetchTestsList();
    }, []);

    useEffect(() => {
        const getTestDetailsAndRedirect = async () => {
            const res = await getMethod(`/tests/${selectedTestID}`, localStorage.getItem("token"));
            if (res.status === "success") {
                const selectedTest = res.data;
                //console.log("exercise to add: ", exercise);
                //console.log("selectedTest: ", selectedTest);
                setCurrentView({ nav: "createTest", page: "createNewTest", params: { name: "editTest", exer: exercise, test: selectedTest } });
            } else {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            }
        };

        if (fetchGetTestDetailsAndRedirect) getTestDetailsAndRedirect();
    }, [fetchGetTestDetailsAndRedirect]);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("addExerciseToTest")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{t("chooseTest")}</Form.Label>
                            {!testsList ? (
                                t("loading")
                            ) : (
                                <Dropdown
                                    id="chooseTestDropdown"
                                    options={options}
                                    selection
                                    placeholder={t("select")}
                                    className="form-control"
                                    value={selectedTestID}
                                    onChange={(e, data) => {
                                        setSelectedTestID(data.value);
                                    }}
                                />
                            )}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="w-25" onClick={() => setFetchGetTestDetailsAndRedirect(true)} disabled={selectedTestID ? false : true}>
                    {t("next")}
                </Button>
                <Button variant="outline-primary" className="w-25" onClick={onClose}>
                    {t("cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddExerciseToTestModal;
