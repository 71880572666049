import React from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FooterMain = () => {
    const { t } = useTranslation();
    const links = [
        { link: process.env.REACT_APP_COOKIES_LINK, name: t("cookiePolitics") },
        { link: process.env.REACT_APP_TERMS_OF_USE_LINK, name: t("termsOfUse") },
        { link: process.env.REACT_APP_PRIVACY_POLICY_LINK, name: t("privacyPolicy") },
        { link: process.env.REACT_APP_CONTACT_LINK, name: t("contact") },
    ];

    return (
        <footer id="footerMain">
            <Container className="py-lg-3 py-2 text-lg-start text-center">
                <Row>
                    <Col xl={3} lg={4} xs={6}>
                        <a href={process.env.REACT_APP_MAIN_DOMAIN} target="_blank" rel="noopener noreferrer">
                            <Image src="/images/klett logo.png" alt="klett polska logo" height="40" style={{ border: `1px solid #176883` }} />
                        </a>
                    </Col>
                    <Col className="d-lg-none" xs={6}>
                        <Image src="/images/klett-testomat-kursor-logo.svg" alt="klett polska logo" height="40" className="my-2" />
                    </Col>
                    <Row className="d-lg-none">
                        {links.map((e, i) => (
                            <Col xs={12} md={6} className="py-2" key={i}>
                                <a href={e.link} target="_blank" rel="noopener noreferrer">
                                    {e.name}
                                </a>
                            </Col>
                        ))}
                    </Row>
                    <Col className="d-none d-lg-block text-end pt-2" xl={9} lg={8}>
                        {links.map((e, i) => (
                            <a href={e.link} target="_blank" rel="noopener noreferrer" key={i}>
                                {e.name}
                            </a>
                        ))}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default FooterMain;
