import i18next from "i18next";
import api from "../api";

async function postMethod(link, data, token) {
    //console.log("POST");
    //console.log(data);
    //console.log(`link: ${link}, data: ${data}, token: ${token}`);
    try {
        const response = await api.post(link, data, { headers: { Authorization: "Bearer ".concat(token) } });

        if (response.status !== 200) {
            const msg = response.data.msg ? response.data.msg : "";
            return { status: "error", msg: i18next.exists(`error.${msg}`) ? i18next.t(`error.${msg}`) : i18next.t("error.errorLog"), code: response.status };
        } else return { status: "success", data: response.data };
    } catch (error) {
        //console.log(error);
        let msg = error.response.data.data ? error.response.data.data.msg : "";
        let status = "danger";

        if (msg === "token_malformed" || msg === "token_not_active" || msg === "jwt_error" || msg === "invalid_auth_token" || msg === "no_auth_token" || msg === "token_expired") {
            status = "errorLogout";
        } else if ((error.code === "ERR_BAD_REQUEST" && error.response.status === 401) || error.response.status === 404) {
            status = "danger";
            msg = "ERR_BAD_REQUEST";
        } else {
            status = error.response.data.status;
        }
        return { status: status, msg: i18next.exists(`error.${msg}`) ? i18next.t(`error.${msg}`) : i18next.t("error.errorLog"), code: msg };
    }
}

export default postMethod;
