import React, { Fragment, useEffect } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoginForm from "../components/LoginForm";
import NavbarMain from "../components/navbars/NavbarMain";
import $ from "jquery";

const LoginPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        $("#mainContainer").addClass("light-color-bg");
    }, []);

    return (
        <Fragment>
            <NavbarMain />

            <Container id="notLoggedViews">
                <Row>
                    <Col lg={6} xs={12}>
                        <Row>
                            <Col lg={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                                <LoginForm />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} xs={12} className="text-center">
                        <Image src="/images/klett-testomat-kursor-logo.svg" fluid alt="klett testomat kursor logo" />
                        <h1 id="mainTitle">{t("main.title")}</h1>
                        <Row>
                            <Col xs={12} lg={{ span: 10, offset: 1 }}>
                                <p id="mainTxt" className="mb-4">
                                    {t("aboutApp")}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default LoginPage;
