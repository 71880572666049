import React, { useEffect, useState, useContext, Fragment } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import ExerciseNavbar from "../../../components/navbars/ExerciseNavbar";
import BasicData from "./BasicData";
import ChooseExerciseType from "./ChooseExerciseType";
import ExerciseForm from "./ExerciseForm";
import { useTranslation } from "react-i18next";
import PreviewExerciseModal from "../../../components/modals/PreviewExerciseModal";
import { BasicContext } from "../../../App";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { LoggedContext } from "../../../structure/LoggedViews";
import postMethod from "../../../requests/posts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { logoutClearCookies } from "../../../components/commonData";

const AddEditExercise = () => {
    const { t } = useTranslation();
    const [exerciseData, setExerciseData] = useState({
        basicData: {
            subject: null,
            eduStage: null,
            series: null,
            volumes: null,
            chapters: [],
            categories: null,
            subCategories: [],
            difficultyLvl: null,
            classes: [],
            dyslexic: false,
            time: "",
            points: "",
            skills: null,
            subSkills: [],
        },
        recording: null,
        exerType: null,
        versions: null,
    });
    const [pos, setPos] = useState(null);
    const [key, setKey] = useState(0);
    const [previewModal, setPreviewModal] = useState(false);
    const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [disableAction, setDisableAction] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { setCurrentView, currentView } = useContext(LoggedContext);
    const [fetchAddExercise, setFetchAddExercise] = useState(false);
    const [fetchEditExercise, setFetchEditExercise] = useState(null);
    const [loading, setLoading] = useState(true);

    const changeValue = (name, value) => {
        const list = [...exerciseData.versions];
        list[key][name] = value;
        setExerciseData({ ...exerciseData, versions: list });
    };

    useEffect(() => {
        let error = null;
        if (!exerciseData.basicData) error = "noChosenSubject";
        else if (!exerciseData.basicData.subject) error = "noChosenSubject";
        else if (!exerciseData.basicData.eduStage) error = "noChosenEduStage";
        else if (!exerciseData.basicData.difficultyLvl) error = "noSelectedDifficultyLvl";
        else if (!exerciseData.basicData.time || exerciseData.basicData.time === 0) error = "noTypedTime";
        else if (!exerciseData.basicData.points) error = "noTypedPoints";
        else if (!exerciseData.exerType) error = "noSelectedExerType";

        if (error) setDisableSubmitBtn(error);
        else setDisableSubmitBtn(false);
    }, [exerciseData]);

    const handleCheckValidation = (e) => {
        e.preventDefault();
        setDisableAction(true);
        const error = [];
        if (parseInt(exerciseData.basicData.time) < 1 || parseInt(exerciseData.basicData.time) > 30) {
            error.push({ code: "invalidEstimatedTime" });
            document.getElementById("estimatedTime").classList.add("is-invalid");
        }
        if (parseInt(exerciseData.basicData.points) < 1 || parseInt(exerciseData.basicData.points) > 50) {
            error.push({ code: "invalidPoints" });
            document.getElementById("points").classList.add("is-invalid");
        }

        const textEditorRegExr = /(&nbsp;)*(<\/*\w+>)+/; //remove all html tags and spaces

        exerciseData.versions.forEach((v, i) => {
            if (!v.taskText || !v.taskText.replace(textEditorRegExr, "")) {
                error.push({ code: "invalidTaskText", name: `${t("version")} ${i + 1}` });
                //$(`#taskText${i} .sun-editor`).addClass("is-invalid");
                /* document.querySelector(`#taskText${i}`).scrollIntoView({
                    behavior: "smooth",
                    alignToTop: true,
                }); */
            }

            if (exerciseData.exerType === "exerCheckRadio") {
                if (!v.config || (v.config && !v.config.value)) error.push({ code: "noSelectedConfig", name: `${t("version")} ${i + 1}` });

                if (!v.subPoints) error.push({ code: "noSelectedSubPoints", name: `${t("version")} ${i + 1}` });
                else if (v.subPoints === "one") {
                    let correctAnswers = false;
                    if (v.answers.length > 25) error.push({ code: "tooManyAnswers" });
                    else {
                        v.answers.forEach((a) => {
                            if (a.correct) correctAnswers = true;
                            if (!a.content || !a.content.replace(textEditorRegExr, ""))
                                error.push({ code: "noAnswerText", name: `${t("version")} ${i + 1}, ${t("answer")} ${String.fromCharCode(a.charCode)}` });
                        });
                        if (!correctAnswers) error.push({ code: "noAnswerIsChecked", name: `${t("version")} ${i + 1}` });
                    }
                } else if (v.subPoints === "many") {
                    if (!v.subExercises || (v.subExercises && v.subExercises.length === 0)) error.push({ code: "noSubExercises", name: `${t("version")} ${i + 1}` });
                    else {
                        const correctAnswersArray = [];
                        v.subExercises.forEach((s, j) => {
                            let correctAnswers = false;
                            if (!s.taskText || !s.taskText.replace(textEditorRegExr, "")) error.push({ code: "invalidSubTaskText", name: `${t("version")} ${i + 1}, ${t("subExercise")} ${j + 1}` });
                            if (v.answers.length > 25) error.push({ code: "tooManyAnswers" });
                            else {
                                s.answers.forEach((a) => {
                                    if (a.correct) correctAnswers = true;
                                    if (!a.content || !a.content.replace(textEditorRegExr, ""))
                                        error.push({ code: "noAnswerText", name: `${t("version")} ${i + 1}, ${t("subExercise")} ${j + 1}, ${t("answer")} ${String.fromCharCode(a.charCode)}` });
                                });
                                correctAnswersArray.push(correctAnswers);
                            }
                        });
                        correctAnswersArray.forEach((e, j) => {
                            if (!e) error.push({ code: "noAnswerIsChecked", name: `${t("version")} ${i + 1}, ${t("subExercise")} ${j + 1}` });
                        });
                    }
                }
            } else if (exerciseData.exerType === "exerTrueFalse") {
                if (v.answers.length > 25) error.push({ code: "tooManyAnswers" });
                else
                    v.answers.forEach((a, j) => {
                        if (!a.content || !a.content.replace(textEditorRegExr, "")) error.push({ code: "noAnswerText", name: `${t("version")} ${i + 1}, ${t("answer")} ${j + 1}` });
                    });
            } else if (exerciseData.exerType === "exerWriting") {
                if (parseInt(v.numberOfLines) < 1 || parseInt(v.numberOfLines) > 50) error.push({ code: "invalidNumberOfLines" });
            } else if (exerciseData.exerType === "exerConnection") {
                if (v.leftCol.length < 2) error.push({ code: "invalidLeftColLength" });
                else {
                    v.leftCol.forEach((l) => {
                        if (!l.content || !l.content.replace(textEditorRegExr, ""))
                            error.push({
                                code: "noAnswerText",
                                name: `${t("version")} ${i + 1}, ${t("createExercise.exerciseContent.connection.leftCol")}, ${t("subPoint")} ${String.fromCharCode(l.charCode)}`,
                            });
                    });
                }
                if (v.rightCol.length < 2) error.push({ code: "invalidRigthColLength" });
                else {
                    let noConnection = true;
                    v.rightCol.forEach((r, i) => {
                        if (!r.content || !r.content.replace(textEditorRegExr, ""))
                            error.push({ code: "noAnswerText", name: `${t("version")} ${i + 1}, ${t("createExercise.exerciseContent.connection.rightCol")}, ${t("subPoint")} ${i + 1}.` });
                        if (r.answer) noConnection = false;
                    });
                    if (noConnection) error.push({ code: "noConnectionRightCol", name: `${t("version")} ${i + 1}` });
                }
            } else if (exerciseData.exerType === "exerFillIn") {
                //console.log(v.fillInTxt, v.fillInTxt.length);
                if (!v.fillInTxt || !v.fillInTxt.replace(textEditorRegExr, "")) error.push({ code: "invalidExerciseContent", name: `${t("version")} ${i + 1}` });
            }
        });

        if (error.length > 0) {
            let alerts = [];
            error.forEach((e) => {
                alerts.push({ variant: "danger", message: `${t(`error.${e.code}`)}${e.name ? ` (${e.name})` : ""}` });
            });
            setAlerts(alerts);
            setDisableAction(false);
        } else {
            setDisableAction(true);
            setAlerts([{ variant: "info", message: t(`info.${currentView.page === "editExercise" ? "editingExercise" : "creatingExercise"}`) }]);
            if (currentView.page === "editExercise") setFetchEditExercise(true);
            else setFetchAddExercise(true);
        }
    };

    useEffect(() => {
        const addExercise = async () => {
            const data = exerciseData;
            data.createDate = new Date();
            //console.log(data);
            const res = await postMethod("/tasks/store", data, localStorage.getItem("token"));
            //console.log(res);
            if (res.status !== "success") {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else {
                    setDisableAction(false);
                    setAlerts([{ variant: "danger", message: res.msg }]);
                }
            } else {
                setAlerts([{ variant: "success", message: t(`success.exerciseCreated`), fading: 2000 }]);

                setTimeout(() => {
                    setCurrentView({ nav: "exercisesList", page: "exercisesList" });
                }, 200);
            }
        };

        if (fetchAddExercise) addExercise();
        return () => {
            setFetchAddExercise(false);
        };
    }, [fetchAddExercise]);

    useEffect(() => {
        localStorage.setItem("creatingProcess", true);
        window.addEventListener("scroll", onScroll);
        if (currentView.page === "editExercise" && currentView.params.exer) setExerciseData(currentView.params.exer.taskData);
        else setLoading(false);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        //console.log(exerciseData);
        if (loading && exerciseData.basicData.subject) {
            setLoading(false);
        }
    }, [exerciseData]);

    useEffect(() => {
        const editExercise = async () => {
            const data = exerciseData;
            //console.log(data);
            data.modifyDate = new Date();
            const res = await postMethod(`/tasks/update/${currentView.params.exer._id}`, data, localStorage.getItem("token"));
            //console.log(res);
            if (res.status === "success") {
                setAlerts([{ variant: "success", message: t("success.exerciseEdited"), fading: 2000 }]);
                setCurrentView({ nav: "exercisesList", page: "exercisesList" });
            } else {
                setAlerts([{ variant: "danger", message: res.msg }]);
                setDisableAction(false);
            }
        };

        if (fetchEditExercise) editExercise();

        return () => {
            setFetchEditExercise(null);
        };
    }, [fetchEditExercise]);

    const handleCancelExerciseCreation = () => {
        setAlerts([{ variant: "info", message: t("info.cancelingExerciseCreation") }]);
        setTimeout(() => {
            setAlerts([{ variant: "success", message: t(`success.${currentView.page === "createExercise" ? "exerciseCanceled" : "exerciseEditCanceled"}`), fading: 3000 }]);
            setCurrentView({ nav: "exercisesList", page: "exercisesList" });
        }, 200);
    };

    const onScroll = () => {
        if (document.getElementById("exerciseNavbar")) {
            const navbar = document.getElementById("exerciseNavbar");
            const posY = navbar.offsetTop;
            if (window.scrollY >= posY) setPos("top");
            else setPos(null);
        }
    };

    return (
        <Fragment>
            <Form onSubmit={(e) => handleCheckValidation(e)}>
                <ExerciseNavbar
                    disableAction={disableAction}
                    setDisableAction={(v) => setDisableAction(v)}
                    setPreviewModal={(v) => setPreviewModal(v)}
                    exerType={exerciseData.exerType}
                    disableSubmitBtn={disableSubmitBtn}
                    pos={pos}
                    setShowCancelModal={setShowCancelModal}
                />
                {loading ? (
                    <Container>
                        <Row>
                            <Col>{t("loading")}</Col>
                        </Row>
                    </Container>
                ) : (
                    <Fragment>
                        <Container id="exerciseCreator">
                            <BasicData setExerciseData={(v) => setExerciseData({ ...exerciseData, basicData: v })} exerciseData={exerciseData.basicData} />
                            <ChooseExerciseType
                                onChoose={(v) => {
                                    setKey(0);
                                    setExerciseData({ ...exerciseData, exerType: v });
                                }}
                                radioValue={exerciseData.exerType}
                                setVersions={(v) => setExerciseData({ ...exerciseData, versions: v })}
                            />

                            <ExerciseForm
                                exerType={exerciseData.exerType}
                                versions={exerciseData.versions}
                                currentKey={key}
                                setKey={(k) => setKey(k)}
                                changeValue={changeValue}
                                setVersions={(v) => setExerciseData({ ...exerciseData, versions: v })}
                                setDisableAction={(v) => setDisableAction(v)}
                                exerciseData={exerciseData}
                                onChangeRecording={(v) => {
                                    //console.log(v);
                                    setExerciseData({ ...exerciseData, recording: v });
                                }}
                            />
                        </Container>

                        {previewModal ? (
                            <PreviewExerciseModal
                                show={previewModal}
                                onClose={() => setPreviewModal(false)}
                                currentKey={key}
                                setKey={(k) => setKey(k)}
                                versions={exerciseData.versions}
                                exerType={exerciseData.exerType}
                                basicData={exerciseData.basicData}
                                recording={exerciseData.recording}
                            />
                        ) : null}
                    </Fragment>
                )}
            </Form>

            {showCancelModal ? (
                <ConfirmModal
                    show={showCancelModal}
                    title={t("cancelingCreatingExercise")}
                    text={t("cancelingCreatingExerciseTxt")}
                    onClose={() => setShowCancelModal(false)}
                    onProceed={handleCancelExerciseCreation}
                />
            ) : null}
        </Fragment>
    );
};

export default AddEditExercise;
