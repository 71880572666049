import React, { Fragment, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import GenerateTestPrint from "../tests/GenerateTestPrint";
import { BasicContext } from "../../App";

const DownloadTestModal = (props) => {
    const [print, setPrint] = useState(false);
    const { t } = useTranslation();
    const [list, setList] = useState(null);
    const { previewData, onCloseModal } = props;
    const { setAlerts } = useContext(BasicContext);
    const [printAnswers, setPrintAnswers] = useState(false);
    const [groupNumber, setGroupNumber] = useState(null);

    useEffect(() => {
        setAlerts([{ variant: "info", message: t("info.generatingPdf") }]);

        setGroupNumber(0);
        setTimeout(() => {
            setList(previewData.exerciseList);
            setPrint(true);
        }, 500);
    }, [previewData]);

    useEffect(() => {
        if (!print && groupNumber !== null) {
            //console.log("after print");
            //console.log(groupNumber);
            if (!printAnswers) {
                setPrintAnswers(true);
                setGroupNumber(groupNumber);
            }
            //print key answers for the same group test
            else {
                setPrintAnswers(false);
                setGroupNumber(groupNumber + 1);
            }
        }
    }, [print]);

    useEffect(() => {
        //console.log("groupNumber:", groupNumber);
        if (groupNumber < previewData.numberOfGroups) setPrint(true);
        else {
            //console.log("no more printinmg");
            setAlerts([{ variant: "success", message: t("success.finishedPrinting"), fading: 3000 }]);
            setList(null);
        }
    }, [groupNumber, printAnswers]);

    //console.log(print);

    return (
        <Fragment>
            {list && print && (
                <GenerateTestPrint
                    print={print}
                    setLoadingPrint={() => {}}
                    previewData={previewData}
                    exerciseList={list}
                    onCloseModal={onCloseModal}
                    setPrint={setPrint}
                    groupNumber={groupNumber}
                    printAnswers={printAnswers}
                />
            )}
        </Fragment>
    );
};

export default DownloadTestModal;
