import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TestContext } from "./AddEditTest";

const BasicData = () => {
    const { t } = useTranslation();
    const { setKey, previewData, setPreviewData } = useContext(TestContext);
    //console.log(previewData);
    return (
        <Fragment>
            <Row>
                <Col>
                    <h1 className="h4 py-4">{t("createTest.basicData.title")}</h1>
                    <Form.Group>
                        <Form.Label>
                            {t("createTest.basicData.testTitle")} <span className="req">*</span>
                        </Form.Label>
                        <Form.Control type="text" placeholder={t("typeName")} value={previewData.testTitle} onChange={(e) => setPreviewData({ ...previewData, testTitle: e.target.value })} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h1 className="h4 py-4">{t("createTest.printData.title")}</h1>
                </Col>
                <Col xs={12}>
                    <Form.Group>
                        {previewData.printData.map((e, i) => (
                            <Fragment key={i}>
                                <Form.Check
                                    inline
                                    className="mb-3"
                                    type="checkbox"
                                    id={`printoutDataCheckbox${i}`}
                                    label={t(`createTest.printData.${e.name}`)}
                                    checked={e.value}
                                    onChange={() => {
                                        const list = [...previewData.printData];
                                        list[i].value = !list[i].value;
                                        setPreviewData({ ...previewData, printData: list });
                                    }}
                                />
                            </Fragment>
                        ))}
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h1 className="h4 py-4">{t("createTest.groups.title")}</h1>
                </Col>
                <Col xs={12}>
                    <ul>
                        {previewData.groups.map((e, i) => (
                            <li key={i} className="my-2">
                                <span className="text-large">
                                    {t("createTest.groups.group")} {String.fromCharCode(e.charCode)}
                                </span>
                                <Button
                                    variant="link"
                                    className="d-inline-block ml-4 align-baseline py-0"
                                    disabled={i === 0}
                                    onClick={() => {
                                        const list = [...previewData.groups];
                                        list.splice(i, 1);
                                        let charCode = 65;
                                        list.forEach((e) => {
                                            e.charCode = charCode;
                                            charCode++;
                                        });
                                        setKey(list.length - 1);
                                        setPreviewData({ ...previewData, groups: list });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </li>
                        ))}
                    </ul>
                    <Button
                        className="my-2"
                        variant="outline-primary"
                        disabled={previewData.groups.length > 25}
                        onClick={() => {
                            const list = [...previewData.groups];
                            list.push({ charCode: list[list.length - 1].charCode + 1 });
                            setPreviewData({ ...previewData, groups: list });
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        {t("createTest.groups.addNewGroup")}
                    </Button>
                </Col>
            </Row>
        </Fragment>
    );
};

export default BasicData;
