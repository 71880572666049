import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RecordingQRCode from "../../RecordingQRCode";

const FillingGapsPreview = (props) => {
    const { t } = useTranslation();
    const { versions, currentKey, showAnswers, recording } = props;

    return (
        <Fragment>
            {!versions ? (
                t("loading")
            ) : !versions[currentKey] ? (
                <Alert variant="warning" className="alert-small">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {t("warning.notEnoughVersions")}
                </Alert>
            ) : (
                <Fragment>
                    <Row className="test-view">
                        <Col xs={12} className="task-text mb-2">
                            {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                        </Col>
                        {versions[currentKey].optionalTxt && (
                            <Col xs={12} className="mb-2">
                                <div dangerouslySetInnerHTML={{ __html: versions[currentKey].optionalTxt }}></div>
                            </Col>
                        )}
                        {!showAnswers && (
                            <Col xs={12} className="mb-2">
                                {versions[currentKey].fillInTxt ? (
                                    <div dangerouslySetInnerHTML={{ __html: versions[currentKey].fillInTxt }}></div>
                                ) : (
                                    <p className="no-data">{t("error.noExerciseText")}</p>
                                )}
                            </Col>
                        )}
                        {showAnswers && (
                            <Fragment>
                                <RecordingQRCode recording={recording} />

                                <Col xs={12} className="mb-2">
                                    {versions[currentKey].fillInAnswer ? (
                                        <div dangerouslySetInnerHTML={{ __html: versions[currentKey].fillInAnswer }}></div>
                                    ) : (
                                        <p className="no-data">{t("error.noAnswerText")}</p>
                                    )}
                                </Col>
                            </Fragment>
                        )}
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default FillingGapsPreview;
