import i18next from "i18next";
import React, { Fragment, useContext, useEffect } from "react";
import { Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";

const NavbarMain = () => {
    const { t } = useTranslation();
    const { langs } = useContext(BasicContext);

    useEffect(() => {
        const browserLang = i18next.language;
        let langAvailable = false;
        langs.forEach((l) => {
            if (l.lang === browserLang) langAvailable = true;
        });
        if (!langAvailable) i18next.changeLanguage("en");
    }, []);
    return (
        <Navbar id="mainNavbar">
            <Container>
                <Navbar.Brand>
                    <Image src="/images/klett-testomat-kursor-logo.svg" alt="klett testomat kursor logo" height="48" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basicNavbar" />
                <Navbar.Collapse id="basicNavbar">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <NavDropdown title={t("changeLang")} id="basic-nav-dropdown">
                            {langs.map((e, i) => (
                                <Fragment key={i}>
                                    {
                                        <NavDropdown.Item disabled={i18next.language === e.lang} onClick={(el) => i18next.changeLanguage(e.lang)}>
                                            {e.text}
                                        </NavDropdown.Item>
                                    }
                                </Fragment>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarMain;
