import React, { useContext, useState, useEffect, Fragment } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../App";
import $ from "jquery";
import i18next from "i18next";
import Email from "./forms/Email";
import ResetPasswordModal from "./modals/ResetPasswordModal";
import postMethod from "../requests/posts";
import Password from "./forms/Password";

const LoginForm = () => {
    const { t } = useTranslation();
    const [login, setLogin] = useState({ username: "", password: "" });
    const [inputType, setInputType] = useState("password");
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [loginFetch, setLoginFetch] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [blockForm, setBlockForm] = useState(false);

    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) return t("error.invalidEmail");
        else return "";
    };

    const checkValidation = (e) => {
        e.preventDefault();
        const errors = [];

        if (!login.username.replace(/\s+/, "")) {
            $("#loginFormEmail").addClass("is-invalid");
            errors.push(t("error.emptyEmail"));
        } else if (validateEmail(login.username)) {
            $("#loginFormEmail").addClass("is-invalid");
            errors.push(t("error.invalidEmail"));
        }

        if (login.password.length === 0) {
            $("#loginFormPassword").addClass("is-invalid");
            errors.push(t("error.emptyPassword"));
        }

        if (errors.length > 0) {
            const list = [];
            errors.forEach((e) => {
                list.push({ variant: "danger", message: e });
            });
            setAlerts(list);
        } else {
            setBlockForm(true);
            setLoginFetch(true);
        }
    };

    useEffect(() => {
        const authorize = async () => {
            const sendData = {
                username: login.username,
                password: login.password,
                client: process.env.REACT_APP_CLIENT,
                language: i18next.language,
            };
            const res = await postMethod("/authorization", sendData, "");
            //console.log(res);
            if (res.status !== "success") {
                setAlerts([{ variant: "danger", message: res.msg }]);
                setBlockForm(false);
            } else {
                const fetchedData = res.data;
                if (fetchedData.token) {
                    localStorage.setItem("token", fetchedData.token);
                    localStorage.setItem("userData", JSON.stringify(fetchedData.data));
                    setIsLogged(true);
                    setAlerts([{ variant: "success", message: t("success.loggedIn"), fading: 2000 }]);
                }
            }
        };

        if (loginFetch) {
            authorize();
            setAlerts([{ variant: "info", message: t("info.logging") }]);
        }

        return () => {
            setLoginFetch(false);
        };
    }, [loginFetch]);

    return (
        <Fragment>
            <Card className="border border-0 put-shadow" id="loginForm">
                <Card.Body>
                    <p className="card-title text-center h2 mb-4 text-uppercase">{t("loginForm.title")}</p>

                    <p className="text-center mb-1">{t("loginForm.dontHaveAccountTxt")}</p>
                    <p className="text-center">
                        <a href="https://klett.pl/rejestracja" target="_blank" rel="noopener noreferrer">
                            {t("register")}
                        </a>
                    </p>
                    <Form className="border border-end-0 border-start-0 py-4 my-4" onSubmit={checkValidation}>
                        <Email
                            controlID="loginFormEmail"
                            onChange={(e) => setLogin({ ...login, username: e.target.value })}
                            onClick={() => setInputType("password")}
                            blockForm={blockForm}
                            value={login.username}
                        />
                        <Password
                            value={login.password}
                            onChange={(e) => setLogin({ ...login, password: e.target.value })}
                            autoComplete={"current-password"}
                            disabled={blockForm}
                            controlID={"loginFormPassword"}
                            btnID="showPass"
                            label={t("password")}
                            onBtnClick={() => setInputType(inputType === "password" ? "text" : "password")}
                            inputType={inputType}
                        />

                        <Form.Group className="mb-3" controlId="loginFormBtn">
                            <Button variant="primary" className="w-100 mt-2" type="submit" disabled={blockForm}>
                                {t("loginForm.loginBtn")}
                            </Button>
                        </Form.Group>
                    </Form>

                    <Button variant="link" className="w-100" onClick={() => setResetPasswordModal(true)}>
                        {t("loginForm.forgotPass")}
                    </Button>
                </Card.Body>
            </Card>

            {resetPasswordModal ? <ResetPasswordModal show={resetPasswordModal} onClose={() => setResetPasswordModal(false)} /> : null}
        </Fragment>
    );
};

export default LoginForm;
