import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "bootstrap";
import React, { Fragment, useContext } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TaskText from "../../../../components/forms/TaskText";
import TextEditor from "../../../../components/forms/TextEditor";
import TextToTheTask from "../../../../components/forms/TextToTheTask";
import { ExerciseContext } from "../ExerciseForm";

const TrueFalse = () => {
    const { t } = useTranslation();
    const { versions, currentKey, changeValue } = useContext(ExerciseContext);

    return (
        <Fragment>
            {!versions[currentKey].answers ? (
                t("loading")
            ) : (
                <Fragment>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <TaskText value={versions[currentKey].taskText} onChange={(v) => changeValue("taskText", v)} />
                        </Col>
                        <TextToTheTask value={versions[currentKey].optionalTxt} changeValue={(v) => changeValue("optionalTxt", v)} />
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Form.Label>{t("answers")}</Form.Label>
                            <Table id="trueFalseAnswersTable" className="create-exercise-table-answers">
                                <tbody>
                                    {versions[currentKey].answers.map((e, i) => (
                                        <tr key={i}>
                                            <td className="align-middle">
                                                <Form.Group controlId={`true-false-radio-answer-${i}`}>
                                                    <Form.Check
                                                        type="radio"
                                                        inline
                                                        label={t("createExercise.exerciseContent.trueFalse.true")}
                                                        checked={e.correct === true}
                                                        onChange={() => {
                                                            const answersList = [...versions[currentKey].answers];
                                                            answersList[i].correct = true;
                                                            changeValue("answers", answersList);
                                                        }}
                                                        className="top-label"
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        inline
                                                        label={t("createExercise.exerciseContent.trueFalse.false")}
                                                        checked={e.correct === false}
                                                        onChange={() => {
                                                            const answersList = [...versions[currentKey].answers];
                                                            answersList[i].correct = false;
                                                            changeValue("answers", answersList);
                                                        }}
                                                        className="top-label"
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <TextEditor
                                                    value={e.content}
                                                    changeValue={(v) => {
                                                        const list = [...versions[currentKey].answers];
                                                        list[i].content = v;
                                                        changeValue("answers", list);
                                                    }}
                                                    autoFocus={e.autoFocus}
                                                />
                                            </td>
                                            <td className="text-center align-middle">
                                                <Button
                                                    variant="outline-secondary"
                                                    className={i !== 0 ? "" : "d-none"}
                                                    onClick={() => {
                                                        const list = [...versions[currentKey].answers];
                                                        list.splice(i, 1);
                                                        changeValue("answers", list);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12}>
                            {versions[currentKey].answers.length >= 25 ? (
                                <OverlayTrigger overlay={<Tooltip id="add-next-answer-tooltip-disabled">{t("createExercise.exerciseContent.checkRadio.addAnswersDisabled")}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button disabled className="mb-3" variant="outline-primary" style={{ pointerEvents: "none" }}>
                                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                                            {t("addNextAnswer")}
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <Button
                                    className="mb-3"
                                    variant="outline-primary"
                                    onClick={() => {
                                        const list = [...versions[currentKey].answers];
                                        list.push({ content: "", correct: true, autoFocus: true });
                                        changeValue("answers", list);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    {t("addNextAnswer")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default TrueFalse;
