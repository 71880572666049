import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createContext, Fragment, useState, useEffect, useContext } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import { logoutClearCookies } from "../../../components/commonData";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import CreatingTestModal from "../../../components/modals/CreatingTestModal";
import TestNavbar from "../../../components/navbars/TestNavbar";
import { getMethod } from "../../../requests/gets";
import postMethod from "../../../requests/posts";
import { LoggedContext } from "../../../structure/LoggedViews";
import ExercisesListMain from "../../exercises/ExercisesListMain";
import BasicData from "./BasicData";
import TestPreview from "./TestPreview";
import TestSummary from "./TestSummary";

export const TestContext = createContext();

const AddEditTest = () => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(1);
    const [cancelModal, setCancelModal] = useState(false);
    const [saveDraftModal, setSaveDraftModal] = useState(false);
    const [disableNavbarBtn, setDisableNavbarBtn] = useState(false);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [finishModal, setFinishModal] = useState(false);
    const [key, setKey] = useState(0);
    const [exerciseList, setExerciseList] = useState([]);
    const [fetchSaveTest, setFetchSaveTest] = useState(false);
    const { currentView, setCurrentView } = useContext(LoggedContext);
    const [loading, setLoading] = useState(true);
    const [showAnswers, setShowAnswers] = useState(false);
    const [previewData, setPreviewData] = useState({
        testTitle: "",
        basicData: {
            totalPoints: 0,
            averageLvl: 0,
            totalTime: 0,
            numberOfExercises: 0,
        },
        detailedData: {
            subject: [],
            eduStage: [],
            classes: [],
            series: [],
            volumes: [],
            chapters: [],
            categories: [],
            skills: [],
            dyslexic: [],
        },
        printData: [
            {
                name: "points",
                value: false,
            },
            {
                name: "score",
                value: false,
            },
            {
                name: "date",
                value: false,
            },
            {
                name: "logNumber",
                value: false,
            },
            {
                name: "time",
                value: false,
            },
        ],
        groups: [{ charCode: 65 }],
    });

    const checkIfExerCanBeAddedToTest = (exer, testData) => {
        //check if exercise can be added to the test
        //console.log(testData);
        //console.log(exer);
        const list = testData.exerciseList;
        let duplicatedExercise = false,
            notEnoughGroups = false;
        //console.log("testList: ", list);
        list.forEach((l) => {
            //console.log(l, exer);
            if (l._id === exer._id) duplicatedExercise = true;
        });
        if (testData.groups.length > exer.taskData.versions.length) notEnoughGroups = true;

        const warningAlerts = [];
        if (duplicatedExercise)
            warningAlerts.push({
                variant: "warning",
                message: (
                    <>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                        {t("warning.duplicatedExercise")}
                    </>
                ),
                fading: 5000,
            });
        if (notEnoughGroups)
            warningAlerts.push({
                variant: "warning",
                message: (
                    <>
                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                        {t("warning.notEnoughVersions")}
                    </>
                ),
                fading: 5000,
            });

        if (warningAlerts.length === 0) list.push(exer);
        else setAlerts(warningAlerts);

        setExerciseList(list);
        setTimeout(() => {
            setLoading(false);
        }, 200);
    };

    const checkTestDraft = () => {
        let testData = localStorage.getItem("testDraft");
        if (testData) {
            testData = JSON.parse(testData);
            setPreviewData(testData.previewData);
            return testData;
        } else {
            setAlerts([{ variant: "warning", message: t("noDraftTestInMemory"), fading: 3000 }]);
            setExerciseList([currentView.params.exer]);
            return false;
        }
    };

    useEffect(() => {
        localStorage.setItem("creatingProcess", true);
        //console.log(currentView.nav, currentView.page);
        if (currentView.nav === "createTest" && currentView.page === "editTest") {
            const fetchTestData = async (testID) => {
                const res = await getMethod(`/tests/${testID}`, localStorage.getItem("token"));
                //console.log(res);
                if (res.status === "success") {
                    const test = res.data;
                    setPreviewData(test);
                    setExerciseList(test.exerciseList);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    if (res.status === "errorLogout") {
                        setAlerts([
                            {
                                variant: "info",
                                message: (
                                    <>
                                        <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                        {res.msg}
                                    </>
                                ),
                                fading: 4000,
                            },
                        ]);
                        setIsLogged(false);
                        logoutClearCookies();
                    } else setAlerts([{ variant: "danger", message: res.msg }]);
                }
            };

            fetchTestData(currentView.params.id);
        } else if (currentView.params) {
            if (currentView.params.name === "addNewExer") {
                setExerciseList([currentView.params.exer]);
                setLoading(false);
            } else if (currentView.params.name === "testDraft") {
                checkTestDraft();
                setLoading(false);
            } else if (currentView.params.name === "addNewExerToDraftTest") {
                const testData = checkTestDraft();
                if (testData) checkIfExerCanBeAddedToTest(currentView.params.exer, testData);
            } else if (currentView.params.name === "editTest") {
                checkIfExerCanBeAddedToTest(currentView.params.exer, currentView.params.test);
            }
        } else setLoading(false);
    }, []);

    const handleAddToTest = (exercise) => {
        const list = [...exerciseList];
        list.push(exercise);
        setExerciseList(list);
    };

    const handleRemoveFromTest = (exercise) => {
        const list = [...exerciseList];
        list.forEach((e) => {
            if (e.id === exercise.id) {
                const getIndex = list.indexOf(e);
                list.splice(getIndex, 1);
            }
        });
        setExerciseList(list);
    };

    useEffect(() => {
        if (currentStep === 1) {
            if (!previewData.testTitle) setDisableNavbarBtn("noTestTitle");
            else setDisableNavbarBtn(false);
        } else if (currentStep === 2) {
            if (exerciseList.length < 1) setDisableNavbarBtn("tooFewExercises");
            else if (exerciseList.length > 20) setDisableNavbarBtn("tooManyExercises");
            else setDisableNavbarBtn(false);
        }
    }, [exerciseList, currentStep, previewData]);

    const handleCheckValidation = (e) => {
        e.preventDefault();
        if (currentStep === 1) {
            setCurrentStep(currentStep + 1);
            setTimeout(function() {
                window.scrollTo(0, 0);
            }, 2);
        } else if (currentStep === 2) {
            const groups = previewData.groups;
            //check if exercises on the list have enough versions depend on number of groups
            let exerNotEnoughGroups = []; //list of exercises that don't have equal of more versions than number of groups set in the test

            let addExer = false;
            exerciseList.forEach((e, i) => {
                if (e.taskData.versions.length < groups.length) addExer = i + 1;
            });
            if (addExer) exerNotEnoughGroups.push({ variant: "danger", message: `${t("error.notEnoughVersions")} (${t("exercise")} ${addExer})` });
            if (exerNotEnoughGroups.length > 0) setAlerts(exerNotEnoughGroups);
            else {
                setAlerts([]);
                setCurrentStep(currentStep + 1);
                setTimeout(function() {
                    window.scrollTo(0, 0);
                }, 2);
            }
        } else if (currentStep === 3) {
            setFinishModal(true);
        }
    };

    const handleSaveAsDraft = () => {
        setAlerts([{ variant: "info", message: t("info.savingAsDraft") }]);
        localStorage.setItem(
            "testDraft",
            JSON.stringify({
                previewData: previewData,
                exerciseList: exerciseList,
            })
        );
        setTimeout(() => {
            setAlerts([{ variant: "success", message: t("success.testSavedAsDraft"), fading: 3000 }]);
            setCurrentView({ nav: "tests", page: "testsList" });
            //console.log(JSON.parse(localStorage.getItem("testDraft")));
        }, 1000);
    };

    const handleCancelTestCreation = () => {
        setAlerts([{ variant: "info", message: t("info.cancelingTestCreation") }]);
        setTimeout(() => {
            setAlerts([{ variant: "success", message: t("success.testCanceled"), fading: 3000 }]);
            setCurrentView({ nav: "tests", page: "testsList" });
        }, 200);
    };

    const handleResponse = (res) => {
        if (res.status === "success") {
            return true;
        } else {
            if (res.status === "errorLogout") {
                setAlerts([
                    {
                        variant: "info",
                        message: (
                            <>
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                {res.msg}
                            </>
                        ),
                        fading: 4000,
                    },
                ]);
                setIsLogged(false);
                logoutClearCookies();
            } else setAlerts([{ variant: "danger", message: res.msg }]);
            return false;
        }
    };

    useEffect(() => {
        const getExerIDs = () => {
            const list = [];
            exerciseList.forEach((e) => {
                //console.log(e);
                list.push(e._id);
            });
            return list;
        };

        const sendData = {
            testTitle: previewData.testTitle,
            basicData: previewData.basicData,
            printData: previewData.printData,
            groups: previewData.groups,
            exerciseList: getExerIDs(),
            detailedData: previewData.detailedData,
        };

        const saveTest = async () => {
            sendData.createDate = new Date();
            const res = await postMethod("/tests/store", sendData, localStorage.getItem("token"));
            //console.log(res);

            if (handleResponse(res)) {
                setAlerts([{ variant: "success", message: t("success.createdTest"), fading: 3000 }]);
                setCurrentView({ nav: "tests", page: "testsList", params: "createdIDTest" });
            }
        };

        const editTest = async () => {
            sendData.modifyDate = new Date();
            const res = await postMethod(`/tests/update/${currentView.params.id}`, sendData, localStorage.getItem("token"));
            //console.log(res);
            if (handleResponse(res)) {
                setAlerts([{ variant: "success", message: t("success.testEdited"), fading: 3000 }]);
                setCurrentView({ nav: "tests", page: "testsList" });
            }
        };

        if (fetchSaveTest) {
            setAlerts([{ variant: "info", message: t(`info.${currentView.nav === "createTest" && currentView.page === "editTest" ? "editingTest" : "creatingTest"}`) }]);
            if (currentView.nav === "createTest" && currentView.page === "editTest") editTest();
            else saveTest();
        }
    }, [fetchSaveTest]);

    return (
        <Fragment>
            <TestContext.Provider
                value={{
                    previewData,
                    setPreviewData,
                    key,
                    setKey,
                    currentStep,
                    setCancelModal,
                    setSaveDraftModal,
                    disableNavbarBtn,
                    setCurrentStep,
                    exerciseList,
                    setExerciseList,
                    showAnswers,
                }}
            >
                <Form onSubmit={(e) => handleCheckValidation(e)}>
                    <TestNavbar onCheckValidation={handleCheckValidation} />
                    <Container>
                        {loading ? (
                            t("loading")
                        ) : (
                            <Row>
                                {currentStep === 3 ? (
                                    <Col xs={12} className="my-lg-3 mb-3">
                                        <TestSummary />
                                    </Col>
                                ) : (
                                    <Fragment>
                                        <Col xs={12} lg={6} className="my-lg-3 mb-3">
                                            {currentStep === 1 ? (
                                                <BasicData />
                                            ) : currentStep === 2 ? (
                                                <Fragment>
                                                    <Row>
                                                        <Col>
                                                            <h1 className="h4 py-4">{t("createTest.selectExercises.addExercises")}</h1>
                                                        </Col>
                                                    </Row>
                                                    <ExercisesListMain
                                                        list={exerciseList}
                                                        view="addExercise"
                                                        onAddToTest={handleAddToTest}
                                                        onRemoveFromTest={handleRemoveFromTest}
                                                        groups={previewData.groups}
                                                        onEditExerDuringTest={() => {
                                                            localStorage.setItem(
                                                                "testDraft",
                                                                JSON.stringify({
                                                                    previewData: previewData,
                                                                    exerciseList: exerciseList,
                                                                })
                                                            );
                                                            const testID = currentView.params.id;
                                                            setCurrentView({
                                                                nav: "exercises",
                                                                page: "editExercise",
                                                                params: { name: "editExerDuringTest", id: currentView.page === "editTest" ? testID : null },
                                                            });
                                                        }}
                                                    />
                                                </Fragment>
                                            ) : null}
                                        </Col>
                                        <Col xs={12} lg={6} className="border-start my-lg-3 mb-3">
                                            <TestPreview onRemoveFromTest={handleRemoveFromTest} onChangeSwitch={(e) => setShowAnswers(e.target.checked)} />
                                        </Col>
                                    </Fragment>
                                )}
                            </Row>
                        )}
                    </Container>
                </Form>
                {finishModal ? <CreatingTestModal show={finishModal} onClose={() => setFinishModal(false)} setFetchSaveTest={setFetchSaveTest} /> : null}
                {saveDraftModal ? (
                    <ConfirmModal
                        show={saveDraftModal}
                        onClose={() => setSaveDraftModal(false)}
                        onProceed={handleSaveAsDraft}
                        title={t("saveAsDraft")}
                        text={
                            <>
                                <p>{t("saveAsDraftTxt")}</p>
                                {localStorage.getItem("testDraft") && (
                                    <Alert variant="warning">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                        {t("warning.testDraftInStore")}
                                    </Alert>
                                )}
                            </>
                        }
                        cancelTxt={t("yesSaveAndClose")}
                        proceedTxt={t("noStayHere")}
                    />
                ) : null}
                {cancelModal ? (
                    <ConfirmModal
                        show={cancelModal}
                        title={t("cancelingCreatingTest")}
                        text={t("cancelingCreatingTestTxt")}
                        onClose={() => setCancelModal(false)}
                        onProceed={handleCancelTestCreation}
                    />
                ) : null}
            </TestContext.Provider>
        </Fragment>
    );
};

export default AddEditTest;
