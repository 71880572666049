import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import $ from "jquery";
import { useTranslation } from "react-i18next";

const Password = (props) => {
    const { t } = useTranslation();
    return (
        <Form.Group className="mb-3" controlId={props.controlID}>
            <Form.Label className="w-100">
                {props.label} <span className="text-danger">*</span>
            </Form.Label>

            <InputGroup>
                <Form.Control
                    value={props.value}
                    onChange={(e) => {
                        $(`#${props.controlID}`).removeClass("is-invalid");
                        props.onChange(e);
                    }}
                    placeholder={t("loginForm.passwordPlaceholder")}
                    type={props.inputType}
                    aria-describedby={props.btnID}
                    autoComplete={props.autoComplete}
                    disabled={props.disabled}
                />
                <Button variant="outline-secondary" id={props.btnID} onClick={props.onBtnClick}>
                    <FontAwesomeIcon icon={props.inputType === "password" ? faEye : faEyeSlash} />
                </Button>
            </InputGroup>
        </Form.Group>
    );
};

export default Password;
