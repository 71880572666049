import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Email = (props) => {
    const { t } = useTranslation();
    return (
        <Form.Group className="mb-3" controlId={props.controlID}>
            <Form.Label className="w-100">
                {t("email")} <span className="text-danger">*</span>
                <span className="tooltip-icon float-end">
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                            <Tooltip id={`${props.controlID}Tooltip`} {...props}>
                                {t("loginForm.loginTooltip")}
                            </Tooltip>
                        )}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </OverlayTrigger>
                </span>
            </Form.Label>
            <Form.Control
                value={props.value}
                onChange={props.onChange}
                onClick={props.onClick ? props.onClick : null}
                placeholder={t("loginForm.loginPlaceholder")}
                type="email"
                autoComplete="username"
                disabled={props.blockForm}
            />
        </Form.Group>
    );
};

export default Email;
