import { faCheck, faCircleArrowLeft, faCircleArrowRight, faFloppyDisk, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { TestContext } from "../../pages/tests/add-edit/AddEditTest";
import { LoggedContext } from "../../structure/LoggedViews";

const TestNavbar = (props) => {
    const [pos, setPos] = useState(null);
    const { setSaveDraftModal, setCancelModal, currentStep, setCurrentStep, disableNavbarBtn } = useContext(TestContext);
    const { onCheckValidation } = props;
    const { currentView } = useContext(LoggedContext);

    const onScroll = () => {
        const navbar = document.getElementById("testNavbar");
        const posY = navbar.offsetTop;
        if (window.scrollY >= posY) setPos("top");
        else setPos(null);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    return (
        <Fragment>
            <div id="testNavbar" className={pos ? "sticky-top" : ""} style={{ zIndex: 1021 }}>
                <Container>
                    <Row>
                        <Col className="me-auto" lg={5} md={4} xs={12}>
                            <p className="h4 creator-title my-2 my-lg-0 mt-lg-3 ">
                                {t(currentView.nav === "createTest" && currentView.page === "editTest" ? "editTest" : "createTest.title")}: {t("step")} <strong>{currentStep}</strong> {t("of")}{" "}
                                <strong>3</strong>
                            </p>
                        </Col>
                        <Col className="text-end" lg={7} md={8} xs={12}>
                            <Button variant="outline-primary" className="mx-lg-2 mx-1 d-inline-block my-2" onClick={() => setCancelModal(true)}>
                                <FontAwesomeIcon icon={faTimes} className="me-2" />
                                {t("cancel")}
                            </Button>
                            <Button variant="outline-primary" className="mx-lg-2 mx-1 d-inline-block my-2" onClick={() => setSaveDraftModal(true)}>
                                <FontAwesomeIcon icon={faFloppyDisk} className="me-2" />
                                {t("saveDraft")}
                            </Button>
                            <Button
                                variant="primary"
                                className="mx-lg-2 mx-1 d-inline-block my-2"
                                onClick={() => {
                                    setCurrentStep(currentStep - 1);
                                    setTimeout(function() {
                                        window.scrollTo(0, 0);
                                    }, 2);
                                }}
                                disabled={currentStep === 1}
                            >
                                <FontAwesomeIcon icon={faCircleArrowLeft} className="me-2" />
                                {t("goBack")}
                            </Button>
                            {disableNavbarBtn ? (
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="next-step-test-tooltip-disabled">{t(`error.${disableNavbarBtn}`)}</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button disabled className="mx-lg-2 mx-1 d-inline-block my-2" variant="primary" style={{ pointerEvents: "none" }}>
                                            <FontAwesomeIcon icon={currentStep === 3 ? faCheck : faCircleArrowRight} className="me-2" />
                                            {t(currentStep === 3 ? "saveAndFinish" : "goNext")}
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            ) : (
                                <Button variant="primary" className="mx-lg-2 mx-1 d-inline-block my-2" type={currentStep === 3 ? "submit" : "button"} onClick={onCheckValidation}>
                                    <FontAwesomeIcon icon={currentStep === 3 ? faCheck : faCircleArrowRight} className="me-2" />
                                    {t(currentStep === 3 ? "saveAndFinish" : "goNext")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};

export default TestNavbar;
