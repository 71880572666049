import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

const DependentDropdowns2 = (props) => {
    const { t } = useTranslation();
    const { dependentOption, dropdownID, onChangeValue, value1, value2, size, unfilteredArray, label } = props;
    const [changedOptions, setChangedOptions] = useState(null);
    const [options1, setOptions1] = useState(null);
    const [options2, setOptions2] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changedValue, setChangedValue] = useState(null);

    useEffect(() => {
        if (unfilteredArray !== changedOptions) {
            setLoading(true);
            if (unfilteredArray) {
                //change options in this dropdown if it was selected another option in dependant value
                const list = [];
                unfilteredArray.forEach((e, i) => {
                    list.push({
                        key: i,
                        value: e.short,
                        text: e.title,
                    });
                });
                setOptions1(list);
                setShowDropdown(true);

                setChangedOptions(unfilteredArray);
            } else {
                setShowDropdown(false);
                setOptions1(null);
                setOptions2(null);
            }
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    }, [unfilteredArray]);

    useEffect(() => {
        if (!value1) {
            setOptions2(null);
            onChangeValue(null, []);
            setChangedValue(null);
        } else if (value1 !== changedValue) {
            const children = unfilteredArray.filter((e) => e.short === value1)[0].children,
                list = [];
            children.forEach((e, i) => {
                list.push({
                    key: i,
                    value: e.short,
                    text: e.title,
                });
            });
            setOptions2(list);
            setChangedValue(value1);
        }
    }, [value1]);

    return (
        <Fragment>
            <Form.Group className="mb-3">
                <Form.Label>{label}</Form.Label>

                {loading ? (
                    <Form.Control disabled placeholder={t("loading")} />
                ) : showDropdown ? (
                    options1.length === 0 ? (
                        <p className="opacity-50">{t("noOptionsToChoose")}</p>
                    ) : (
                        <Fragment>
                            <Dropdown
                                id={`${dropdownID}Dropdown`}
                                placeholder={t("select")}
                                fluid
                                selection
                                options={options1}
                                clearable
                                className={size ? `form-control-${size}` : "form-control"}
                                noResultsMessage={t("noResultsFound")}
                                value={value1}
                                onChange={(e, data) => onChangeValue(data.value, [])}
                            />
                            {value1 && options2 && (
                                <Dropdown
                                    id={`${dropdownID}SubDropdown`}
                                    placeholder={t("selectMany")}
                                    fluid
                                    multiple
                                    selection
                                    search
                                    options={options2}
                                    className={size ? `form-control-${size}` : "form-control"}
                                    noResultsMessage={t("noResultsFound")}
                                    value={value2}
                                    onChange={(e, data) => onChangeValue(value1, data.value)}
                                />
                            )}
                        </Fragment>
                    )
                ) : (
                    <p className={size === "sm" ? "text-small" : size === "lg" ? "text-large" : ""}>
                        {t("firstChoose")}
                        {dependentOption.name}
                    </p>
                )}
            </Form.Group>
        </Fragment>
    );
};

export default DependentDropdowns2;
