import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const SortingDropdown = (props) => {
    const { t } = useTranslation();
    const options = [
        {
            key: 1,
            value: "newest",
            text: t("sorting.newest"),
        },
        {
            key: 2,
            value: "oldest",
            text: t("sorting.oldest"),
        },
        {
            key: 3,
            value: "A-Z",
            text: t("sorting.A-Z"),
            disabled: props.noAlphabeticalOrder,
        },
        {
            key: 4,
            value: "Z-A",
            text: t("sorting.Z-A"),
            disabled: props.noAlphabeticalOrder,
        },
    ];

    return (
        <Form.Group>
            <Form.Label>{t("sorting.sortBy")}</Form.Label>
            <Dropdown id="sortingDropdown" options={options} selection placeholder={t("select")} className="form-control" value={props.value} onChange={(e, data) => props.onChange(data.value)} />
        </Form.Group>
    );
};

export default SortingDropdown;
