import React from "react";
import { Modal, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FillingGapsHelperModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t("createExercise.exerciseContent.fillingGaps.modal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <p className="mb-3">{t("createExercise.exerciseContent.fillingGaps.modal.txt")}</p>
                    </Col>
                    <Col lg={6} xs={12} className="mb-3">
                        <Image fluid src="/images/fillingGapsExample1.jpg" alt="filling gaps example - exercise text" />
                    </Col>
                    <Col lg={6} xs={12}>
                        <Image fluid src="/images/fillingGapsExample2.jpg" alt="filling gaps example - exercise answer" />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default FillingGapsHelperModal;
