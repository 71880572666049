import React, { Fragment, useContext, useState } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../App";
import DependentDropdown from "../components/forms/DependentDropdown";
import Subject from "../components/forms/Subject";
import { LoggedContext } from "../structure/LoggedViews";

const FirstSettings = () => {
    const { t } = useTranslation();
    const [exerciseData, setExerciseData] = useState({ subject: null, eduStage: null });
    const [selectedArrays, setSelectedArrays] = useState({});
    const { permissions, initialTestFilters, initialExerFilters, setCurrentView, setBasicSettings } = useContext(LoggedContext);
    const { setAlerts } = useContext(BasicContext);

    const handleCheckValidation = (e) => {
        e.preventDefault();
        if (exerciseData.subject && exerciseData.eduStage) {
            const testFilters = [...initialTestFilters],
                exerFilters = [...initialExerFilters];
            testFilters.forEach((e) => {
                if (e.title === "subject") e.value = exerciseData.subject;
                if (e.title === "eduStage") e.value = exerciseData.eduStage;
            });
            exerFilters.forEach((e) => {
                if (e.title === "subject") e.value = exerciseData.subject;
                if (e.title === "eduStage") e.value = exerciseData.eduStage;
            });

            //console.log(exerFilters);
            let userData = localStorage.getItem("userData");
            userData = JSON.parse(userData);
            const settings = [{ subject: exerciseData.subject, eduStage: exerciseData.eduStage, userID: userData.uid }];
            localStorage.setItem("basicSettings", JSON.stringify(settings));
            setBasicSettings(settings);
            setCurrentView({ nav: "tests", page: "testsList", params: null });
        } else setAlerts([{ variant: "danger", message: t("error.subjectOrEduStageIsEmpty") }]);
    };

    return (
        <Fragment>
            <Form onSubmit={(e) => handleCheckValidation(e)}>
                <Container className="mt-5">
                    <Row>
                        <Col xs={12} lg={{ span: 8, offset: 2 }} className="light-color-bg p-5">
                            <Row>
                                <Col xs={12}>
                                    <p className="h3 text-center py-4">{t("basicPreferences.title")}</p>
                                    <p className="h5 text-center py-4">{t("basicPreferences.subtitle")}</p>
                                </Col>

                                <Col xs={12} lg={6}>
                                    <Subject
                                        value={exerciseData.subject}
                                        onChangeValue={(v) => {
                                            setExerciseData({ ...exerciseData, subject: v, eduStage: null });
                                            setSelectedArrays({ ...selectedArrays, eduStage: permissions.filter((e) => e.short === v)[0].children });
                                        }}
                                    />
                                </Col>
                                <Col xs={12} lg={6}>
                                    <DependentDropdown
                                        value={exerciseData.eduStage}
                                        onChangeValue={(v) => {
                                            setExerciseData({ ...exerciseData, eduStage: v });
                                        }}
                                        req
                                        options={selectedArrays.eduStage}
                                        dependentOption={{ value: exerciseData.subject, name: t("subject") }}
                                        dropdownID="educationStageDropdown"
                                        dropdownLabel={t("educationStage")}
                                    />
                                </Col>
                                <Col xs={12} className="text-center">
                                    <Button variant="primary" className="mt-3" size="lg" type="submit" disabled={exerciseData.subject && exerciseData.eduStage ? false : true}>
                                        {t("basicPreferences.submitAndContinue")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </Fragment>
    );
};

export default FirstSettings;
