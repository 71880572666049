import React, { Fragment, useEffect } from "react";
import { useSortable, defaultAnimateLayoutChanges } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CheckRadioPreview from "../exercises/previews/CheckRadioPreview";
import TrueFalsePreview from "../exercises/previews/TrueFalsePreview";
import WritingPreview from "../exercises/previews/WritingPreview";
import ConnectionPreview from "../exercises/previews/ConnectionPreview";
import FillingGapsPreview from "../exercises/previews/FillingGapsPreview";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faTrash } from "@fortawesome/free-solid-svg-icons";

const ExercisePreviewInTest = (props) => {
    const { t } = useTranslation();
    const { exercise, index, onRemoveFromTest, draggableMode, currentKey, showAnswers } = props;
    const animateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: exercise.id, animateLayoutChanges }); //id nie może być obiektem, musi być stringiem lub number'em

    const transformModify = () => {
        let trans = transform;
        trans = trans ? { x: trans.x, y: trans.y, scaleX: 1, scaleY: 1 } : null;
        return trans;
    };

    const style = { transform: CSS.Transform.toString(transformModify()), transition, zIndex: draggableMode ? 10000 : "auto" };

    const typesList = [
        {
            value: "exerCheckRadio",
            preview: <CheckRadioPreview versions={exercise.taskData.versions} currentKey={currentKey} showAnswers={showAnswers} recording={exercise.recording} />,
        },
        {
            value: "exerTrueFalse",
            preview: <TrueFalsePreview versions={exercise.taskData.versions} currentKey={currentKey} showAnswers={showAnswers} recording={exercise.recording} />,
        },
        {
            value: "exerWriting",
            preview: <WritingPreview versions={exercise.taskData.versions} currentKey={currentKey} showAnswers={showAnswers} recording={exercise.recording} />,
        },
        {
            value: "exerConnection",
            preview: <ConnectionPreview versions={exercise.taskData.versions} currentKey={currentKey} showAnswers={showAnswers} recording={exercise.recording} />,
        },
        {
            value: "exerFillIn",
            preview: <FillingGapsPreview versions={exercise.taskData.versions} currentKey={currentKey} showAnswers={showAnswers} recording={exercise.recording} />,
        },
    ];

    useEffect(() => {
        $(document).ready(function() {
            const width = $(`#exerciseInTestPreview`).width();
            const height = $("#exerciseInTestPreview").height();
            //console.log(width, height);
            $("#testPreviewGroups figure img").each(function() {
                $(this).css({ "max-width": `${width}px`, "max-height": `${height}px` });
            });

            $(".exer-drag")
                .on("mouseenter", function() {
                    let isDragging = false;
                    $(".exer-drag").each(function() {
                        if ($(this).hasClass("dragging")) isDragging = true;
                    });
                    if (isDragging) {
                        //only dragging element has styling and visible buttons
                        $(".exer-drag.dragging").css({ cursor: "default" });
                        $(".exer-drag.dragging").addClass("active");
                        $(".exer-drag.dragging")
                            .find(".drag-sidebar")
                            .fadeIn(200, "linear");
                    } else {
                        $(this).css({ cursor: "default" });
                        $(this).addClass("active");
                        $(this)
                            .find(".drag-sidebar")
                            .fadeIn(200, "linear");
                    }

                    ////console.log("enter");
                })
                .on("mouseleave", function() {
                    if (!$(this).hasClass("dragging"))
                        $(this)
                            .find(".drag-sidebar")
                            .fadeOut(100, "linear");
                    $(this).removeClass("active");
                    ////console.log("out");
                });

            $(".default-cursor").on("mouseenter", function() {
                $(this).css({ cursor: "default" });
            });
        });
    }, []);

    return (
        <Fragment>
            <div ref={setNodeRef} style={style} {...attributes} className={draggableMode ? "exer-drag" : "default-cursor"} id={`exerID${exercise.id}`}>
                {/* at this point everything inside this div is dropable*/}
                <div className="drag-sidebar" style={{ display: "none" }}>
                    <Button variant="outline-primary" {...listeners} className="btn-drag mx-1">
                        <FontAwesomeIcon icon={faGripVertical} />
                    </Button>

                    <Button variant="outline-primary" className="mx-1" onClick={() => onRemoveFromTest(exercise)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
                <Row className="my-3" id={`exerContent${index}`}>
                    <Col xs={6}>
                        <p className="mb-0">
                            {t("exercise")} {index}
                        </p>
                    </Col>
                    {!showAnswers && (
                        <Col xs={6} className="text-end">
                            <p className="mb-0">...../{exercise.taskData.basicData.points}p.</p>
                        </Col>
                    )}
                    <Col xs={12}>
                        {typesList.map((e, i) => (
                            <Fragment key={i}>
                                {e.value === exercise.taskData.exerType ? (
                                    <div id="exerciseInTestPreview">
                                        {showAnswers && e.value === "exerWriting" && !exercise.taskData.recording ? t("createExercise.exerciseTypes.writing") : e.preview}
                                    </div>
                                ) : null}
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

export default ExercisePreviewInTest;
