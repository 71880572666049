import i18next from "i18next";

export const difficultyLvlOptions = () => {
    return [
        {
            key: 1,
            value: 1,
            text: i18next.t("difficultyLvl.easy"),
        },
        {
            key: 2,
            value: 2,
            text: i18next.t("difficultyLvl.medium"),
        },
        {
            key: 3,
            value: 3,
            text: i18next.t("difficultyLvl.hard"),
        },
    ];
};

export const exerciseTypesOptions = () => {
    return [
        {
            key: 1,
            value: "exerCheckRadio",
            text: i18next.t("createExercise.exerciseTypes.checkRadio"),
        },
        {
            key: 2,
            value: "exerTrueFalse",
            text: i18next.t("createExercise.exerciseTypes.trueFalse"),
        },
        {
            key: 3,
            value: "exerWriting",
            text: i18next.t("createExercise.exerciseTypes.writing"),
        },
        {
            key: 4,
            value: "exerConnection",
            text: i18next.t("createExercise.exerciseTypes.connection"),
        },
        {
            key: 5,
            value: "exerFillIn",
            text: i18next.t("createExercise.exerciseTypes.fillIn"),
        },
    ];
};

export const classesFilterOptions = () => {
    const classesArray = [];
    for (let i = 1; i <= 8; i++) {
        classesArray.push({
            key: i,
            value: i,
            text: `${i18next.t("class")} ${i}`,
        });
    }
    return classesArray;
};

export const reduceDuplicated = (array) => {
    return array.reduce(function(a, b) {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
    }, []);
};

export const groupsOptions = () => {
    const array = [];
    for (let i = 0; i < 4; i++) {
        array.push({ key: i, value: i + 1, text: i + 1 });
    }
    return array;
};

export const logoutClearCookies = () => {
    localStorage.removeItem("testFilters");
    localStorage.removeItem("exerFilters");
    localStorage.removeItem("userData");
    localStorage.removeItem("token");
    return true;
};
