import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LogoutModal = (props) => {
    const { t } = useTranslation();
    return (
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("logoutModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("logoutModal.txt")}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onProceed}>
                    {t("logoutModal.proceedTxt")}
                </Button>
                <Button variant="outline-primary" onClick={props.onClose}>
                    {t("logoutModal.cancelTxt")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LogoutModal;
