import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Row, Image, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BasicTestDataPreview from "../../../components/tests/BasicTestDataPreview";
import ExercisePreviewInTest from "../../../components/tests/ExercisePreviewInTest";
import $ from "jquery";

const TestSummaryPdfPreview = (props) => {
    const { t } = useTranslation();
    const { loading, previewData, exerciseList, showAnswers } = props;
    const [currentKey, setCurrentKey] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [exercisesOnPages, setExercisesOnPages] = useState(null);
    const [exerciseNumber, setExerciseNumber] = useState(null);
    const a4width = 1115;

    const setPdfPreview = (pageInnerHeight) => {
        const pages = [],
            exerciseNumbers = [];
        let exerInPage = [],
            pageWithExerHeight = document.getElementById("basicTestDataPreview").offsetHeight,
            exerciseNumbersList = [];
        exerciseList.forEach((e, j) => {
            let exerH = $(`#exerContent${j + 1}`).outerHeight(true); // offsetHeight + margins
            //console.log("exerH:", exerH);
            if (pageWithExerHeight + exerH < pageInnerHeight) {
                exerInPage.push(e);
                exerciseNumbersList.push(j);
                pageWithExerHeight += exerH;
            } else {
                pages.push(exerInPage);
                exerciseNumbers.push(exerciseNumbersList);
                exerInPage = [e];
                exerciseNumbersList = [j];
                pageWithExerHeight = 0 + exerH;
            }
        });
        if (exerInPage.length > 0) {
            pages.push(exerInPage);
            exerciseNumbers.push(exerciseNumbersList);
        }

        $(".body-preview").css({ display: "none" }); // hide current displaying exercise list that is not set in pages
        setExerciseNumber(exerciseNumbers);
        setExercisesOnPages(pages); // exercise list that is set in pages
        //console.log(pages, exerciseNumbers);
    };

    const setPageHeightAndExercisesPerPage = () => {
        setCurrentPage(1);
        setExercisesOnPages(null);

        if ($(".body-preview").length) {
            $(".body-preview").css({ display: "block" }); //show to calculate height of exercises after every tab change

            const height = a4width * 1.4142,
                footerHeight = $(".pdf-viewer-footer").outerHeight(),
                padding = 96;
            $(".pdf-viewer").css({ width: `${a4width}px`, height: `${height}px` });
            $(".pdf-viewer-body").css({ height: `${height - footerHeight - padding * 2}px` });
            document.getElementById("testSummaryPagination").style.maxWidth = `${a4width}px`;

            setPdfPreview((height - padding * 2 - footerHeight).toFixed(0));
        }
    };

    useEffect(() => {
        if (!loading) setPageHeightAndExercisesPerPage();
    }, [loading, currentKey]);

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: document.getElementById("previewTestModalBody") ? 0 : document.getElementById("testSummaryGroups").offsetTop,
                left: 0,
                behavior: "smooth",
            });
        }, 100);
    };

    return (
        <Fragment>
            <Col xs={12} className="my-5">
                <div id={"testSummaryGroups"}>
                    {previewData.groups.map((group, i) => (
                        <Button
                            key={i}
                            onClick={() => {
                                setCurrentPage(1);
                                setCurrentKey(i);
                            }}
                            variant={i === currentKey ? "primary" : "outline-primary"}
                            className="test-summary-tab-btn"
                            size="lg"
                        >
                            {`${t("createTest.groups.group")} ${String.fromCharCode(group.charCode)}`}
                        </Button>
                    ))}
                    <div className="test-view">
                        {previewData.groups.map((group, i) => (
                            <Fragment key={i}>
                                {i === currentKey && (
                                    <div className="position-relative pdf-viewer" style={{ width: "100%" }}>
                                        <div className="pdf-viewer-body">
                                            {currentPage === 1 && <BasicTestDataPreview previewData={previewData} groupLetter={String.fromCharCode(group.charCode)} showAnswers={showAnswers} />}
                                            <div className="body-preview">
                                                {exerciseList.map((e, j) => (
                                                    <ExercisePreviewInTest exercise={e} index={j + 1} currentKey={i} key={j} showAnswers={showAnswers} />
                                                ))}
                                            </div>
                                            {exercisesOnPages ? (
                                                <div>
                                                    {exercisesOnPages.map((p, j) => (
                                                        <Fragment key={j}>
                                                            {currentPage - 1 === j &&
                                                                p.map((e, k) => (
                                                                    <ExercisePreviewInTest exercise={e} index={exerciseNumber[j][k] + 1} currentKey={i} key={k} showAnswers={showAnswers} />
                                                                ))}
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            ) : (
                                                t("loading")
                                            )}
                                        </div>
                                        <div className="pdf-viewer-footer">
                                            <Image src="/images/klett logo.png" className="main-logo" />
                                            <Image src="/images/klett-testomat-kursor-logo.svg" />
                                            <p className="d-inline float-end" style={{ paddingTop: "12px" }}>
                                                {t("page")} {currentPage} {t("from")} {exercisesOnPages ? exercisesOnPages.length : 1}
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                    {previewData.groups.map((group, i) => (
                        <Fragment key={i}>
                            {i === currentKey && (
                                <Row>
                                    <Col className="my-3">
                                        <div id="testSummaryPagination">
                                            {exercisesOnPages && (
                                                <Pagination className="align-items-center justify-content-center">
                                                    <Pagination.Prev
                                                        disabled={currentPage === 1}
                                                        onClick={() => {
                                                            setCurrentPage(currentPage - 1);
                                                            scrollToTop();
                                                        }}
                                                    />
                                                    {exercisesOnPages.map((e, i) => (
                                                        <Pagination.Item
                                                            key={i}
                                                            active={i + 1 === currentPage}
                                                            onClick={() => {
                                                                setCurrentPage(i + 1);
                                                                if (i + 1 !== currentPage)
                                                                    //is not active
                                                                    scrollToTop();
                                                            }}
                                                        >
                                                            {i + 1}
                                                        </Pagination.Item>
                                                    ))}
                                                    <Pagination.Next
                                                        disabled={currentPage === exercisesOnPages.length}
                                                        onClick={() => {
                                                            setCurrentPage(currentPage + 1);
                                                            scrollToTop();
                                                        }}
                                                    />
                                                </Pagination>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Fragment>
                    ))}
                </div>
            </Col>
        </Fragment>
    );
};

export default TestSummaryPdfPreview;
