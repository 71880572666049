import React, { Fragment, useContext, useState } from "react";
import TextEditor from "../../../../components/forms/TextEditor";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ExerciseContext } from "../ExerciseForm";
import TaskText from "../../../../components/forms/TaskText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import FillingGapsHelperModal from "../../../../components/modals/FillingGapsHelperModal";
import TextToTheTask from "../../../../components/forms/TextToTheTask";

const FillingGaps = () => {
    const { t } = useTranslation();
    const { versions, changeValue, currentKey } = useContext(ExerciseContext);
    const [showFillingGapsHelperModal, setShowFillingGapsHelperModal] = useState(false);

    return (
        <Fragment>
            {versions[currentKey] && (
                <Fragment>
                    {versions[currentKey].fillInTxt === undefined ? (
                        <Row>
                            <Col>{t("loading")}</Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs={12} className="mb-3">
                                <TaskText value={versions[currentKey].taskText} noBold onChange={(v) => changeValue("taskText", v)} />
                            </Col>
                            <TextToTheTask value={versions[currentKey].optionalTxt} changeValue={(v) => changeValue("optionalTxt", v)} />
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">
                                        {t("createExercise.exerciseContent.fillingGaps.taskText")} <span className="req">*</span>
                                        <Button onClick={() => setShowFillingGapsHelperModal(true)} className="mx-2 p-0" variant="link">
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </Button>
                                    </Form.Label>
                                    <p className="text-small mb-3">{t("createExercise.exerciseContent.fillingGaps.taskTextHelper")}</p>
                                    <TextEditor
                                        value={versions[currentKey].fillInTxt}
                                        changeValue={(v) => {
                                            changeValue("fillInTxt", v);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label className="mb-1">
                                        {t("answer")}
                                        <Button onClick={() => setShowFillingGapsHelperModal(true)} className="mx-2 p-0" variant="link">
                                            <FontAwesomeIcon icon={faQuestionCircle} />
                                        </Button>
                                    </Form.Label>
                                    <p className="text-small mb-3">{t("createExercise.exerciseContent.fillingGaps.answerHelper")}</p>
                                    <TextEditor
                                        value={versions[currentKey].fillInAnswer}
                                        changeValue={(v) => {
                                            changeValue("fillInAnswer", v);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}

            {showFillingGapsHelperModal && <FillingGapsHelperModal show={showFillingGapsHelperModal} onClose={() => setShowFillingGapsHelperModal(false)} />}
        </Fragment>
    );
};

export default FillingGaps;
