import { faBars, faEdit, faLanguage, faPlus, faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Image, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import { LoggedContext } from "../../structure/LoggedViews";
import ConfirmModal from "../modals/ConfirmModal";
import LogoutModal from "../modals/LogoutModal";
import i18next from "i18next";
import { logoutClearCookies } from "../commonData";

const NavbarMainLogged = () => {
    const { setCurrentView, currentView } = useContext(LoggedContext);
    const { setIsLogged, setAlerts } = useContext(BasicContext);
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showChangeViewModal, setShowChangeViewModal] = useState(false);

    const { langs } = useContext(BasicContext);

    const handleLogout = () => {
        logoutClearCookies();
        setIsLogged(false);
        setAlerts([{ variant: "success", message: t("success.logout"), fading: 2000 }]);
    };

    const smallerUserData = () => {
        let userData = localStorage.getItem("userData");
        userData = JSON.parse(userData);
        const userName = userData.profile.firstName;
        const userLastname = userData.profile.lastName;
        const user = document.getElementById("userName");
        if (userName.length + userLastname.length > 20 && window.innerWidth >= 992) {
            const br = document.createElement("br");
            user.classList.add("break-lines");
            user.innerHTML += userName;
            user.appendChild(br);
            user.innerHTML += userLastname;
        } else {
            user.innerHTML = `${userName} ${userLastname}`;
        }
    };

    useEffect(() => {
        //decrease user name and last name font size and change layout when too long
        smallerUserData();
    }, []);

    const changeView = (page) => {
        if (localStorage.getItem("creatingProcess")) {
            setShowChangeViewModal(page);
        } else {
            setCurrentView(page);
            setExpanded(false);
        }
    };

    return (
        <Fragment>
            <Navbar expand="lg" id="mainNavbar" expanded={expanded}>
                <Container>
                    <Navbar.Brand>
                        <Image src="/images/klett-testomat-kursor-logo.svg" alt="klett testomat kursor logo" height="48" className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basicNavbar" children={<FontAwesomeIcon icon={faBars} />} onClick={() => setExpanded(expanded ? false : "expanded")} />
                    <Navbar.Collapse id="basicNavbar">
                        <Nav className="me-auto"></Nav>
                        <Nav>
                            <Nav.Link className={currentView.nav === "tests" ? "active" : ""} onClick={() => changeView({ nav: "tests", page: "testsList", params: null })}>
                                {t("menu.testsList")}
                            </Nav.Link>
                            <NavDropdown title={t("menu.createTest")} id="basic-nav-dropdown" align={{ lg: "end" }} className={currentView.nav === "createTest" ? "active" : ""}>
                                <NavDropdown.Item onClick={() => changeView({ nav: "createTest", page: "createNewTest", params: null })}>
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    {t("menu.createNewTest")}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() => changeView({ nav: "createTest", page: "createNewTest", params: { name: "testDraft" } })}
                                    disabled={!localStorage.getItem("testDraft") ? true : false}
                                >
                                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                                    {t("menu.createTestFromDraft")}
                                </NavDropdown.Item>
                            </NavDropdown>
                            <div className="menu-divider-vertical d-none d-lg-block"></div>
                            <Nav.Link className={currentView.nav === "exercisesList" ? "active" : ""} onClick={() => changeView({ nav: "exercisesList", page: "exercisesList", params: null })}>
                                {t("menu.exercisesList")}
                            </Nav.Link>
                            <Nav.Link className={currentView.nav === "exercises" ? "active" : ""} onClick={() => changeView({ nav: "exercises", page: "createExercise", params: null })}>
                                {t("menu.createExercise")}
                            </Nav.Link>
                            <div className="menu-divider-vertical d-none d-lg-block"></div>
                            <div className="menu-divider-horizontal d-lg-none"></div>
                            <Nav.Link href={process.env.REACT_APP_CLIENT_LINK} target="_blank" className="d-lg-none">
                                <FontAwesomeIcon icon={faUser} className="me-2" />
                                {t("menu.myAccount", { client: process.env.REACT_APP_CLIENT_DOMAIN })}
                            </Nav.Link>
                            <NavDropdown
                                className="d-lg-none"
                                title={
                                    <>
                                        <FontAwesomeIcon icon={faLanguage} className="me-2" />
                                        {t("changeLang")}
                                    </>
                                }
                                id="basic-nav-dropdown"
                                align={{ lg: "end" }}
                            >
                                {langs.map((e, i) => (
                                    <Fragment key={i}>
                                        {
                                            <NavDropdown.Item disabled={i18next.language === e.lang} onClick={(el) => i18next.changeLanguage(e.lang)}>
                                                {e.text}
                                            </NavDropdown.Item>
                                        }
                                    </Fragment>
                                ))}
                            </NavDropdown>
                            <Nav.Link onClick={handleLogout} className="d-lg-none">
                                <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
                                {t("menu.logout")}
                            </Nav.Link>

                            <NavDropdown
                                title={
                                    <Fragment>
                                        <Image src="/images/logo64.png" alt="logo alt 64" height="54" id="navMenuDropdownUserImg" />
                                        <span id="userName"></span>
                                    </Fragment>
                                }
                                id="navMenuDropdownUser"
                                align={{ lg: "end" }}
                                className="d-none d-lg-inline-block"
                            >
                                <NavDropdown.Item href={process.env.REACT_APP_CLIENT_LINK} target="_blank">
                                    <FontAwesomeIcon icon={faUser} className="me-2" />
                                    {t("menu.myAccount", { client: process.env.REACT_APP_CLIENT_DOMAIN })}
                                </NavDropdown.Item>
                                <NavDropdown
                                    title={
                                        <>
                                            <FontAwesomeIcon icon={faLanguage} className="me-2" />
                                            {t("changeLang")}
                                        </>
                                    }
                                    id="basic-nav-dropdown"
                                >
                                    {langs.map((e, i) => (
                                        <Fragment key={i}>
                                            {
                                                <NavDropdown.Item disabled={i18next.language === e.lang} onClick={(el) => i18next.changeLanguage(e.lang)}>
                                                    {e.text}
                                                </NavDropdown.Item>
                                            }
                                        </Fragment>
                                    ))}
                                </NavDropdown>
                                <NavDropdown.Item onClick={() => setShowLogoutModal(true)}>
                                    <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
                                    {t("menu.logout")}
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {showLogoutModal ? <LogoutModal show={true} onProceed={handleLogout} onClose={() => setShowLogoutModal(false)} /> : null}
            {showChangeViewModal ? (
                <ConfirmModal
                    show={true}
                    onProceed={() => {
                        setCurrentView(showChangeViewModal);
                        setExpanded(false);
                        setShowChangeViewModal(false);
                        localStorage.removeItem("creatingProcess");
                    }}
                    onClose={() => setShowChangeViewModal(false)}
                    title={t("cancelingProcessModal.title")}
                    text={t("cancelingProcessModal.text")}
                />
            ) : null}
        </Fragment>
    );
};

export default NavbarMainLogged;
