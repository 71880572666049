import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext, Fragment } from "react";
import { BasicContext } from "../../App";
import { logoutClearCookies } from "../../components/commonData";
import ExerciseFilters from "../../components/exercises/ExerciseFilters";
import ExercisesList from "../../components/exercises/ExercisesList";
import postMethod from "../../requests/posts";
import { LoggedContext } from "../../structure/LoggedViews";

const ExercisesListMain = (props) => {
    const noe = 8; //number of elements
    const [exerciseList, setExerciseList] = useState(null);
    const [filteredList, setFilteredList] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [paginationList, setPaginationList] = useState(null);
    const [slicedList, setSlicedList] = useState(null);
    const [userID, setUserID] = useState(null);
    const [sortValue, setSortValue] = useState("newest");
    const [selectedFilters, setSelectedFilters] = useState(null);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const { initialExerFilters } = useContext(LoggedContext);
    const [fetchList, setFetchList] = useState(false);
    const { view, onAddToTest, onRemoveFromTest, groups, onEditExerDuringTest, list } = props;

    const sortList = (unsortedList) => {
        let list = [...unsortedList];
        if (sortValue === "newest") list.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));
        else if (sortValue === "oldest") list.sort((a, b) => new Date(a.dates.createdAt) - new Date(b.dates.createdAt));
        else list.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));
        return list;
    };

    useEffect(() => {
        if (filteredList) {
            setFilteredList(sortList(filteredList));
        }
    }, [sortValue]);

    const countSlicedExercises = (list) => {
        const nop = Math.ceil(list.length / noe);
        let startIndex = null,
            endIndex = null,
            j = 1;
        for (let i = 1; i <= nop; i++) {
            if (i === activePage) {
                startIndex = j - 1;
                if (j + (noe - 1) <= list.length) endIndex = j + (noe - 1);
                else endIndex = list.length;
            }
            j += noe;
        }
        return list.slice(startIndex, endIndex);
    };

    const setPagination = (list) => {
        const nop = Math.ceil(list.length / noe); //number of pages
        const items = [];
        for (let i = 1; i <= nop; i++) {
            items.push(i);
        }
        setPaginationList(items);
    };

    useEffect(() => {
        if (exerciseList) {
            setFilteredList(exerciseList);
            setPagination(exerciseList);
        }
    }, [exerciseList]);

    useEffect(() => {
        const getExercisesList = async (filters) => {
            const data = filters;
            //console.log(data);
            const res = await postMethod("/tasks/filtered", data, localStorage.getItem("token"));
            //console.log(res);
            if (res.status === "success") {
                const data = res.data;
                data.forEach((e) => {
                    //console.log(e._id, e.dates);
                    e.taskData.recording = e.taskData.recording ? e.taskData.recording : null;
                });

                data.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));

                let userData = localStorage.getItem("userData");
                userData = JSON.parse(userData);
                setUserID(userData.uid);
                setExerciseList(data);
            } else {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            }
            setFetchList(false);
        };

        if (fetchList)
            if (selectedFilters) {
                setSlicedList(null);

                const filtersForFetch = [];
                selectedFilters.forEach((e) => {
                    filtersForFetch.push({
                        title: e.title,
                        value: e.value ? [e.value] : [],
                    });
                });
                getExercisesList(filtersForFetch);
            }
    }, [fetchList]);

    useEffect(() => {
        if (selectedFilters) localStorage.setItem("exerFilters", JSON.stringify(selectedFilters));
    }, [selectedFilters]);

    useEffect(() => {
        if (filteredList) {
            setSlicedList(countSlicedExercises(filteredList));
            setPagination(filteredList);
        }
    }, [activePage, filteredList]);

    useEffect(() => {
        localStorage.removeItem("creatingProcess");

        let filters = localStorage.getItem("exerFilters");
        if (filters) {
            filters = JSON.parse(filters);
            setSelectedFilters(filters);
            setFetchList(true);
        } else {
            setSelectedFilters(initialExerFilters);
            setFetchList(true);
        }
    }, []);

    return (
        <Fragment>
            <ExerciseFilters
                view={view ? view : "list"}
                exercisesList={exerciseList}
                setFilteredList={(list) => setFilteredList(sortList(list))}
                setActivePage={setActivePage}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                onFilter={() => setFetchList(true)}
                disableFilterBtn={fetchList}
            />
            <ExercisesList
                list={list}
                view={view ? view : "list"}
                setExerciseList={setExerciseList}
                filteredList={filteredList}
                setFilteredList={setFilteredList}
                activePage={activePage}
                setActivePage={setActivePage}
                paginationList={paginationList}
                slicedList={slicedList}
                userID={userID}
                sortValue={sortValue}
                setSortValue={setSortValue}
                onAddToTest={onAddToTest}
                onRemoveFromTest={onRemoveFromTest}
                groups={groups}
                onEditExerDuringTest={onEditExerDuringTest}
            />
        </Fragment>
    );
};

export default ExercisesListMain;
