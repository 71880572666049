import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tab } from "bootstrap";
import React, { Fragment, createContext } from "react";
import { Alert, Button, Col, Row, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CheckRadio from "./contents/CheckRadio";
import Connection from "./contents/Connection";
import FillingGaps from "./contents/FillingGaps";
import TrueFalse from "./contents/TrueFalse";
import Writing from "./contents/Writing";
import Recording from "./../../../components/forms/Recording";

export const ExerciseContext = createContext();

const ExerciseForm = (props) => {
    const { exerType, currentKey, setKey, versions, changeValue, setVersions, setDisableAction, exerciseData, onChangeRecording } = props;
    const { t } = useTranslation();

    const handleAddVersion = () => {
        const list = [...versions];
        typesList.forEach((e) => {
            if (e.value === exerType) {
                list.push(e.initialData);
                list[list.length - 1].key = list.length - 1;
            }
        });
        setKey(list.length - 1); //activate newest versions' tab
        setVersions(list);
    };

    const handleDeleteVersion = (i) => {
        const list = [...versions];
        list.splice(i, 1);
        list.forEach((e, k) => {
            e.key = k; //renumerate versions
        });
        setKey(currentKey - 1 < 0 ? 0 : currentKey - 1); //activate tab on the left of the deleted version; if first is deleted, activate the newest first tab
        setVersions(list);
    };

    const typesList = [
        {
            value: "exerCheckRadio",
            content: <CheckRadio />,
            initialData: {
                answers: [
                    { charCode: 65, content: "", autoFocus: true, correct: false },
                    { charCode: 66, content: "", autoFocus: false, correct: false },
                ],
                subExercises: [
                    {
                        key: 0,
                        taskText: "",
                        answers: [
                            { charCode: 65, content: "", autoFocus: false, correct: false },
                            { charCode: 66, content: "", autoFocus: false, correct: false },
                        ],
                    },
                ],
                config: {
                    value: "col1",
                    size: 12,
                    txt: t("createExercise.exerciseContent.checkRadio.oneColumn"),
                },
                subPoints: null,
                taskText: "",
                key: 0,
                optionalTxt: "",
            },
        },
        {
            value: "exerTrueFalse",
            content: <TrueFalse />,
            initialData: {
                key: 0,
                taskText: "",
                answers: [
                    { content: "", correct: true, autoFocus: false },
                    { content: "", correct: true, autoFocus: false },
                ],
                optionalTxt: "",
            },
        },
        {
            value: "exerWriting",
            content: <Writing />,
            initialData: {
                key: 0,
                taskText: "",
                numberOfLines: "",
                optionalTxt: "",
            },
        },
        {
            value: "exerConnection",
            content: <Connection />,
            initialData: {
                key: 0,
                taskText: "",
                leftCol: [
                    {
                        charCode: 65,
                        content: "",
                        autoFocus: false,
                    },
                    { charCode: 66, content: "", autoFocus: false },
                ],
                rightCol: [
                    { key: 1, content: "", autoFocus: false, answer: null },
                    { key: 2, content: "", autoFocus: false, answer: null },
                ],
                optionalTxt: "",
            },
        },
        {
            value: "exerFillIn",
            content: <FillingGaps />,
            initialData: {
                key: 0,
                taskText: "",
                fillInTxt: "",
                fillInAnswer: "",
                optionalTxt: "",
            },
        },
    ];

    return (
        <Fragment>
            <ExerciseContext.Provider value={{ setDisableAction, versions, currentKey, changeValue }}>
                <Recording
                    value={exerciseData.recording}
                    onChange={(v) => onChangeRecording(v)}
                    subject={exerciseData.basicData.subject}
                    eduStage={exerciseData.basicData.eduStage}
                    series={exerciseData.basicData.series}
                />
                <Row>
                    <Col className="py-3">
                        <h1 className="h4 py-4">{t("createExercise.exerciseContent.title")}</h1>
                    </Col>
                </Row>

                {!exerType ? (
                    <Row>
                        <Col>
                            <Alert variant="info" className="mb-5">
                                {t("info.firstChooseExerciseType")}
                            </Alert>
                        </Col>
                    </Row>
                ) : (
                    versions && (
                        <Fragment>
                            <Row>
                                <Col>
                                    <Button variant="link" className="py-0 mb-4 ps-0" onClick={() => handleAddVersion()}>
                                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                                        {t("addVersion")}
                                    </Button>
                                    <Button variant="link" className="text-danger py-0 mb-4" onClick={() => handleDeleteVersion(currentKey)} disabled={versions.length <= 1}>
                                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                                        {t("deleteVersion")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div id="exerciseVersions" className="mb-5">
                                        <Tabs id="exer-version" activeKey={currentKey} onSelect={(k) => setKey(parseInt(k))}>
                                            {versions.map((e, i) => (
                                                <Tab eventKey={i} title={`${t("version")} ${i + 1}`} key={i}>
                                                    {typesList.map((e, i) => (
                                                        <Fragment key={i}>{e.value === exerType ? e.content : null}</Fragment>
                                                    ))}
                                                </Tab>
                                            ))}
                                        </Tabs>
                                    </div>
                                </Col>
                            </Row>
                        </Fragment>
                    )
                )}
            </ExerciseContext.Provider>
        </Fragment>
    );
};

export default ExerciseForm;
