import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const i18nextOptions = {
    fallbackLng: "pl",
    whitelist: ["pl"],
    ns: "translation",
    defaultNS: "translation",
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .use(LanguageDetector)
    .init(i18nextOptions);

export default i18n;
