import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Card, Col, Container, Form, Row, Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Password from "../components/forms/Password";
import NavbarMain from "../components/navbars/NavbarMain";
import $ from "jquery";
import { BasicContext } from "../App";
import { getMethod } from "../requests/gets";
import postMethod from "../requests/posts";

const SetNewPassword = () => {
    const { t } = useTranslation();
    const [password, setPassword] = useState({ first: "", second: "" });
    const [blockForm, setBlockForm] = useState(false);
    const [inputType, setInputType] = useState("password");
    const { setAlerts, setLoading } = useContext(BasicContext);
    let [searchParams] = useSearchParams();
    const [fetchURLCorectness, setFetchURLCorectness] = useState(false);
    const [hash, setHash] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [fetchSetNewPassword, setFetchSetNewPassword] = useState(false);
    const navigate = useNavigate();

    const validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    useEffect(() => {
        const sendData = {
            hash: hash,
            password: password.first,
            client: process.env.REACT_APP_CLIENT,
        };
        const newPassword = async () => {
            const res = await postMethod("/recover-password/password-update", sendData, "");
            if (res.status !== "success") {
                setAlerts([{ variant: "danger", message: res.msg }]);
                setBlockForm(false);
            } else {
                setAlerts([{ variant: "success", message: t("success.passwordChanged"), fading: 2000 }]);
                navigate("/");
            }
        };

        if (fetchSetNewPassword) newPassword();

        return () => {
            setFetchSetNewPassword(false);
        };
    }, [fetchSetNewPassword]);

    const handleValidation = (e) => {
        e.preventDefault();

        let errors = [];
        if (password.first.length === 0 || password.second.length === 0) {
            errors.push({ variant: "danger", message: t("error.oneOfPasswordsIsEmpty") });
            if (password.first.length === 0) $("#resetFormPassword1").addClass("is-invalid");
            if (password.second.length === 0) $("#resetFormPassword2").addClass("is-invalid");
        } else if (password.first !== password.second) {
            errors.push({ variant: "danger", message: t("error.passwordsAreNotTheSame") });
            $("#resetFormPassword1").addClass("is-invalid");
            $("#resetFormPassword2").addClass("is-invalid");
            setInputType("text");
        } else if (!validatePassword(password.first) || !validatePassword(password.second)) {
            errors.push({ variant: "danger", message: t("error.invalidPassword") });
            $("#resetFormPassword1").addClass("is-invalid");
            $("#resetFormPassword2").addClass("is-invalid");
        }

        if (errors.length > 0) setAlerts(errors);
        else {
            setAlerts([{ variant: "info", message: t("info.changingPassword") }]);
            setBlockForm(true);
            setFetchSetNewPassword(true);
        }
    };

    const handleBtnClick = () => {
        setInputType(inputType === "password" ? "text" : "password");
    };

    useEffect(() => {
        $("#mainContainer").addClass("light-color-bg");

        let hash = searchParams.get("hash");
        if (!hash) setAlerts([{ variant: "danger", message: t("error.hashIncorrect") }]);
        else {
            setHash(hash);
            setFetchURLCorectness(true);
        }
    }, []);

    useEffect(() => {
        const checkURL = async () => {
            //console.log("checkurl");
            const res = await getMethod(`${process.env.REACT_APP_API_URL}/password-recover/hash-verification?hash=${hash}&client=${process.env.REACT_APP_CLIENT}`);
            //console.log(res);
            if (res.status !== "success") {
                setAlerts([{ variant: "danger", message: res.code === "no_data_found" ? t("error.hashIncorrect") : t(res.msg), link: { to: "/", txt: t("setNewPassword.goBackToLogin") } }]);
                setBlockForm(false);
                setLoading(false);
            } else {
                //const fetchedData = res.data;
                //console.log(fetchedData);
                setShowForm(true);
            }
        };

        if (fetchURLCorectness) checkURL();

        return () => {
            setFetchURLCorectness(false);
        };
    }, [fetchURLCorectness]);

    return (
        <Fragment>
            <NavbarMain />

            {showForm ? (
                <Container className="py-3 light-color-bg">
                    <Row>
                        <Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }} className="pt-5">
                            <Card className="border border-0 put-shadow p-4">
                                <Card.Body>
                                    <Row>
                                        <Col xs={12}>
                                            <p className="card-title text-center h2 mb-4 text-uppercase">{t("setNewPassword.title")}</p>
                                            <p className="text-center mb-3">{t("setNewPassword.txt")}</p>
                                            <Alert variant="info">
                                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                                {t("setNewPassword.alertTxt", { mainAccount: process.env.REACT_APP_CLIENT_DOMAIN })}
                                            </Alert>
                                        </Col>
                                        <Col xs={12} className="p-2">
                                            <Form onSubmit={handleValidation}>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <input type="text" name="email" autoComplete="username email" className="d-none" />
                                                        <Password
                                                            value={password.first}
                                                            onChange={(e) => setPassword({ ...password, first: e.target.value })}
                                                            autoComplete={"new-password"}
                                                            disabled={blockForm}
                                                            controlID={"resetFormPassword1"}
                                                            btnID="showPass1"
                                                            label={t("setNewPassword.typePassword")}
                                                            onBtnClick={handleBtnClick}
                                                            inputType={inputType}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Password
                                                            value={password.second}
                                                            onChange={(e) => setPassword({ ...password, second: e.target.value })}
                                                            autoComplete={"new-password"}
                                                            disabled={blockForm}
                                                            controlID={"resetFormPassword2"}
                                                            btnID="showPass2"
                                                            label={t("setNewPassword.typeAgain")}
                                                            onBtnClick={handleBtnClick}
                                                            inputType={inputType}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={{ offset: 8, span: 2 }} lg={{ offset: 3, span: 6 }}>
                                                        <Button variant="primary" type="submit" disabled={blockForm} className="w-100 p-2 mt-3 mb-4">
                                                            {t("setNewPassword.changePass")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <Col xs={12} className="text-center border-top pt-3">
                                                <Link as={Button} to="/">
                                                    {t("setNewPassword.goBackToLogin")}
                                                </Link>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            ) : null}
        </Fragment>
    );
};

export default SetNewPassword;
