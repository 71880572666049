import React, { Fragment } from "react";
import { Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const LoadingView = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Container fluid id="loadingView">
                <div>
                    <Image src="/images/klett-testomat-kursor-logo.svg" alt="klett testomat kursor logo" />
                    <p>
                        {t("loading")}
                        <span className="first">.</span>
                        <span className="second">.</span>
                        <span className="third">.</span>
                    </p>
                </div>
            </Container>
        </Fragment>
    );
};

export default LoadingView;
