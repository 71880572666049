import React, { Fragment, useEffect, useContext, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { TestContext } from "./AddEditTest";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import ExercisePreviewInTest from "../../../components/tests/ExercisePreviewInTest";
import BasicTestDataPreview from "../../../components/tests/BasicTestDataPreview";

const TestPreview = (props) => {
    const { t } = useTranslation();
    const { setKey, key, previewData, setPreviewData, setExerciseList, exerciseList, showAnswers } = useContext(TestContext);
    const { onRemoveFromTest, onChangeSwitch } = props;
    const [pos, setPos] = useState(null);

    const setTabHeight = () => {
        ////console.log($(`#testPreviewGroupsTab${key}`).outerWidth());
        let width = $(`#testPreviewGroupsTab${key}`).outerWidth();
        ////console.log((width * 1.4142).toFixed(1));
        $(`#testPreviewGroupsTab${key}`).css({ height: `${(width * 1.4142).toFixed(1)}px` }); //a4 ratio height to width: 1.4142:1
    };

    useEffect(() => {
        if (previewData.groups) {
            setTabHeight();
        }
    }, [key, previewData]);

    const handleDragEnd = (event) => {
        const { active, over } = event; //active - active card that the user is pressing on, over - the card that the user is pressing over
        $(`#exerID${active.id}`).removeClass("dragging");
        if (active.id !== over.id) {
            //switch positions
            setExerciseList((items) => {
                let activeIndex = null,
                    overIndex = null;
                items.forEach((e) => {
                    if (e.id === active.id) activeIndex = items.indexOf(e);
                    if (e.id === over.id) overIndex = items.indexOf(e);
                });
                ////console.log(activeIndex, overIndex);
                ////console.log(arrayMove(items, activeIndex, overIndex));
                return arrayMove(items, activeIndex, overIndex);
            });
        }
    };

    const onScroll = () => {
        const navbar = document.getElementById("testNavbar");
        const posY = navbar.offsetTop;
        if (window.scrollY >= posY) {
            setPos("top");

            document.getElementById("testPreviewMode").style.top = `${$("#testNavbar").outerHeight() + 16}px`;
        } else setPos(null);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
            window.removeEventListener("resize", setTabHeight);
        };
    }, []);

    useEffect(() => {
        ////console.log("exer list");
        let totalPoints = 0,
            averageLvl = 0,
            totalTime = 0;
        exerciseList.forEach((e) => {
            totalPoints += parseInt(e.taskData.basicData.points);
            totalTime += parseInt(e.taskData.basicData.time);
            averageLvl += e.taskData.basicData.difficultyLvl;
        });
        setPreviewData({
            ...previewData,
            basicData: {
                totalPoints: totalPoints,
                averageLvl: averageLvl > 0 ? averageLvl / exerciseList.length : 0.0,
                totalTime: totalTime,
                numberOfExercises: exerciseList.length,
            },
        });
    }, [exerciseList]);

    return (
        <div className={pos ? "sticky-top" : ""} id="testPreviewMode">
            <Row>
                <Col>
                    <h1 className="h4 py-4">{t("createTest.previewMode.title")}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div id="testPreviewGroups">
                        <p className="mb-1">
                            {t("createTest.previewMode.testTitle")}: {previewData.testTitle ? <b>{previewData.testTitle}</b> : <i className="opacity-50">{t("lack")}</i>}
                        </p>
                        <p className="mb-1">
                            {t("createTest.previewMode.exercisesLength")}: <b>{previewData.basicData.numberOfExercises}</b>, {t("createTest.printData.points")}:{" "}
                            <b>{previewData.basicData.totalPoints}</b>, {t("createTest.printData.time")}: <b>{previewData.basicData.totalTime} min</b>,
                        </p>
                        <p>
                            {t("createTest.previewMode.averageLvl")}: <b>{String(previewData.basicData.averageLvl.toFixed(1)).replace(".", ",")}</b>
                        </p>
                        <Form.Group className="mb-3">
                            <Form.Label className="form-label mb-0 me-2">{t("showAnswers")}</Form.Label>
                            <Form.Check type="switch" id="testPreviewShowAnswers" checked={showAnswers} onChange={onChangeSwitch} className="d-inline-block align-bottom" />
                        </Form.Group>
                        {!previewData.groups ? (
                            t("loading")
                        ) : (
                            <Tabs id="testPreviewGroups" activeKey={key} onSelect={(k) => setKey(parseInt(k))}>
                                {previewData.groups.map((group, i) => (
                                    <Tab eventKey={i} title={`${t("createTest.groups.group")} ${String.fromCharCode(group.charCode)}`} key={i} className="overflow-y-auto">
                                        <div className="test-view scale  test-preview-groups-tab" id={`testPreviewGroupsTab${i}`}>
                                            <div id={`testPreviewGroupsTabContent${i}`} className="p-3 test-preview-groups-tab-content">
                                                <BasicTestDataPreview previewData={previewData} groupLetter={String.fromCharCode(group.charCode)} showAnswers={showAnswers} />
                                                <Row>
                                                    <DndContext
                                                        collisionDetection={closestCenter}
                                                        onDragEnd={handleDragEnd}
                                                        onDragStart={(e) => {
                                                            const id = e.active.id;
                                                            $(`#exerID${id}`).addClass("dragging");
                                                        }}
                                                    >
                                                        <SortableContext items={exerciseList} strategy={verticalListSortingStrategy}>
                                                            {/* Components that use the useSortable hook */}
                                                            {exerciseList.map((e, i) => (
                                                                <Fragment key={i}>
                                                                    <ExercisePreviewInTest
                                                                        exercise={e}
                                                                        index={i + 1}
                                                                        onRemoveFromTest={onRemoveFromTest}
                                                                        draggableMode
                                                                        currentKey={key}
                                                                        showAnswers={showAnswers}
                                                                    />
                                                                </Fragment>
                                                            ))}
                                                        </SortableContext>
                                                    </DndContext>
                                                </Row>
                                            </div>
                                        </div>
                                    </Tab>
                                ))}
                            </Tabs>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default TestPreview;
