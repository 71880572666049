import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import LoadingTxt from "../LoadingTxt";

const FilterDropdown = (props) => {
    const { t } = useTranslation();
    const { label, value, onChangeValue, options } = props;

    return (
        <Fragment>
            <Form.Group style={{ marginBottom: "0.5rem" }}>
                <Form.Label className="form-label-sm">{t(label)}</Form.Label>
                {!options ? (
                    <LoadingTxt />
                ) : (
                    <Dropdown
                        placeholder={t("selectMany")}
                        fluid
                        selection
                        options={options}
                        onChange={(e, data) => onChangeValue(data.value)}
                        className={`form-control form-control-sm`}
                        noResultsMessage={t("noResultsFound")}
                        value={value}
                        id={`${label}FilterDropdown`}
                        clearable
                    />
                )}
            </Form.Group>
        </Fragment>
    );
};

export default FilterDropdown;
