import { Modal, Row, Col, Alert, Form, Button } from "react-bootstrap";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const ChangeExerTypeReminderModal = (props) => {
    const { t } = useTranslation();
    const [checkedReminder, setCheckedReminder] = useState(false);

    return (
        <Modal show={props.show} size="lg">
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <Alert variant="warning" className="mb-3">
                            <FontAwesomeIcon icon={faWarning} className="me-2" />
                            {t("changeExerTypeReminderModalTxt")}
                        </Alert>
                    </Col>
                    <Col xs={12}>
                        <Form.Check
                            className="form-check-sm"
                            type="checkbox"
                            id="changeExerTypeReminderCheckbox"
                            label={t("dontShowAgain")}
                            checked={checkedReminder}
                            onChange={() => setCheckedReminder(!checkedReminder)}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="w-25" onClick={() => props.onClose(checkedReminder)}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangeExerTypeReminderModal;
