import React from "react";
import { Col, Pagination, Row } from "react-bootstrap";

const PaginationCustom = (props) => {
    const { paginationList, activePage, setActivePage, scrollToTop } = props;

    const sliceList = (list, activeP) => {
        return list.slice(list.indexOf(activeP) - 5 < 0 ? 0 : list.indexOf(activeP) - 5, list.indexOf(activeP) + 5 > list.length ? list.length : list.indexOf(activeP) + 5);
    };

    return (
        <Row>
            <Col className="my-3">
                {paginationList && (
                    <Pagination className="align-items-center justify-content-center">
                        <Pagination.First
                            disabled={activePage === 1}
                            onClick={() => {
                                setActivePage(1);
                                scrollToTop();
                            }}
                        />
                        <Pagination.Prev
                            disabled={activePage === 1}
                            onClick={() => {
                                setActivePage(activePage - 1);
                                scrollToTop();
                            }}
                        />
                        {sliceList(paginationList, activePage).map((e, i) => (
                            <Pagination.Item
                                key={i}
                                active={e === activePage}
                                onClick={() => {
                                    setActivePage(e);
                                    if (i + 1 !== activePage)
                                        //is not active
                                        scrollToTop();
                                }}
                            >
                                {e}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            disabled={activePage === paginationList.length}
                            onClick={() => {
                                setActivePage(activePage + 1);
                                scrollToTop();
                            }}
                        />
                        <Pagination.Last
                            disabled={activePage === paginationList.length}
                            onClick={() => {
                                setActivePage(paginationList.length);
                                scrollToTop();
                            }}
                        />
                    </Pagination>
                )}
            </Col>
        </Row>
    );
};

export default PaginationCustom;
