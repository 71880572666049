import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useState } from "react";
import { Form } from "react-bootstrap";

const Number = (props) => {
    const { req, label, formID, formPl, value, onChange, min, integer } = props;
    const [onlyIntegerTxt, setOnlyIntegerTxt] = useState(false);

    return (
        <Form.Group className="mb-3">
            <Form.Label>
                {label} {req && <span className="req">*</span>}
            </Form.Label>
            <Form.Control
                id={formID}
                placeholder={formPl}
                name={formID}
                type="number"
                value={value}
                onKeyDown={(e) => {
                    if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8 && e.keyCode !== 9 && integer && (e.keyCode < 96 || e.keyCode > 105)) {
                        setOnlyIntegerTxt(true);
                        e.preventDefault();
                    }
                }}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                min={min ? min : null}
            />
            {onlyIntegerTxt && (
                <p className="text-small">
                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                    {t("info.onlyIntegerTxt")}
                </p>
            )}
        </Form.Group>
    );
};

export default Number;
