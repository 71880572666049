import React, { Fragment, useContext, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TestContext } from "./AddEditTest";
import TestSummaryBasicData from "./TestSummaryBasicData";
import TestSummaryPdfPreview from "./TestSummaryPdfPreview";
import { LoggedContext } from "../../../structure/LoggedViews";
import { reduceDuplicated } from "../../../components/commonData";

const TestSummary = () => {
    const { t } = useTranslation();
    const { previewData, exerciseList, setPreviewData } = useContext(TestContext);
    const { filters } = useContext(LoggedContext);
    const [loading, setLoading] = useState(true);
    const [showAnswers, setShowAnswers] = useState(false);

    const getName = (data, p) => {
        //get translation value from filters
        let name = [];
        data.forEach((e) => {
            if (!e) name.push(null);
            else {
                if (filters[p])
                    filters[p].forEach((f) => {
                        if (e === f.short) name.push(f.title);
                    });
                else if (p === "classes") name.push(`${t("class")} ${e}`);
            }
        });
        return name;
    };

    useEffect(() => {
        const data = {
                subject: [],
                eduStage: [],
                classes: [],
                series: [],
                volumes: [],
                chapters: [],
                categories: [],
                skills: [],
                dyslexic: [],
            },
            dataProperties = Object.keys(data);
        let mainData = [];

        exerciseList.forEach((e) => {
            dataProperties.forEach((p) => {
                if (e.taskData.basicData[p] !== undefined) {
                    //check if property also exists in exercise basic data object
                    let list = [...data[p]];
                    if (typeof e.taskData.basicData[p] === "object" && e.taskData.basicData[p]) {
                        if (e.taskData.basicData[p].length > 0)
                            e.taskData.basicData[p].forEach((el) => {
                                list.push(el);
                                data[p] = list;
                            });
                        else {
                            list.push(null);
                            data[p] = list;
                        }
                    } else {
                        list.push(e.taskData.basicData[p]);
                        data[p] = list;
                    }
                }
            });
        });

        dataProperties.forEach((p) => {
            if (typeof data[p] === "object" && data[p] && data[p].length > 0) {
                const reducedProp = reduceDuplicated(data[p]);
                data[p] = reducedProp;
                if (p === "dyslexic") {
                    let checkIfTrue = false;
                    data[p].forEach((d) => {
                        if (d) checkIfTrue = true;
                    });
                    data[p] = checkIfTrue;
                }
            } else data[p] = previewData.basicData[p];

            data.exercisesLength = exerciseList.length;

            if (typeof data[p] === "object" && data[p]) data[p] = data[p].filter((e) => e !== null);

            mainData.push({
                title: p === "eduStage" ? "educationStage" : p,
                //name: typeof data[p] !== "object" ? data[p] : data[p].length === 1 && data[p][0] === null ? data[p] : getName(data[p], p),
                name: typeof data[p] !== "object" ? data[p] : getName(data[p], p),
                value: typeof data[p] === "object" && data[p] && data[p].length === 0 ? null : data[p],
            });
        });

        setPreviewData({ ...previewData, detailedData: mainData });
        setLoading(false);
    }, []);

    //console.log(exerciseList);

    return (
        <Fragment>
            <Row>
                <Col>
                    <h1 className="h4 py-4">
                        {t("preview")} – {previewData.testTitle}
                    </h1>
                </Col>
            </Row>
            {loading ? (
                <Row>
                    <Col>
                        <p>{t("loading")}</p>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <TestSummaryBasicData previewData={previewData} />
                    <Col xs={12} className="my-3">
                        <Form.Group className="mb-3">
                            <Form.Label className="form-label mb-0 me-2">{t("showAnswers")}</Form.Label>
                            <Form.Check type="switch" id="testPreviewShowAnswers" checked={showAnswers} onChange={(e) => setShowAnswers(e.target.checked)} className="d-inline-block align-bottom" />
                        </Form.Group>
                    </Col>
                    <TestSummaryPdfPreview loading={loading} previewData={previewData} exerciseList={exerciseList} showAnswers={showAnswers} />
                </Row>
            )}
        </Fragment>
    );
};

export default TestSummary;
