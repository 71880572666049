import React, { Fragment, useState, useEffect } from "react";
import { ToggleButtonGroup, Col, Image, Row, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ChangeExerTypeReminderModal from "../../../components/modals/ChangeExerTypeReminderModal";

const ChooseExerciseType = (props) => {
    const { t } = useTranslation();
    const { radioValue, onChoose, setVersions } = props;
    const [setShowChangeExerTypeReminder, setSetShowChangeExerTypeReminder] = useState(false);

    useEffect(() => {
        if (radioValue) {
            typesList.forEach((e) => {
                if (e.value === radioValue) {
                    setVersions([e.initialData]);
                }
            });
        }
    }, [radioValue]);

    const typesList = [
        {
            value: "exerCheckRadio",
            src: "exer-check-radio",
            alt: "exer-check-radio",
            txt: t("createExercise.exerciseTypes.checkRadio"),
            initialData: {
                answers: [
                    { charCode: 65, content: "", autoFocus: true, correct: false },
                    { charCode: 66, content: "", autoFocus: false, correct: false },
                ],
                subExercises: [
                    {
                        key: 0,
                        taskText: "",
                        answers: [
                            { charCode: 65, content: "", autoFocus: false, correct: false },
                            { charCode: 66, content: "", autoFocus: false, correct: false },
                        ],
                    },
                ],
                config: {
                    value: "col1",
                    size: 12,
                    txt: t("createExercise.exerciseContent.checkRadio.oneColumn"),
                },
                subPoints: null,
                taskText: "",
                key: 0,
            },
        },
        {
            value: "exerTrueFalse",
            src: "exer-true-false",
            alt: "exer-true-false",
            txt: t("createExercise.exerciseTypes.trueFalse"),
            initialData: {
                key: 0,
                taskText: "",
                answers: [
                    { content: "", correct: true, autoFocus: false },
                    { content: "", correct: true, autoFocus: false },
                ],
            },
        },
        {
            value: "exerWriting",
            src: "exer-writing",
            alt: "exer-writing",
            txt: t("createExercise.exerciseTypes.writing"),
            initialData: {
                key: 0,
                taskText: "",
                numberOfLines: "",
            },
        },
        {
            value: "exerConnection",
            src: "exer-connect",
            alt: "exer-connect",
            txt: t("createExercise.exerciseTypes.connection"),
            initialData: {
                key: 0,
                taskText: "",
                leftCol: [
                    {
                        charCode: 65,
                        content: "",
                        autoFocus: false,
                    },
                    { charCode: 66, content: "", autoFocus: false },
                ],
                rightCol: [
                    { key: 1, content: "", autoFocus: false, answer: null },
                    { key: 2, content: "", autoFocus: false, answer: null },
                ],
            },
        },
        {
            value: "exerFillIn",
            src: t.language === "pl" ? "exer-fill-in-pl" : "exer-fill-in-en",
            alt: "exer-fill-in",
            txt: t("createExercise.exerciseTypes.fillIn"),
            initialData: {
                key: 0,
                taskText: "",
                fillInTxt: "",
                fillInAnswer: "",
            },
        },
    ];

    return (
        <Fragment>
            <Row>
                <Col className="py-3">
                    <h1 className="h4 py-4">{t("createExercise.exerciseTypes.title")}</h1>
                </Col>
            </Row>
            <Row>
                <Col className="overflow-x-auto">
                    <ToggleButtonGroup
                        name="choose-exercise-type-toggle-btn-group"
                        className="mb-2"
                        type="radio"
                        onChange={(value) => {
                            if (!localStorage.getItem("checkedReminder") && radioValue) setSetShowChangeExerTypeReminder(value);
                            else onChoose(value);
                        }}
                        value={radioValue}
                    >
                        {typesList.map((radio, idx) => (
                            <ToggleButton key={idx} id={`tbg-radio-${idx}`} className="exercise-type" variant="outline-primary" value={radio.value} checked={radioValue === radio.value}>
                                <Image src={`/images/icons/${radio.src}.svg`} alt={radio.alt} />
                                <p>
                                    <span>{radio.txt}</span>
                                </p>
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Col>
            </Row>

            {setShowChangeExerTypeReminder && (
                <ChangeExerTypeReminderModal
                    show={true}
                    onClose={(checkedReminder) => {
                        if (checkedReminder) localStorage.setItem("checkedReminder", true);
                        onChoose(setShowChangeExerTypeReminder);
                        setSetShowChangeExerTypeReminder(false);
                    }}
                />
            )}
        </Fragment>
    );
};

export default ChooseExerciseType;
