import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextEditor from "../../../../components/forms/TextEditor";

const CheckRadioAnswers = (props) => {
    const { t } = useTranslation();
    const { mainArray, updateData } = props;

    return (
        <Fragment>
            <Form.Label>{t("createExercise.exerciseContent.checkRadio.answers")}</Form.Label>
            <p className="small-text">{t("createExercise.exerciseContent.checkRadio.answersTxt")} </p>
            {mainArray && (
                <Fragment>
                    <Table id="checkboxRadioAnswersTable" className="create-exercise-table-answers">
                        <tbody>
                            {mainArray.answers.map((e, i) => (
                                <tr key={i}>
                                    <td className="align-middle">
                                        <Form.Check
                                            type="checkbox"
                                            id={`answer-checkbox-${i}`}
                                            label={`${String.fromCharCode(e.charCode)}.`}
                                            checked={e.correct}
                                            onChange={() => {
                                                const list = [...mainArray.answers];
                                                list[i].correct = !e.correct;
                                                updateData("answers", list);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <TextEditor
                                            value={e.content}
                                            autoFocus={e.autoFocus}
                                            changeValue={(v) => {
                                                const list = [...mainArray.answers];
                                                list[i].content = v;
                                                updateData("answers", list);
                                            }}
                                        />
                                    </td>
                                    <td className="text-center align-middle">
                                        <Button
                                            variant="outline-secondary"
                                            className={i !== 0 ? "" : "d-none"}
                                            onClick={() => {
                                                const list = [...mainArray.answers];
                                                list.splice(i, 1);
                                                updateData("answers", list);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {mainArray.answers[mainArray.answers.length - 1].charCode >= 90 ? (
                        <OverlayTrigger overlay={<Tooltip id="add-next-answer-tooltip-disabled">{t("createExercise.exerciseContent.checkRadio.addAnswersDisabled")}</Tooltip>}>
                            <span className="d-inline-block">
                                <Button disabled className="mb-3" variant="outline-primary" style={{ pointerEvents: "none" }}>
                                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                                    {t("addNextAnswer")}
                                </Button>
                            </span>
                        </OverlayTrigger>
                    ) : (
                        <Button
                            className="mb-3"
                            variant="outline-primary"
                            onClick={() => {
                                const list = [...mainArray.answers];
                                let lastNumber = list[list.length - 1].charCode;
                                lastNumber++;
                                list.push({ charCode: lastNumber, content: "", autoFocus: true, correct: false });
                                updateData("answers", list);
                            }}
                        >
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            {t("addNextAnswer")}
                        </Button>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckRadioAnswers;
