import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const Classes = (props) => {
    const { t } = useTranslation();
    const { value, req, dependentOption, dropdownID, dropdownLabel, onChangeValue, size } = props;
    const [changedOptions, setChangedOptions] = useState(null);
    const [options, setOptions] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setChangedOptions(dependentOption.value);
    }, []);

    useEffect(() => {
        if (dependentOption.value !== changedOptions && dependentOption.value) {
            //change options in this dropdown if it was selected another option in dependant value
            setChangedOptions(dependentOption.value);

            const dropdownOptions = [],
                min = props.options.min,
                max = props.options.max;
            let key = 0;

            for (let i = min; i <= max; i++) {
                dropdownOptions.push({
                    key: key,
                    value: i,
                    text: `${t("class")} ${i}`,
                });
                key++;
            }
            setOptions(dropdownOptions);
            setShowDropdown(true);
        } else if (!dependentOption.value) setShowDropdown(false);
        else setShowDropdown(false);
    }, [dependentOption.value]);

    return (
        <Form.Group className="mb-3">
            <Form.Label>
                {dropdownLabel} {req && <span className="req">*</span>}
            </Form.Label>
            {showDropdown ? (
                <Dropdown
                    id={dropdownID}
                    placeholder={t("selectMany")}
                    fluid
                    multiple
                    selection
                    options={options}
                    className={`form-control ${size ? `form-control-${size}` : ""}`}
                    noResultsMessage={t("noResultsFound")}
                    value={value}
                    onChange={(e, data) => {
                        onChangeValue(data.value);
                    }}
                />
            ) : (
                <p className={size === "sm" ? "text-small" : size === "lg" ? "text-large" : ""}>
                    {t("firstChoose")}
                    {dependentOption.name}
                </p>
            )}
        </Form.Group>
    );
};

export default Classes;
