import { faDownload, faEdit, faEllipsisVertical, faEye, faPlus, faPrint /* , faShareNodes */ } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState } from "react";
import { Col, Row, Button, OverlayTrigger, Tooltip, Image, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../../App";
import postMethod from "../../../requests/posts";
import { LoggedContext } from "../../../structure/LoggedViews";

const TestCard = (props) => {
    const { t } = useTranslation();
    const { test, onDownloadTest, onPreviewTest, index, userID, onDownloadRecordings } = props;
    const [showMoreDataBtn, setShowMoreDataBtn] = useState(false);
    const { setCurrentView } = useContext(LoggedContext);
    const { setAlerts } = useContext(BasicContext);

    const handleSendStatisticksAndDownload = (testID) => {
        const sendStatistics = async () => {
            let userData = localStorage.getItem("userData");
            userData = JSON.parse(userData);
            const data = {
                testID: testID,
                loggedUserID: userData.uid,
                downloadDate: new Date(),
            };
            //console.log(data);
            const res = await postMethod("/tests/count-create-pdf", data, localStorage.getItem("token"));
            //console.log(res);
            if (res.status !== "success" || res.data.status !== "success") {
                setAlerts([{ variant: "danger", message: t(`error.${res.data.msg}`) }]);
            } else {
                onDownloadTest(testID);
            }
        };

        sendStatistics();
    };

    return (
        <div className="test-card">
            <Fragment>
                <Row>
                    <Col>
                        <p className="h4 pb-3 mt-1 d-inline-block">{test.testTitle}</p>
                        <p className="test-card-author float-end d-inline-block">
                            {test.author.accountData.name === process.env.REACT_APP_CLIENT_NAME && test.author.accountData.surname === process.env.REACT_APP_CLIENT_SURNAME && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-test-card-author`}>
                                            {t("authorTest", { authorName: process.env.REACT_APP_CLIENT_NAME, authorSurname: process.env.REACT_APP_CLIENT_SURNAME })}
                                        </Tooltip>
                                    }
                                >
                                    <Image src={`/images/icons/${process.env.REACT_APP_CLIENT_ICON}`} alt={`${process.env.REACT_APP_CLIENT_ICON}`} className="mx-1 exercise-card-icon" />
                                </OverlayTrigger>
                            )}
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className="test-card-series-chapters mb-3" style={{ paddingTop: "0.125rem" }}>
                            {!test.seriesTxt ? (
                                t("loading")
                            ) : (
                                <>
                                    {test.seriesTxt.map((n, i) => (
                                        <span key={i}>
                                            {i < 3 ? (
                                                <>
                                                    {n}
                                                    {(test.seriesTxt.length > 3 && i + 1 < 3) || (test.seriesTxt.length <= 3 && i + 1 < test.seriesTxt.length) ? ", " : ""}
                                                </>
                                            ) : (
                                                test.seriesTxt.length > 3 && (
                                                    <Fragment>
                                                        {!showMoreDataBtn ? (
                                                            i + 1 === 4 && (
                                                                <Button variant="outline-secondary" size="sm" className="ms-2" onClick={() => setShowMoreDataBtn(!showMoreDataBtn)}>
                                                                    <FontAwesomeIcon icon={faPlus} />
                                                                </Button>
                                                            )
                                                        ) : (
                                                            <>
                                                                {i + 1 === 4 ? ", " : ""}
                                                                {n}
                                                                {i + 1 < test.seriesTxt.length ? ", " : ""}
                                                            </>
                                                        )}
                                                    </Fragment>
                                                )
                                            )}
                                        </span>
                                    ))}
                                </>
                            )}
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col className="test-card-txts">
                        <p>
                            {t("test.numberOfExercises")}: <b>{test.basicData.numberOfExercises}</b>
                        </p>
                        <p>
                            {t("test.numberOfPoints")}: <b>{test.basicData.totalPoints} p.</b>
                        </p>
                        <p>
                            {t("test.numberOfGroups")}: <b>{test.numberOfGroups}</b>
                        </p>
                        <p>
                            {t("test.estimatedTime")}: <b>{test.basicData.totalTime} min.</b>
                        </p>
                        <p>
                            {t("test.averageDifficultyLvl")}: <b>{test.basicData.averageLvl.toFixed(2).replace(".", ",")}</b>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-3" xs={12}>
                        <Button
                            variant="primary"
                            onClick={() => {
                                handleSendStatisticksAndDownload(test._id);
                                //onDownloadTest(test._id)
                            }}
                            className="me-2"
                        >
                            <FontAwesomeIcon icon={faPrint} className="me-2" />
                            {t("test.printTest")}
                        </Button>
                        <Button variant="outline-primary" className="me-2" onClick={() => onPreviewTest(test._id)}>
                            <FontAwesomeIcon icon={faEye} className="me-2" />
                            {t("test.preview")}
                        </Button>
                        <Button variant="outline-primary" className="me-2" onClick={() => onDownloadRecordings(test._id)}>
                            <FontAwesomeIcon icon={faDownload} className="me-2" />
                            {t("test.downloadRecordings")}
                        </Button>

                        <Dropdown align="end" className="float-end">
                            <Dropdown.Toggle variant="outline-primary" id={`testDetails${index + 1}`} className="ellipsis-btn">
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* <Dropdown.Item disabled>
                                    <FontAwesomeIcon icon={faShareNodes} className="me-2" />
                                    {t("share")}
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                    disabled={userID !== test.author.accountUid}
                                    onClick={() => {
                                        setCurrentView({ nav: "createTest", page: "editTest", params: { id: test._id } });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} className="me-2" />
                                    {t("edit")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
            </Fragment>
        </div>
    );
};

export default TestCard;
