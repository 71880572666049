import React from "react";
import { Form } from "react-bootstrap";

const DislexicSwitch = (props) => {
    const { req, label, value, onChange, switchID, size } = props;
    return (
        <Form.Group>
            <Form.Label className={`form-label-${size ? size : "md"}`}>
                {label} {req && <span className="req">*</span>}
            </Form.Label>
            <Form.Check type="switch" id={switchID} checked={value} onChange={onChange} className="mt-1" />
        </Form.Group>
    );
};

export default DislexicSwitch;
