import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, Col, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import TextEditor from "../../../../components/forms/TextEditor";

const ConnectionCol = (props) => {
    const { t } = useTranslation();
    const { colName, columnsArray, changeValue, options } = props;
    return (
        <Row>
            <Col xs={12}>
                <Form.Group>
                    <Form.Label className="mb-1">
                        {t(`createExercise.exerciseContent.connection.${colName}`)} <span className="req">*</span>
                    </Form.Label>
                    <Table id={`connectionAnswersTable${colName}`} className="create-exercise-table-answers">
                        <tbody>
                            {columnsArray.map((e, i) => (
                                <tr key={i}>
                                    <td className="align-middle">
                                        {e.charCode ? (
                                            String.fromCharCode(e.charCode)
                                        ) : (
                                            <Fragment>
                                                {options && (
                                                    <Dropdown
                                                        id={`rightColAnswer${i}`}
                                                        placeholder={""}
                                                        fluid
                                                        clearable
                                                        selection
                                                        options={options}
                                                        className={"form-control d-inline-block me-2"}
                                                        noResultsMessage={t("noResultsFound")}
                                                        value={e.answer}
                                                        onChange={(e, data) => {
                                                            const list = [...columnsArray];
                                                            list[i].answer = data.value;
                                                            changeValue(colName, list);
                                                        }}
                                                        style={{ width: "70%" }}
                                                    />
                                                )}
                                                <span className="d-inline-block">{i + 1}</span>
                                            </Fragment>
                                        )}
                                        .
                                    </td>
                                    <td>
                                        {
                                            <TextEditor
                                                value={e.content}
                                                changeValue={(v) => {
                                                    const list = [...columnsArray];
                                                    list[i].content = v;
                                                    changeValue(colName, list);
                                                }}
                                                autoFocus={e.autoFocus}
                                            />
                                        }
                                    </td>
                                    <td className="text-center align-middle">
                                        <Button
                                            variant="outline-secondary"
                                            className={i !== 0 ? "" : "d-none"}
                                            onClick={() => {
                                                const list = [...columnsArray];
                                                list.splice(i, 1);
                                                changeValue(colName, list);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form.Group>
            </Col>
            <Col xs={12}>
                {columnsArray.length >= 25 ? (
                    <OverlayTrigger overlay={<Tooltip id="add-next-answer-tooltip-disabled">{t("createExercise.exerciseContent.checkRadio.addAnswersDisabled")}</Tooltip>}>
                        <span className="d-inline-block">
                            <Button disabled className="mb-3" variant="outline-primary" style={{ pointerEvents: "none" }}>
                                <FontAwesomeIcon icon={faPlus} className="me-2" />
                                {t("addNextAnswer")}
                            </Button>
                        </span>
                    </OverlayTrigger>
                ) : (
                    <Button
                        className="mb-3"
                        variant="outline-primary"
                        onClick={() => {
                            const list = [...columnsArray];
                            let lastNumber = list[list.length - 1].charCode;
                            lastNumber++;
                            list.push({ charCode: lastNumber, content: "", autoFocus: true });
                            changeValue(colName, list);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        {t("addNextAnswer")}
                    </Button>
                )}
            </Col>
        </Row>
    );
};

export default ConnectionCol;
