import React, { Fragment, useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { LoggedContext } from "../../structure/LoggedViews";
import LoadingTxt from "../LoadingTxt";

const Subject = (props) => {
    const { size, filter, clearable, onChangeValue, value } = props;
    const { t } = useTranslation();
    const [subjectOptions, setSubjectOptions] = useState(null);
    const { permissions, filters } = useContext(LoggedContext);

    useEffect(() => {
        const options = [];
        ////console.log(categories);
        filters.subject.forEach((e, i) => {
            options.push({
                key: i + 1,
                value: e.short,
                text: e.title,
                disabled: filter ? false : checkIfUserHasAccess(e.short),
            });
        });
        ////console.log(options);
        setSubjectOptions(options);
    }, []);

    //checking if user has access to the certain subject from the fetched list
    const checkIfUserHasAccess = (subject) => {
        let hasAccess = false;
        permissions.forEach((e) => {
            if (e.short === subject) hasAccess = true;
        });
        return !hasAccess;
    };

    return (
        <Fragment>
            <Form.Group className="mb-3">
                <Form.Label>
                    {t("subject")} {!filter && <span className="req">*</span>}
                </Form.Label>
                {!subjectOptions ? (
                    <LoadingTxt />
                ) : (
                    <Dropdown
                        placeholder={t("select")}
                        fluid
                        selection
                        options={subjectOptions}
                        onChange={(e, data) => onChangeValue(data.value)}
                        className={`form-control ${size ? `form-control-${size}` : ""}`}
                        noResultsMessage={t("noResultsFound")}
                        value={value}
                        id="subjectSelect"
                        clearable={clearable}
                    />
                )}
            </Form.Group>
        </Fragment>
    );
};

export default Subject;
