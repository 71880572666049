import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TextEditor from "./TextEditor";

const TaskText = (props) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Form.Group controlId={props.id}>
                <Form.Label>
                    {t(`createExercise.exerciseContent.${props.subExercise ? "subExerciseTask" : "contentOfTask"}`)} <span className="req">*</span>
                </Form.Label>
                <TextEditor value={props.value} autoFocus noBold changeValue={props.onChange} id={props.id} />
            </Form.Group>
        </Fragment>
    );
};

export default TaskText;
