import React, { Fragment, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TaskText from "../../../../components/forms/TaskText";
import TextToTheTask from "../../../../components/forms/TextToTheTask";
import { ExerciseContext } from "../ExerciseForm";
import ConnectionCol from "./ConnectionCol";

const Connection = () => {
    const { t } = useTranslation();
    const { versions, changeValue, currentKey } = useContext(ExerciseContext);

    const rightColOptions = () => {
        const options = [];
        versions[currentKey].leftCol.forEach((e, i) => {
            options.push({ key: i, value: String.fromCharCode(e.charCode), text: String.fromCharCode(e.charCode) });
        });

        return options;
    };

    return (
        <Fragment>
            {versions[currentKey] && (
                <Fragment>
                    {!versions[currentKey].leftCol ? (
                        t("loading")
                    ) : (
                        <Fragment>
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <TaskText value={versions[currentKey].taskText} onChange={(v) => changeValue("taskText", v)} />
                                </Col>

                                <TextToTheTask value={versions[currentKey].optionalTxt} changeValue={(v) => changeValue("optionalTxt", v)} />
                            </Row>
                            <ConnectionCol columnsArray={versions[currentKey].leftCol} colName="leftCol" changeValue={changeValue} />
                            <ConnectionCol columnsArray={versions[currentKey].rightCol} colName="rightCol" changeValue={changeValue} options={rightColOptions()} />
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Connection;
