import React, { Fragment, useContext } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TaskText from "../../../../components/forms/TaskText";
import TextToTheTask from "../../../../components/forms/TextToTheTask";
import { ExerciseContext } from "../ExerciseForm";

const Writing = () => {
    const { t } = useTranslation();
    const { versions, changeValue, currentKey } = useContext(ExerciseContext);

    return (
        <Fragment>
            {versions[currentKey] && (
                <Fragment>
                    {versions[currentKey].numberOfLines === undefined ? (
                        <Row>
                            <Col>{t("loading")}</Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col xs={12} className="mb-3">
                                <TaskText value={versions[currentKey].taskText} onChange={(v) => changeValue("taskText", v)} />
                            </Col>
                            <TextToTheTask value={versions[currentKey].optionalTxt} changeValue={(v) => changeValue("optionalTxt", v)} />
                            <Col xs={6} lg={3}>
                                <Form.Group>
                                    <Form.Label className="mb-1">
                                        {t("createExercise.exerciseContent.writing.numberOfLines")} <span className="req">*</span>
                                    </Form.Label>
                                    <p className="text-small mb-2">{t("createExercise.exerciseContent.writing.numberOfLinesHelper")}</p>
                                    <Form.Control type="number" min="1" value={versions[currentKey].numberOfLines} onChange={(e) => changeValue("numberOfLines", e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Writing;
