import React, { Fragment } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TestSummaryBasicData = (props) => {
    const { t } = useTranslation();
    const { previewData } = props;
    //console.log(previewData);
    return (
        <Fragment>
            <Col xs={12} lg={6}>
                {previewData.detailedData.map((e, i) => (
                    <Fragment key={i}>
                        {typeof e.name === "object" && (
                            <Fragment>
                                <p className="mb-lg-1 mb-2">
                                    {t(`${e.title}`)}:{" "}
                                    {!e.value ? (
                                        <i className="opacity-4">{t("lack")}</i>
                                    ) : (
                                        e.name.map((el, j) => (
                                            <span key={j}>
                                                {el}
                                                {j < e.name.length - 1 ? ", " : ""}
                                            </span>
                                        ))
                                    )}
                                </p>
                            </Fragment>
                        )}
                    </Fragment>
                ))}
            </Col>
            <Col xs={12} lg={6}>
                <p className="mb-lg-1 mb-2">
                    {t(`createTest.previewMode.totalPoints`)}: {previewData.basicData.totalPoints} p.
                </p>
                <p className="mb-lg-1 mb-2">
                    {t(`createTest.previewMode.totalTime`)}: {previewData.basicData.totalTime} min.
                </p>
                <p className="mb-lg-1 mb-2">
                    {t(`test.numberOfExercises`)}: {previewData.basicData.numberOfExercises}
                </p>
                <p className="mb-lg-1 mb-2">
                    {t(`createTest.previewMode.averageLvl`)}: {previewData.basicData.averageLvl.toFixed(2)}
                </p>
            </Col>
        </Fragment>
    );
};

export default TestSummaryBasicData;
