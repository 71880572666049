import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { BasicContext } from "../../App";
import { logoutClearCookies } from "../../components/commonData";
import TestFilters from "../../components/tests/TestFilters";
import postMethod from "../../requests/posts";
import { LoggedContext } from "../../structure/LoggedViews";
import TestsList from "./list/TestsList";

const TestsListMain = () => {
    const noe = 4; //number of elements
    const [search, setSearch] = useState("");
    const [sortValue, setSortValue] = useState("newest");
    const [testsList, setTestsList] = useState(null);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [slicedList, setSlicedList] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [paginationList, setPaginationList] = useState(null);
    const { initialTestFilters } = useContext(LoggedContext);
    const [filteredList, setFilteredList] = useState(null);
    const [userID, setUserID] = useState(null);
    const [showEraser, setShowEraser] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [fetchList, setFetchList] = useState(false);

    useEffect(() => {
        if (search.length > 0) {
            //const searchedList = testsList.filter((e) => e.testTitle.toLowerCase().includes(search));
            //setFilteredList(searchedList);
            setShowEraser(true);
        } else {
            setShowEraser(false);
            //setFilteredList(testsList);
        }
    }, [search]);

    const countSlicedExercises = (list) => {
        const nop = Math.ceil(list.length / noe);
        let startIndex = null,
            endIndex = null,
            j = 1;
        for (let i = 1; i <= nop; i++) {
            if (i === activePage) {
                startIndex = j - 1;
                if (j + (noe - 1) <= list.length) endIndex = j + (noe - 1);
                else endIndex = list.length;
            }
            j += noe;
        }
        return list.slice(startIndex, endIndex);
    };

    useEffect(() => {
        if (filteredList) {
            setSlicedList(countSlicedExercises(filteredList));
            setPagination(filteredList);
        }
    }, [activePage, filteredList]);

    const sortList = (unsortedList) => {
        let list = [...unsortedList];
        if (sortValue === "newest") list.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));
        else if (sortValue === "oldest") list.sort((a, b) => new Date(a.dates.createdAt) - new Date(b.dates.createdAt));
        else if (sortValue === "A-Z") list.sort((a, b) => (a.testTitle.toLowerCase() > b.testTitle.toLowerCase() ? 1 : b.testTitle.toLowerCase() > a.testTitle.toLowerCase() ? -1 : 0));
        else if (sortValue === "Z-A") list.sort((a, b) => (a.testTitle.toLowerCase() > b.testTitle.toLowerCase() ? -1 : b.testTitle.toLowerCase() > a.testTitle.toLowerCase() ? 1 : 0));
        else list.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));

        return list;
    };

    useEffect(() => {
        if (filteredList) {
            setFilteredList(sortList(filteredList));
        }
    }, [sortValue]);

    useEffect(() => {
        if (testsList) {
            setFilteredList(testsList);
            setPagination(testsList);
        }
    }, [testsList]);

    const setPagination = (list) => {
        const nop = Math.ceil(list.length / noe); //number of pages
        const items = [];
        for (let i = 1; i <= nop; i++) {
            items.push(i);
        }
        setPaginationList(items);
    };

    useEffect(() => {
        localStorage.removeItem("creatingProcess");
        let userData = localStorage.getItem("userData");
        userData = JSON.parse(userData);
        setUserID(userData.uid);

        const fetchTestsList = async (filters) => {
            const data = filters;
            //console.log(data);
            const res = await postMethod("/tests/filtered", data, localStorage.getItem("token"));
            if (res.status === "success") {
                res.data.forEach((e) => {
                    let seriesTxt = [];
                    e.detailedData.forEach((d) => {
                        if (d.title === "series") seriesTxt = seriesTxt.concat(d.name);
                        if (d.title === "volumes") seriesTxt = seriesTxt.concat(d.name);
                        if (d.title === "chapters") seriesTxt = seriesTxt.concat(d.name);
                    });
                    e.seriesTxt = seriesTxt;
                });
                res.data.sort((a, b) => new Date(b.dates.createdAt) - new Date(a.dates.createdAt));
                setTestsList(res.data);
            } else {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else setAlerts([{ variant: "danger", message: res.msg }]);
            }
            setFetchList(false);
        };

        if (fetchList)
            if (selectedFilters) {
                setSlicedList(null);
                localStorage.setItem("testFilters", JSON.stringify(selectedFilters));
                const filtersForFetch = [];
                selectedFilters.forEach((e) => {
                    filtersForFetch.push({
                        title: e.title,
                        value: e.value ? [e.value] : [],
                        search: search,
                    });
                });
                filtersForFetch.push({ search: search });
                //console.log(filtersForFetch);
                fetchTestsList(filtersForFetch);
            }
    }, [fetchList]);

    useEffect(() => {
        let filters = localStorage.getItem("testFilters");
        if (filters) {
            filters = JSON.parse(filters);
            setSelectedFilters(filters);
            setFetchList(true);
        } else {
            setSelectedFilters(initialTestFilters);
            setFetchList(true);
        }
    }, []);

    useEffect(() => {
        if (selectedFilters) localStorage.setItem("testFilters", JSON.stringify(selectedFilters));
    }, [selectedFilters]);

    //console.log(selectedFilters);

    return (
        <Fragment>
            <TestFilters
                testsList={testsList}
                setFilteredList={(list) => setFilteredList(sortList(list))}
                setActivePage={setActivePage}
                setSearch={setSearch}
                search={search}
                showEraser={showEraser}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                onFilter={() => setFetchList(true)}
                disableFilterBtn={fetchList}
            />
            <TestsList
                slicedList={slicedList}
                filteredList={filteredList}
                sortValue={sortValue}
                setSortValue={setSortValue}
                paginationList={paginationList}
                userID={userID}
                activePage={activePage}
                setActivePage={setActivePage}
            />
        </Fragment>
    );
};

export default TestsListMain;
