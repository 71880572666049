import { faFileCircleCheck, faInfoCircle, faRecordVinyl } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Alert, Button, Col, Modal, Form, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import { getMethod } from "../../requests/gets";
import { logoutClearCookies } from "../commonData";
import TextEditor from "../forms/TextEditor";

const Recording = (props) => {
    const { value, onChange, subject, eduStage, series } = props;
    const [showRecordingsListModal, setShowRecordingsListModal] = useState(false);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const { setAlerts, setIsLogged } = useContext(BasicContext);
    const [alert, setAlert] = useState(null);
    const [recordings, setRecordings] = useState(null);

    useEffect(() => {
        const getRecordingsList = async () => {
            const res = await getMethod(`/categories/records?subject=${subject}&level=${eduStage}&series=${series}`, localStorage.getItem("token"));
            //console.log(res);
            if (res.status === "success") {
                const recordings = [...res.data.records];
                recordings.sort((a, b) => (a.file > b.file ? 1 : b.file > a.file ? -1 : 0));
                //console.log(recordings);
                setRecordings(recordings);
                setLoading(false);
            } else if (res.status === "errorLogout") {
                setAlerts([
                    {
                        variant: "info",
                        message: (
                            <>
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                {res.msg}
                            </>
                        ),
                        fading: 4000,
                    },
                ]);
                setIsLogged(false);
                logoutClearCookies();
            } else {
                setAlert({ variant: "danger", message: res.msg });
                setLoading(false);
            }
        };
        if (showRecordingsListModal) {
            getRecordingsList();
        }
    }, [showRecordingsListModal]);

    return (
        <Fragment>
            <Row>
                <Col className="py-3 pb-1" xs={12}>
                    <h1 className="h4 py-4">{t("recording.title")}</h1>
                </Col>

                <Col xs={12} className="mb-2">
                    <p className="">{t("recording.helper")}</p>

                    {subject && eduStage && series ? (
                        <Fragment>
                            {value ? (
                                <Row>
                                    <Col xs={12}>
                                        <p className="h6 mb-2">
                                            <b>
                                                <FontAwesomeIcon icon={faRecordVinyl} className="me-2" />
                                                {value.file}
                                            </b>
                                        </p>
                                    </Col>
                                </Row>
                            ) : (
                                <p>
                                    <i className="opacity-50">{t("recording.noRecording")}</i>
                                </p>
                            )}
                            <Button variant="outline-primary" className="d-inline-block me-2" onClick={() => setShowRecordingsListModal(true)}>
                                <FontAwesomeIcon icon={faFileCircleCheck} className="me-2" />
                                {t("recording.select")}
                            </Button>
                            {value ? (
                                <Col xs={12} className="mt-3">
                                    <Form.Label>{t("recording.transcription")}</Form.Label>
                                    <TextEditor
                                        value={value.transcription}
                                        changeValue={(v) => {
                                            const rec = value;
                                            rec.transcription = v;
                                            onChange(rec);
                                        }}
                                        id="recordingTranscription"
                                    />
                                </Col>
                            ) : null}
                        </Fragment>
                    ) : (
                        <Alert variant="info">{t("info.firstChooseSubjectAndEduStage")}</Alert>
                    )}
                </Col>
            </Row>

            {showRecordingsListModal ? (
                <Modal scrollable show={true} onHide={() => setShowRecordingsListModal(false)} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{t("recording.selectRecordingTitle")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {alert ? <Alert variant={alert.variant}>{alert.message}</Alert> : null}
                        {loading ? (
                            <Alert variant="info">{t("info.loadingRecordings")}</Alert>
                        ) : !recordings || recordings.length === 0 ? (
                            <Alert variant="info">
                                <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                {t("info.noRecordingsListed")}
                            </Alert>
                        ) : (
                            <Row>
                                <Col>
                                    {recordings.map((e, i) => (
                                        <Card key={i}>
                                            <Card.Body>
                                                <p className="d-inline-block mb-0 align-bottom">
                                                    <FontAwesomeIcon icon={faRecordVinyl} className="me-2" />
                                                    {e.file}
                                                </p>
                                                <Button
                                                    className="float-end"
                                                    size="sm"
                                                    onClick={() => {
                                                        const rec = e;
                                                        rec.transcription = "";
                                                        onChange(rec);
                                                        setShowRecordingsListModal(false);
                                                    }}
                                                >
                                                    {t("select")}
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ))}
                                </Col>
                            </Row>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={() => setShowRecordingsListModal(false)}>
                            {t("cancel")}
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </Fragment>
    );
};

export default Recording;
