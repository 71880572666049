import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RecordingQRCode from "../../RecordingQRCode";

const ConnectionPreview = (props) => {
    const { versions, currentKey, showAnswers, recording } = props;
    const { t } = useTranslation();

    return (
        <Fragment>
            {!versions ? (
                t("loading")
            ) : !versions[currentKey] ? (
                <Alert variant="warning" className="alert-small">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {t("warning.notEnoughVersions")}
                </Alert>
            ) : (
                <Row className="test-view">
                    <Col xs={12} className="task-text mb-2">
                        {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                    </Col>
                    {versions[currentKey].optionalTxt && (
                        <Col xs={12} className="mb-2">
                            <div dangerouslySetInnerHTML={{ __html: versions[currentKey].optionalTxt }}></div>
                        </Col>
                    )}
                    {showAnswers ? (
                        <Fragment>
                            <RecordingQRCode recording={recording} />
                            <Col xs={12}>
                                <p>
                                    {t("answers")}:{" "}
                                    {versions[currentKey].rightCol.map((e, i) => (
                                        <Fragment key={i}>
                                            {i + 1}. {e.answer ? e.answer : t("lack")}
                                            {i + 1 < versions[currentKey].rightCol.length ? ", " : ""}
                                        </Fragment>
                                    ))}
                                </p>
                            </Col>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Col xs={5}>
                                <Table className="no-border">
                                    <tbody>
                                        {versions[currentKey].leftCol.map((e, i) => (
                                            <tr key={i}>
                                                <td className="align-middle" width="20px">
                                                    {String.fromCharCode(e.charCode)}.
                                                </td>
                                                <td className="align-middle">
                                                    {e.content ? <span dangerouslySetInnerHTML={{ __html: e.content }}></span> : <p className="no-data align-middle">{t("error.noAnswerText")}</p>}
                                                </td>
                                                <td className="align-middle">
                                                    <span style={{ width: "0.5rem", height: "0.5rem", background: "black", borderRadius: "50%", display: "block" }}></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={5}>
                                <Table className="no-border">
                                    <tbody>
                                        {versions[currentKey].rightCol.map((e, i) => (
                                            <tr key={i}>
                                                <td className="align-middle">
                                                    <span style={{ width: "0.5rem", height: "0.5rem", background: "black", borderRadius: "50%", display: "block" }}></span>
                                                </td>
                                                <td className="align-middle" width="20px">
                                                    {i + 1}.
                                                </td>
                                                <td className="align-middle">{e.content ? <span dangerouslySetInnerHTML={{ __html: e.content }}></span> : <p>{t("error.noAnswerText")}</p>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Fragment>
                    )}
                </Row>
            )}
        </Fragment>
    );
};

export default ConnectionPreview;
