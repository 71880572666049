import React, { createContext, useState, Fragment, useEffect, useContext } from "react";
import NavbarMainLogged from "../components/navbars/NavbarMainLogged";
import AddEditExercise from "../pages/exercises/add-edit/AddEditExercise";
import $ from "jquery";
import { getMethod } from "../requests/gets";
import { BasicContext } from "../App";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import AddEditTest from "../pages/tests/add-edit/AddEditTest";
import ExercisesListMain from "../pages/exercises/ExercisesListMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import FirstSettings from "../pages/FirstSettings";
import { classesFilterOptions, difficultyLvlOptions, exerciseTypesOptions, groupsOptions, logoutClearCookies } from "../components/commonData";
import TestsListMain from "../pages/tests/TestsListMain";

export const LoggedContext = createContext();

const LoggedViews = () => {
    const [currentView, setCurrentView] = useState(null);
    const [filters, setFilters] = useState(null);
    const [permissions, setPermissions] = useState(null);
    const { setAlerts, setIsLogged, setLoading } = useContext(BasicContext);
    const [initialExerFilters, setInitialExerFilters] = useState(null);
    const [initialTestFilters, setInitialTestFilters] = useState(null);
    const [basicSettings, setBasicSettings] = useState(localStorage.getItem("basicSettings") ? JSON.parse(localStorage.getItem("basicSettings")) : null);

    const pages = [
        {
            nav: "tests",
            page: "testsList",
            component: <TestsListMain />,
        },
        {
            nav: "createTest",
            page: "createNewTest",
            component: <AddEditTest />,
        },
        {
            nav: "createTest",
            page: "editTest",
            component: <AddEditTest />,
        },
        {
            nav: "exercisesList",
            page: "exercisesList",
            component: <ExercisesListMain />,
        },
        {
            nav: "exercises",
            page: "createExercise",
            component: <AddEditExercise />,
        },
        {
            nav: "exercises",
            page: "editExercise",
            component: <AddEditExercise />,
        },
        {
            nav: "settings",
            page: "firstSettings",
            component: <FirstSettings />,
        },
    ];

    const transformArray = (array) => {
        const newArray = [];
        array.forEach((e, i) => {
            newArray.push({
                key: i + 1,
                value: e.short,
                text: e.title,
            });
        });

        newArray.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
        return newArray;
    };

    const getBasicSettings = (title) => {
        //console.log("basicSettings");
        let userData = localStorage.getItem("userData");
        userData = JSON.parse(userData);
        const loggedUserID = userData.uid;
        if (localStorage.getItem("basicSettings")) {
            //load only logged user settings
            const settings = JSON.parse(localStorage.getItem("basicSettings"));

            let savedSetting = null;
            settings.forEach((s) => {
                //console.log(s);
                if (s.userID === loggedUserID) savedSetting = s;
            });

            //console.log(savedSetting);
            if (savedSetting) {
                //console.log(savedSetting.subject);
                if (title === "subject") return savedSetting.subject;
                else if (title === "eduStage") return savedSetting.eduStage;
            } else return null;
        } else return null;
    };

    useEffect(() => {
        if (filters) {
            setInitialExerFilters(
                localStorage.getItem("exerFilters")
                    ? JSON.parse(localStorage.getItem("exerFilters"))
                    : [
                          {
                              label: "subject",
                              title: "subject",
                              value: getBasicSettings("subject"),
                              options: transformArray(filters.subject),
                              moreFilters: false,
                          },
                          {
                              label: "educationStage",
                              title: "eduStage",
                              value: getBasicSettings("eduStage"),
                              options: transformArray(filters.eduStage),
                              moreFilters: false,
                          },
                          {
                              label: "series",
                              title: "series",
                              value: null,
                              options: transformArray(filters.series),
                              moreFilters: false,
                          },
                          {
                              label: "classes",
                              title: "classes",
                              value: null,
                              options: classesFilterOptions(),
                              moreFilters: false,
                          },
                          {
                              label: "volumes",
                              title: "volumes",
                              value: null,
                              options: transformArray(filters.volumes),
                              moreFilters: false,
                          },
                          {
                              label: "chapters",
                              title: "chapters",
                              value: null,
                              options: transformArray(filters.chapters),
                              moreFilters: false,
                          },
                          {
                              label: "categories",
                              title: "categories",
                              value: null,
                              options: transformArray(filters.categories),
                              moreFilters: false,
                          },
                          {
                              label: "skills",
                              title: "skills",
                              value: null,
                              options: transformArray(filters.skills),
                              moreFilters: false,
                          },
                          {
                              label: "difficultyLvl.label",
                              title: "difficultyLvl",
                              value: null,
                              options: difficultyLvlOptions(),
                              moreFilters: true,
                          },
                          {
                              label: "createExercise.exerciseTypes.title",
                              title: "exerType",
                              value: null,
                              options: exerciseTypesOptions(),
                              moreFilters: true,
                          },
                          {
                              label: "onlyForDislexic",
                              title: "dyslexic",
                              value: false,
                              moreFilters: true,
                          },
                          {
                              label: "numberOfGroups",
                              title: "versions",
                              value: null,
                              options: groupsOptions(),
                              moreFilters: true,
                          },
                      ]
            );
            setInitialTestFilters(
                localStorage.getItem("testFilters")
                    ? JSON.parse(localStorage.getItem("testFilters"))
                    : [
                          {
                              label: "subject",
                              title: "subject",
                              value: getBasicSettings("subject"),
                              options: transformArray(filters.subject),
                              moreFilters: false,
                          },
                          {
                              label: "educationStage",
                              title: "eduStage",
                              value: getBasicSettings("eduStage"),
                              options: transformArray(filters.eduStage),
                              moreFilters: false,
                          },
                          {
                              label: "series",
                              title: "series",
                              value: null,
                              options: transformArray(filters.series),
                              moreFilters: false,
                          },
                          {
                              label: "classes",
                              title: "classes",
                              value: null,
                              options: classesFilterOptions(),
                              moreFilters: false,
                          },
                          {
                              label: "volumes",
                              title: "volumes",
                              value: null,
                              options: transformArray(filters.volumes),
                              moreFilters: false,
                          },
                          {
                              label: "chapters",
                              title: "chapters",
                              value: null,
                              options: transformArray(filters.chapters),
                              moreFilters: true,
                          },
                          {
                              label: "categories",
                              title: "categories",
                              value: null,
                              options: transformArray(filters.categories),
                              moreFilters: true,
                          },
                          {
                              label: "skills",
                              title: "skills",
                              value: null,
                              options: transformArray(filters.skills),
                              moreFilters: true,
                          },
                          {
                              label: "numberOfGroups",
                              title: "numberOfGroups",
                              value: null,
                              options: groupsOptions(),
                              moreFilters: true,
                          },
                          {
                              label: "onlyForDislexic",
                              title: "dyslexic",
                              value: false,
                              moreFilters: true,
                          },
                      ]
            );
        }
    }, [filters]);

    useEffect(() => {
        $("#mainContainer").removeClass("light-color-bg");

        const getPermissions = async () => {
            const res = await getMethod("/categories/", localStorage.getItem("token"));
            if (res.status !== "success") {
                if (res.status === "errorLogout") {
                    setAlerts([
                        {
                            variant: "info",
                            message: (
                                <>
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                                    {res.msg}
                                </>
                            ),
                            fading: 4000,
                        },
                    ]);
                    setIsLogged(false);
                    logoutClearCookies();
                } else {
                    setAlerts([{ variant: "danger", message: res.msg }]);
                }
            } else {
                let userData = localStorage.getItem("userData");
                userData = JSON.parse(userData);
                const permissions = userData.permissions;
                //console.log("permissions: ", permissions);
                if (userData.permissions.length === 0) {
                    setIsLogged(false);
                    logoutClearCookies();
                    setAlerts([{ variant: "danger", message: t("error.noPermission", { mail: process.env.REACT_APP_CONTACT_EMAIL }) }]);
                } else {
                    setFilters(res.data[0]);
                    //console.log(res.data[0]);
                    setPermissions(permissions);

                    //set basic settings
                    if (!localStorage.getItem("basicSettings")) setCurrentView({ nav: "settings", page: "firstSettings" });
                    else {
                        setCurrentView(checkUserBasicSettings() ? { nav: "tests", page: "testsList", params: null } : { nav: "settings", page: "firstSettings" });
                    }
                }
            }
            setLoading(false);
        };

        getPermissions();
    }, []);

    const checkUserBasicSettings = () => {
        if (!localStorage.getItem("basicSettings")) return null;
        else {
            const basicSettings = JSON.parse(localStorage.getItem("basicSettings")),
                userData = JSON.parse(localStorage.getItem("userData"));
            let settingsAreSet = false;
            basicSettings.forEach((e) => {
                if (e.userID === userData.uid) settingsAreSet = true;
            });
            if (settingsAreSet) return true;
            else return false;
        }
    };

    //console.log(initialTestFilters);

    return (
        <LoggedContext.Provider value={{ currentView, setCurrentView, filters, permissions, initialExerFilters, initialTestFilters, basicSettings, setBasicSettings }}>
            {checkUserBasicSettings() && currentView ? <NavbarMainLogged /> : null}

            {filters && permissions && initialExerFilters && initialTestFilters && currentView ? (
                pages.map((e, i) => <Fragment key={i}>{e.page === currentView.page ? e.component : null}</Fragment>)
            ) : (
                <Container>
                    <Row>
                        <Col className="text-center">{t("loading")}</Col>
                    </Row>
                </Container>
            )}
        </LoggedContext.Provider>
    );
};

export default LoggedViews;
