import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ConfirmModal = (props) => {
    const { t } = useTranslation();
    const { show, onClose, text, proceedTxt, cancelTxt, onProceed, title } = props;
    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{text}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClose}>
                    {proceedTxt ? proceedTxt : t("confirmModal.proceedTxt")}
                </Button>
                <Button variant="outline-primary" onClick={onProceed}>
                    {cancelTxt ? cancelTxt : t("confirmModal.cancelTxt")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
