import React, { Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";

const DependentDropdown = (props) => {
    const { t } = useTranslation();
    const { value, multiple, req, dependentOption, dropdownID, dropdownLabel, onChangeValue, search, clearable, size } = props;
    const [changedOptions, setChangedOptions] = useState(null);
    const [options, setOptions] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setChangedOptions(dependentOption.value);
    }, []);

    useEffect(() => {
        if (dependentOption.value !== changedOptions) {
            setLoading(true);
            if (dependentOption.value) {
                //change options in this dropdown if it was selected another option in dependant value
                setChangedOptions(dependentOption.value);
                const dropdownOptions = [];
                props.options.forEach((e, i) => {
                    dropdownOptions.push({
                        key: i + 1,
                        text: e.title,
                        value: e.short,
                    });
                });
                ////console.log(dropdownOptions);
                setOptions(dropdownOptions);
                setShowDropdown(true);
            } else setShowDropdown(false);
            setTimeout(() => {
                setLoading(false);
            }, 200);
        }
    }, [dependentOption.value]);

    return (
        <Fragment>
            <Form.Group className="mb-3">
                {dropdownLabel && (
                    <Form.Label>
                        {dropdownLabel} {req && <span className="req">*</span>}
                    </Form.Label>
                )}
                {loading ? (
                    <Form.Control disabled placeholder={t("loading")} />
                ) : showDropdown ? (
                    options.length === 0 ? (
                        <p className="opacity-50">{t("noOptionsToChoose")}</p>
                    ) : (
                        <Dropdown
                            id={dropdownID}
                            placeholder={t(multiple ? "selectMany" : search ? "writeOrSelect" : "select")}
                            fluid
                            multiple={multiple}
                            selection
                            search={search}
                            options={options}
                            clearable={clearable}
                            className={size ? `form-control-${size}` : "form-control"}
                            noResultsMessage={t("noResultsFound")}
                            value={value}
                            onChange={(e, data) => {
                                onChangeValue(data.value);
                            }}
                        />
                    )
                ) : dependentOption.name ? (
                    <p className={size === "sm" ? "text-small" : size === "lg" ? "text-large" : ""}>
                        {t("firstChoose")}
                        {dependentOption.name}
                    </p>
                ) : null}
            </Form.Group>
        </Fragment>
    );
};

export default DependentDropdown;
