import i18next from "i18next";
import React, { useContext, useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import { ExerciseContext } from "../../pages/exercises/add-edit/ExerciseForm";

const TextEditor = (props) => {
    const { value, autoFocus, changeValue, noBold, id } = props;
    const [content, setContent] = useState(value);
    const { setDisableAction } = useContext(ExerciseContext);

    //note: useEffect for SunEditor onChange is crucial - without this setting state in parent state (e.g. in this case "versions") will not update correctly
    useEffect(() => {
        changeValue(content);
    }, [content]);

    const formatTxt = noBold ? ["underline", "italic", "strike", "subscript", "superscript"] : ["bold", "underline", "italic", "strike", "subscript", "superscript"];

    return (
        <div className="mb-3" id={id}>
            <SunEditor
                onChange={(content) => {
                    setContent(content);
                    setDisableAction(false);
                }}
                lang={i18next.language}
                setOptions={{
                    buttonList: [["undo", "redo"], ["fontSize", "fontColor"], formatTxt, ["removeFormat"], ["codeView"]],
                    colorList: [
                        ["#111", "#222", "#333", "#444", "#555", "#666", "#777"],
                        ["#888", "#999", "#aaa", "#bbb", "#ccc", "#ddd", "#eee"],
                    ],
                }}
                setDefaultStyle="font-size: 16px"
                onInput={(e) => setDisableAction(true)}
                autoFocus={autoFocus}
                setContents={value} //setContents is used to set the contents of the editor programmatically. You must be aware that, when the setContents's prop changes, the onChange event is triggered.
            />
        </div>
    );
};

export default TextEditor;
