import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import { difficultyLvlOptions } from "../commonData";

const DifficultyLvl = (props) => {
    const { t } = useTranslation();
    const options = difficultyLvlOptions();

    let label = window.innerWidth > 992 ? t("difficultyLvl.label") : t("difficultyLvl.labelShort");

    return (
        <Fragment>
            <Form.Group className="mb-3">
                <Form.Label>
                    {label} <span className="req">*</span>
                </Form.Label>

                <Dropdown
                    placeholder={t("select")}
                    fluid
                    selection
                    options={options}
                    onChange={(e, data) => props.onChangeValue(data.value)}
                    className="form-control"
                    noResultsMessage={t("noResultsFound")}
                    value={props.value}
                    id="difficultyLvlSelect"
                />
            </Form.Group>
        </Fragment>
    );
};

export default DifficultyLvl;
