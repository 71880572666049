import React, { Fragment, useContext, useState, useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BasicContext } from "../../App";
import postMethod from "../../requests/posts";
import Email from "../forms/Email";
import $ from "jquery";

const ResetPasswordModal = (props) => {
    const { t } = useTranslation();
    const [blockForm, setBlockForm] = useState(false);
    const [email, setEmail] = useState("");
    const [resetFetch, setResetFetch] = useState(false);
    const { setAlerts } = useContext(BasicContext);

    const validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) return t("error.invalidEmail");
        else return "";
    };

    useEffect(() => {
        const resetPassword = async () => {
            const res = await postMethod("/recover-password", { username: email, client: process.env.REACT_APP_CLIENT }, "");
            //console.log(res);
            if (res.status !== "success") {
                setAlerts([{ variant: "danger", message: res.msg }]);
                setBlockForm(false);
            } else {
                setAlerts([{ variant: "success", message: t("success.passwordReset"), fading: 2000 }]);
                props.onClose();
            }
        };

        if (resetFetch) {
            setAlerts([{ variant: "info", message: t("info.resettingPassword") }]);
            resetPassword();
        }

        return () => {
            setResetFetch(false);
        };
    }, [resetFetch]);

    const handleValidation = (e) => {
        e.preventDefault();
        const errors = [];
        if (!email.replace("^s+$", "")) {
            $("#resetPassEmail").addClass("is-invalid");
            errors.push(t("error.emptyEmail"));
        } else if (validateEmail(email)) {
            $("#resetPassEmail").addClass("is-invalid");
            errors.push(t("error.invalidEmail"));
        }
        if (errors.length > 0) {
            const list = [];
            errors.forEach((e) => {
                list.push({ variant: "danger", message: e });
            });
            setAlerts(list);
        } else {
            setBlockForm(true);
            setResetFetch(true);
        }
    };

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.onClose}>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("resetPasswordModal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mb-3">{t("resetPasswordModal.txt")}</p>
                        <Email value={email} onChange={(e) => setEmail(e.target.value)} controlID="resetPassEmail" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" onClick={handleValidation} disabled={blockForm || !email}>
                            {t("resetPasswordModal.proceedTxt")}
                        </Button>
                        <Button variant="outline-primary" onClick={props.onClose}>
                            {t("resetPasswordModal.cancelTxt")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default ResetPasswordModal;
