/* import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import React, { useState, Fragment /* , useContext  */ } from "react";
import { Col, Container, /* Form, InputGroup , */ Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
//import { LoggedContext } from "../../structure/LoggedViews";
import DislexicSwitch from "../forms/DislexicSwitch";
import FilterDropdown from "../forms/FilterDropdown";

const TestFilters = (props) => {
    const { t } = useTranslation();
    const [moreFilters, setMoreFilters] = useState(false);
    const { disableFilterBtn, onFilter, setActivePage, /* setSearch, search, showEraser ,*/ selectedFilters, setSelectedFilters } = props;
    //const { initialTestFilters } = useContext(LoggedContext);

    /* useEffect(() => {
        if (selectedFilters) {
            let allFilters = true;

            selectedFilters.forEach((f) => {
                if (typeof f.value !== "boolean") {
                    if (f.value.length > 0) allFilters = false;
                } else if (f.value) allFilters = false;
            });

            if (allFilters) setFilteredList(testsList);
            else {
                setSearch("");
                const filteredList = [];
                let selectedFiltersValues = [];

                selectedFilters.forEach((f) => {
                    if (typeof f.value === "boolean" && f.value) selectedFiltersValues.push(true);
                    if (f.value.length > 0) selectedFiltersValues = selectedFiltersValues.concat(f.value);
                });

                //filters on exercise list and creating test (step 2)
                testsList.forEach((test) => {
                    let addToList = [];
                    test.detailedData.forEach((e) => {
                        selectedFilters.forEach((f) => {
                            if (e.title === f.title) {
                                // only for dyslexic option
                                if (f.value === "dyslexic" && f.value) {
                                    if (f.value === e.value) addToList.push({ name: e.title, value: true });
                                } else {
                                    //check basicData
                                    if (e.value) {
                                        if (typeof e.value === "string" || typeof e.value === "number") {
                                            if (f.value.includes(e.value)) addToList.push({ name: e.title, value: true });
                                        } else if (typeof e.value === "object") {
                                            if (f.value.length > 0)
                                                e.value.forEach((el) => {
                                                    if (f.value.includes(el)) addToList.push({ name: e.title, value: true });
                                                });
                                        }
                                    }
                                }
                            }
                        });
                    });

                    if (test.numberOfGroups) {
                        selectedFilters.forEach((f) => {
                            if (f.title === "numberOfGroups") {
                                if (f.value.includes(test.numberOfGroups)) addToList.push({ name: "numberOfGroups", value: true });
                            }
                        });
                    }

                    if (addToList.length === selectedFiltersValues.length) filteredList.push(test);
                });

                setFilteredList(filteredList);
            }
            localStorage.setItem("testFilters", JSON.stringify(selectedFilters));
            let postFilters = [];
            selectedFilters.forEach((e) => {
                postFilters.push({
                    title: e.title,
                    value: e.value,
                });
            });
            //console.log(postFilters);
        }
    }, [selectedFilters]); */

    return (
        <div className="filters">
            <Container className="py-3">
                {!selectedFilters ? (
                    <Row>
                        <Col>{t("loading")}</Col>
                    </Row>
                ) : (
                    <Row>
                        {/* <Col xl={2} md={3} xs={4} className="border-end">
                            <Form.Label size="sm">{t("browser")}</Form.Label>

                            <InputGroup className="mb-3" size="sm">
                                <Form.Control
                                    placeholder={t("searchByName")}
                                    aria-label={t("searchByName")}
                                    aria-describedby="searchTestByName"
                                    value={search}
                                    onChange={(e) => {
                                        setSelectedFilters(initialTestFilters);
                                        setSearch(e.target.value);
                                    }}
                                />
                                <Button variant="outline-secondary" id="searchTestByName" disabled={!showEraser} onClick={() => setSearch("")}>
                                    <FontAwesomeIcon icon={faEraser} />
                                </Button>
                            </InputGroup>
                        </Col> */}
                        {selectedFilters.map((e, i) => (
                            <Fragment key={i}>
                                {!e.moreFilters || (e.moreFilters && moreFilters) ? (
                                    <Fragment>
                                        {e.label === "onlyForDislexic" ? (
                                            <Col xl={3} md={4} xs={5}>
                                                <DislexicSwitch
                                                    label={t("onlyForDislexic")}
                                                    value={e.value}
                                                    onChange={() => {
                                                        setActivePage(1);
                                                        const list = [...selectedFilters];
                                                        list[i].value = !list[i].value;
                                                        setSelectedFilters(list);
                                                    }}
                                                    size="sm"
                                                    switchID="forDislexicSwitchFilter"
                                                />
                                            </Col>
                                        ) : (
                                            <Col xl={e.title === "series" || e.title === "eduStage" || e.title === "chapters" ? 3 : 2} md={3} xs={4}>
                                                <FilterDropdown
                                                    value={e.value}
                                                    options={e.options}
                                                    label={e.label}
                                                    onChangeValue={(v) => {
                                                        //console.log(v);
                                                        setActivePage(1);
                                                        const list = [...selectedFilters];
                                                        list[i].value = v;
                                                        setSelectedFilters(list);
                                                    }}
                                                    search={true}
                                                />
                                            </Col>
                                        )}
                                    </Fragment>
                                ) : null}
                            </Fragment>
                        ))}
                    </Row>
                )}

                <Row>
                    <Col>
                        <Button variant="primary" onClick={() => onFilter()} className="w-25" disabled={disableFilterBtn}>
                            {t("filter")}
                        </Button>
                        <Button variant="link" onClick={() => setMoreFilters(!moreFilters)} className="pe-0 text-decoration-none">
                            {t(!moreFilters ? "showMoreFilters" : "showLessFilters")}
                        </Button>
                    </Col>
                    <Col className="text-end">
                        <Button
                            variant="link"
                            onClick={() => {
                                const list = [...selectedFilters];
                                list.forEach((e) => {
                                    e.value = null;
                                });
                                localStorage.setItem("testFilters", JSON.stringify(list));
                                setSelectedFilters(list);
                            }}
                            className="px-0 text-decoration-none"
                        >
                            {t("clearFilters")}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TestFilters;
