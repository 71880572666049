import React, { useContext, useState, Fragment, useEffect } from "react";
import { faCheck, faPrint, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TestContext } from "../../pages/tests/add-edit/AddEditTest";
import GenerateTestPrint from "../tests/GenerateTestPrint";
import { BasicContext } from "../../App";
import { LoggedContext } from "../../structure/LoggedViews";

const CreatingTestModal = (props) => {
    const { t } = useTranslation();
    const { show, onClose, setFetchSaveTest } = props;
    const { setAlerts } = useContext(BasicContext);
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [print, setPrint] = useState(false);
    const [onlyPrint, setOnlyPrint] = useState(false);
    const { previewData, exerciseList } = useContext(TestContext);
    const [groupNumber, setGroupNumber] = useState(null);
    const { setCurrentView } = useContext(LoggedContext);
    const [printAnswers, setPrintAnswers] = useState(false);

    useEffect(() => {
        if (!print && groupNumber !== null) {
            //console.log("after print");
            //console.log(groupNumber);
            if (!printAnswers) {
                setPrintAnswers(true);
                setGroupNumber(groupNumber);
            }
            //print key answers for the same group test
            else {
                setPrintAnswers(false);
                setGroupNumber(groupNumber + 1);
            }
        }
    }, [print]);

    useEffect(() => {
        //console.log("groupNumber:", groupNumber);
        if (groupNumber !== null) {
            //console.log(groupNumber, previewData.numberOfGroups);
            if (groupNumber < previewData.groups.length) setPrint(true);
            else {
                //console.log("no more printinmg");
                if (onlyPrint) {
                    setAlerts([{ variant: "success", message: t("success.finishedPrinting"), fading: 3000 }]);
                    onClose();
                    setCurrentView({ nav: "tests", page: "testsList", params: null });
                } else setFetchSaveTest(true);
            }
        }
    }, [groupNumber, printAnswers]);

    return (
        <Fragment>
            <Modal show={show} onHide={onClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("confirmingAction")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("confirmCreateTest", { testName: previewData.testTitle })}</Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="primary" onClick={() => setFetchSaveTest(true)}>
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        {t("yesSaveAndFinish")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setGroupNumber(0);
                            setOnlyPrint(false);
                        }}
                        disabled={loadingPrint}
                    >
                        {loadingPrint ? (
                            <span className="spinning">
                                <FontAwesomeIcon icon={faSpinner} />
                            </span>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPrint} className="me-2" />
                                {t("yesSaveAndPrint")}
                            </>
                        )}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setGroupNumber(0);
                            setOnlyPrint(true);
                        }}
                        disabled={loadingPrint}
                    >
                        {loadingPrint ? (
                            <span className="spinning">
                                <FontAwesomeIcon icon={faSpinner} />
                            </span>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faPrint} className="me-2" />
                                {t("yesOnlyPrint")}
                            </>
                        )}
                    </Button>

                    <Button variant="outline-primary" onClick={onClose}>
                        {t("noGoBackToCreating")}
                    </Button>
                </Modal.Footer>
            </Modal>
            {print && (
                <GenerateTestPrint
                    print={print}
                    setLoadingPrint={setLoadingPrint}
                    previewData={previewData}
                    exerciseList={exerciseList}
                    onCloseModal={onClose}
                    setPrint={setPrint}
                    groupNumber={groupNumber}
                    printAnswers={printAnswers}
                />
            )}
        </Fragment>
    );
};

export default CreatingTestModal;
