import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Button } from "react-bootstrap";
import FilterDropdown from "../forms/FilterDropdown";
import DislexicSwitch from "../forms/DislexicSwitch";

const ExerciseFilters = (props) => {
    const { t } = useTranslation();
    const { view, onFilter, setActivePage, setSelectedFilters, selectedFilters, disableFilterBtn } = props;
    const [moreFilters, setMoreFilters] = useState(false);

    return (
        <div className="filters">
            <Container className="py-3">
                <Row>
                    {!selectedFilters ? (
                        t("loading")
                    ) : (
                        <Fragment>
                            {selectedFilters.map((e, i) => (
                                <Fragment key={i}>
                                    {!e.moreFilters || (e.moreFilters && moreFilters) ? (
                                        <Fragment>
                                            {e.label === "onlyForDislexic" ? (
                                                <Col xl={view === "list" ? 3 : 6} lg={view === "list" ? 4 : 6} md={view === "list" ? 6 : 12} xs={view === "list" ? 6 : 12}>
                                                    <DislexicSwitch
                                                        label={t("onlyForDislexic")}
                                                        value={e.value}
                                                        onChange={() => {
                                                            setActivePage(1);
                                                            const list = [...selectedFilters];
                                                            list[i].value = !list[i].value;
                                                            setSelectedFilters(list);
                                                        }}
                                                        size="sm"
                                                        switchID="forDislexicSwitchFilter"
                                                    />
                                                </Col>
                                            ) : (
                                                <Col xl={view === "list" ? 3 : 6} lg={view === "list" ? 3 : 6} md={view === "list" ? 4 : 12} xs={view === "list" ? 6 : 12}>
                                                    <FilterDropdown
                                                        value={e.value}
                                                        options={e.options}
                                                        label={e.label}
                                                        onChangeValue={(v) => {
                                                            setActivePage(1);
                                                            const list = [...selectedFilters];
                                                            list[i].value = v;
                                                            setSelectedFilters(list);
                                                        }}
                                                        search={true}
                                                    />
                                                </Col>
                                            )}
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            ))}
                        </Fragment>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" onClick={() => onFilter()} className="w-25" disabled={disableFilterBtn}>
                            {t("filter")}
                        </Button>
                        <Button variant="link" onClick={() => setMoreFilters(!moreFilters)} className="pe-0 text-decoration-none">
                            {t(!moreFilters ? "showMoreFilters" : "showLessFilters")}
                        </Button>
                    </Col>
                    <Col className="text-end">
                        <Button
                            variant="link"
                            onClick={() => {
                                const list = [...selectedFilters];
                                list.forEach((e) => {
                                    e.value = null;
                                });
                                localStorage.setItem("exerFilters", JSON.stringify(list));
                                setSelectedFilters(list);
                            }}
                            className="px-0 text-decoration-none"
                        >
                            {t("clearFilters")}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ExerciseFilters;
