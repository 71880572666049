import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RecordingQRCode from "../../RecordingQRCode";

const CheckRadioPreview = (props) => {
    const { t } = useTranslation();
    const { versions, currentKey, showAnswers, recording } = props;
    const [type, setType] = useState(null);
    const [subExerType, setSubExerType] = useState(null);

    const setTypeValue = () => {
        //check which type of answers should be set - radio or checkbox - due to number of 'correct: true' options
        if (versions[currentKey].subPoints === "one") {
            let noco = 0; //noco = number of correct options
            versions[currentKey].answers.forEach((e) => {
                if (e.correct) noco++;
            });
            setType(noco === 1 ? "radio" : noco > 1 ? "checkbox" : null);
        } else if (versions[currentKey].subPoints === "many") {
            const subExercises = versions[currentKey].subExercises,
                coa = []; // coa - correct options array
            subExercises.forEach((e) => {
                let noco = 0;

                e.answers.forEach((el) => {
                    if (el.correct) noco++;
                });
                coa.push(noco === 1 ? "radio" : noco > 1 ? "checkbox" : null);
            });

            setSubExerType(coa);
        }
    };

    useEffect(() => {
        setTypeValue();
    }, [currentKey]); //monitor changing key tabs

    useEffect(() => {
        setTypeValue();
    }, []);

    const typeCorrectAnswers = (answersArray) => {
        const answers = answersArray.filter((e) => e.correct);
        let correct = "";
        answers.forEach((e, i) => {
            correct += String.fromCharCode(e.charCode);
            if (i !== answers.length - 1) correct += ", ";
        });

        return correct;
    };

    return (
        <Fragment>
            {!versions ? (
                t("loading")
            ) : !versions[currentKey] ? (
                <Alert variant="warning" className="alert-small">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {t("warning.notEnoughVersions")}
                </Alert>
            ) : (
                <Fragment>
                    {versions[currentKey].subPoints === "one" ? (
                        <Row className="test-view">
                            <Col xs={12} className="task-text mb-2">
                                {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                            </Col>
                            {versions[currentKey].optionalTxt && (
                                <Col xs={12} className="mb-2">
                                    <div dangerouslySetInnerHTML={{ __html: versions[currentKey].optionalTxt }}></div>
                                </Col>
                            )}
                            {showAnswers ? (
                                <Fragment>
                                    <RecordingQRCode recording={recording} />
                                    <Col xs={12}>
                                        <p className="d-inline-block">
                                            {t("answers")}: {type ? <b>{typeCorrectAnswers(versions[currentKey].answers)}</b> : <span className="no-data">{t("error.noAnswerIsChecked")}</span>}
                                        </p>
                                    </Col>
                                </Fragment>
                            ) : (
                                versions[currentKey].answers.map((e, i) => (
                                    <Col xs={versions[currentKey].config.size} key={i}>
                                        {type ? (
                                            <Fragment>
                                                <div className={`${type}-option d-inline-block align-middle`}></div>
                                                <div className="d-inline align-middle me-1">
                                                    <strong>{String.fromCharCode(e.charCode)}.</strong>
                                                </div>
                                                <div className="d-inline align-middle" dangerouslySetInnerHTML={{ __html: e.content }}></div>
                                            </Fragment>
                                        ) : (
                                            <p className="no-data">{t("error.noAnswerIsChecked")}</p>
                                        )}
                                    </Col>
                                ))
                            )}
                        </Row>
                    ) : versions[currentKey].subPoints === "many" ? (
                        <Row className="test-view">
                            <Col xs={12} className="task-text">
                                {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                            </Col>
                            <Col xs={12} className="mt-2">
                                {versions[currentKey].subExercises.map((e, i) => (
                                    <Fragment key={i}>
                                        <div className="d-inline me-1">{`${i + 1}.`}</div>
                                        <div className="d-inline" dangerouslySetInnerHTML={{ __html: `${e.taskText}` }}></div>
                                        <Row>
                                            {showAnswers ? (
                                                <Col xs={12}>
                                                    <p className="d-inline-block">
                                                        {t("answers")}: <b>{typeCorrectAnswers(e.answers)}</b>
                                                    </p>
                                                </Col>
                                            ) : (
                                                e.answers.map((el, k) => (
                                                    <Col key={k} xs={versions[currentKey].config.size}>
                                                        {!subExerType ? (
                                                            t("loading")
                                                        ) : subExerType[i] ? (
                                                            <Fragment>
                                                                <div className={`${subExerType[i]}-option d-inline-block align-middle`}></div>
                                                                <div className="d-inline align-middle me-1">
                                                                    <strong>{String.fromCharCode(el.charCode)}.</strong>
                                                                </div>
                                                                {el.content.length === 0 ? (
                                                                    <p className="no-data d-inline align-middle">{t("error.noAnswerText")}</p>
                                                                ) : (
                                                                    <div className="d-inline align-middle" dangerouslySetInnerHTML={{ __html: el.content }}></div>
                                                                )}
                                                            </Fragment>
                                                        ) : (
                                                            <p className="no-data">{t("error.noAnswerIsChecked")}</p>
                                                        )}
                                                    </Col>
                                                ))
                                            )}
                                        </Row>
                                    </Fragment>
                                ))}
                            </Col>
                        </Row>
                    ) : (
                        <Alert variant="info">{t("info.noChosenSubPoints")}</Alert>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckRadioPreview;
