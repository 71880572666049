import React, { createContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "react-bootstrap";
import CheckRadioPreview from "./previews/CheckRadioPreview";
import TrueFalsePreview from "./previews/TrueFalsePreview";
import WritingPreview from "./previews/WritingPreview";
import ConnectionPreview from "./previews/ConnectionPreview";
import FillingGapsPreview from "./previews/FillingGapsPreview";

export const PreviewExerciseContext = createContext();

const ExercisePreview = (props) => {
    const { t } = useTranslation();
    const { versions, currentKey, setKey, exerType, showAnswers, size, recording } = props;

    const typesList = [
        {
            value: "exerCheckRadio",
            preview: <CheckRadioPreview versions={versions} currentKey={currentKey} showAnswers={showAnswers} recording={recording} />,
        },
        {
            value: "exerTrueFalse",
            preview: <TrueFalsePreview versions={versions} currentKey={currentKey} showAnswers={showAnswers} recording={recording} />,
        },
        {
            value: "exerWriting",
            preview: <WritingPreview versions={versions} currentKey={currentKey} showAnswers={showAnswers} recording={recording} />,
        },
        {
            value: "exerConnection",
            preview: <ConnectionPreview versions={versions} currentKey={currentKey} showAnswers={showAnswers} recording={recording} />,
        },
        {
            value: "exerFillIn",
            preview: <FillingGapsPreview versions={versions} currentKey={currentKey} showAnswers={showAnswers} recording={recording} />,
        },
    ];

    return (
        <Fragment>
            {versions && (
                <div id={`exerPreview${size ? `-${size}` : ""}`}>
                    <Tabs activeKey={currentKey} onSelect={(k) => setKey(parseInt(k))}>
                        {versions.map((e, i) => (
                            <Tab eventKey={e.key} title={`${t("version")} ${e.key + 1}`} key={i}>
                                <div className="test-preview-groups-tab">
                                    {typesList.map((e, i) => (
                                        <Fragment key={i}>{e.value === exerType ? e.preview : null}</Fragment>
                                    ))}
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            )}
        </Fragment>
    );
};

export default ExercisePreview;
