import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RecordingQRCode from "../../RecordingQRCode";

const WritingPreview = (props) => {
    const { t } = useTranslation();
    const { versions, currentKey, showAnswers, recording } = props;

    const drawLines = () => {
        let lines = [];

        for (let i = 0; i < versions[currentKey].numberOfLines; i++) {
            lines.push(null);
        }
        return lines;
    };

    return (
        <Fragment>
            {!versions ? (
                t("loading")
            ) : !versions[currentKey] ? (
                <Alert variant="warning" className="alert-small">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                    {t("warning.notEnoughVersions")}
                </Alert>
            ) : (
                <Fragment>
                    <Row className="test-view">
                        <Col xs={12} className="task-text mb-2">
                            {versions[currentKey].taskText ? <div dangerouslySetInnerHTML={{ __html: versions[currentKey].taskText }}></div> : <p className="no-data">{t("error.noTaskText")}</p>}
                        </Col>
                        {!showAnswers ? (
                            <Fragment>
                                {versions[currentKey].optionalTxt && (
                                    <Col xs={12} className="mb-2">
                                        <div dangerouslySetInnerHTML={{ __html: versions[currentKey].optionalTxt }}></div>
                                    </Col>
                                )}
                                <Col xs={12}>
                                    {drawLines().map((e, i) => (
                                        <div key={i} className="writing-line"></div>
                                    ))}
                                </Col>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <RecordingQRCode recording={recording} />
                            </Fragment>
                        )}
                    </Row>
                </Fragment>
            )}
        </Fragment>
    );
};

export default WritingPreview;
